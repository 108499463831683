import React  from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  Grid,
  Paper,
  Divider,
  TextField,
  CircularProgress
} from "@material-ui/core"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { i18n } from "../i18n"
import _ from "lodash"
import { HERTZ_YELLOW, BLUE } from "../../constants/style"
import numeral from "numeral"
import Moment from "react-moment"

const BookingsSummary = () => {
  const classes = useStyles()
  const quotation = useSelector(state => ({ ...state.quotation }))
  const bookings = useSelector(state => ({ ...state.bookings }))
  const locations = useSelector(state => ({ ...state.locations }))
  const settingsMeta = useSelector(state =>  state.userInfo.settings.meta )
  const allowTotalSubtotal = settingsMeta.find(e => e.key === "allowTotalSubtotal").value
  const getLabel = (context, label, defaultValue) => {
    if(i18n !== undefined){
     return _.get(context,`labels.${label}`,defaultValue)
    }else{
      return defaultValue
    }
  }

  function getLocationName(lCode) {
    const location = locations.locations
      ? locations.locations.filter(l => l.locationCode.includes(lCode))
      : null
    return location ? location[0].locationName : ""
  }

  return (
    <i18n.Consumer>
      {context => (
        <React.Fragment>
          <Card style={{ margin: "10px" }} className={classes.quotationInfo}>
            <CardContent style={{ padding: "10px" }}>
              <Grid container spacing={1}>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <TextField
                    id="reservationnumber"
                    label={getLabel(context, "bookingSummary.reservation", "Reservation #")}
                    margin="dense"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={bookings.reservationNumber}
                  />
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <TextField
                    id="claimnumber"
                    label={getLabel(context, "bookingSummary.claim", "Claim #")}
                    margin="dense"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={quotation.claimnumber}
                  />
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <TextField
                    id="policynumber"
                    label={getLabel(context, "bookingSummary.policy", "Policy #")}
                    margin="dense"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={quotation.policynumber}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="sfirstname"
                    label={getLabel(context, "customer.firstname", "First Name")}
                    value={quotation.customer.firstname}
                    margin="dense"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="slastname"
                    label={getLabel(context, "customer.lastname", "Last Name")}
                    value={quotation.customer.lastname}
                    margin="dense"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ padding: "5px 10px", textAlign: "center" }}
                >
                  <div style={{ fontSize: "10px" }}>
                    {getLabel(context, "bookingSummary.pickup", "PICKUP")}
                  </div>
                  <div>{getLocationName(quotation.pickupLocation)}</div>
                  <div>
                    <Moment format="DD MMM, YYYY hh:mm A" parse="DD-MM-YYYY HH:mm">
                      {quotation.pickupDate + " " + quotation.pickupTime}
                    </Moment>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ padding: "5px 10px", textAlign: "center" }}
                >
                  <div style={{ fontSize: "10px" }}>
                    {getLabel(context, "bookingSummary.dropoff", "DROPOFF")}
                  </div>
                  <div>{getLocationName(quotation.dropoffLocation)}</div>
                  <div>
                    <Moment format="DD MMM, YYYY hh:mm A" parse="DD-MM-YYYY HH:mm">
                      {quotation.dropoffDate + " " + quotation.dropoffTime}
                    </Moment>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box
            height="calc(100vh - 106px)"
            width="700px"
            style={{ paddingTop: "5px", overflowY: "auto" }}
          >
            <div
              style={{
                padding: `0px 0px`,
                zIndex: `999`,
                width: `100%`,
                height: "100px",
                position: `absolute`
              }}
            >
              <Grid container spacing={1} justify="center">
                {bookings.vehicles.length != 0 && (
                  <Grid container alignItems="stretch">
                    <Grid item xs={6} style={{ padding: "0px 5px 0px 15px" }}>
                      <Paper className={classes.vehicleCard}>
                        <div
                          style={{
                            background: HERTZ_YELLOW,
                            width: "100%",
                            height: "20px",
                            position: "absolute",
                            top: "0px",
                            fontSize: "10px",
                            lineHeight: "20px"
                          }}
                        >
                          {getLabel(context, 
                            "bookingSummary.customerCharges",
                            "CUSTOMER CHARGES"
                          )}
                        </div>
                        <img
                          style={{ height: "60px", marginTop: 5 }}
                          src={`https://images.hertz.com/vehicles/220x128/${
                            bookings.vehicles.filter(
                              v => v.code == quotation.customerToReceive
                            )[0].image
                          }`}
                        />
                        {quotation.customerAcrissCategory && (
                          <div>
                            <Grid container spacing={1} justify="center">
                            </Grid>
                            <Grid container spacing={1} justify="center">
                              <Grid
                                item
                                xs={5}
                                style={{
                                  textAlign: "right",
                                  marginBottom: "-2px",
                                  fontSize: "13px"
                                }}
                              >
                                <strong>
                                  {getLabel(context, "bookingSummary.class", "Class")}
                                </strong>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{
                                  textAlign: "left",
                                  marginBottom: "-2px",
                                  fontSize: "13px"
                                }}
                              >
                                <strong>{quotation.customerToReceive} ({quotation.customerAcrissCode})</strong>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} justify="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                  marginBottom: "-2px",
                                  fontSize: "13px"
                                }}
                              >
                                <strong>
                                  {
                                    bookings.vehicles.filter(
                                      v => v.code == quotation.customerToReceive
                                    )[0].title
                                  }{" "}
                                  {getLabel(context, "bookingSummary.similar", "or similar")}
                                </strong>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "-2px" }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "right", marginBottom: "-2px" }}
                              >
                                {getLabel(context, "bookingSummary.category", "Category")}:
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "left", marginBottom: "-2px" }}
                              >
                                {quotation.customerAcrissCategory}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "-2px" }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "right", marginBottom: "-2px" }}
                              >
                                {getLabel(context, "bookingSummary.type", "Type")}:
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "left", marginBottom: "-2px" }}
                              >
                                {quotation.customerAcrissType}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "-2px" }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "right", marginBottom: "-2px" }}
                              >
                                {getLabel(context, 
                                  "bookingSummary.transmission",
                                  "Transmission"
                                )}
                                :
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "left", marginBottom: "-2px" }}
                              >
                                {quotation.customerAcrissTransmission}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "2px" }}
                            >
                              <Grid item xs={5} style={{ textAlign: "right" }}>
                                {getLabel(context, 
                                  "bookingSummary.fuelAndAir",
                                  "Fuel And Air"
                                )}
                                :
                              </Grid>
                              <Grid item xs={5} style={{ textAlign: "left" }}>
                                {quotation.customerAcrissFueldAndAir}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        {allowTotalSubtotal && bookings.estimate && (
                          <div style={{ fontSize: "20px" }}>
                            {numeral(bookings.estimate.Customer.Total).format(
                              "$0,0.00"
                            )}
                          </div>
                        )}
                        <Divider variant="middle" style={{ margin: "0 0 5px 0" }} />
                        {bookings.estimate && (
                          <div style={{ padding: "0 10px" }}>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px" }}
                            >
                              <Grid item xs={6} style={{ textAlign: "left" }}>
                                {getLabel(context, 
                                  "bookingSummary.description",
                                  "DESCRIPTION"
                                )}
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "right" }}>
                                {getLabel(context, "bookingSummary.qty", "QTY")}
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {allowTotalSubtotal && getLabel(context, "bookingSummary.subtotal", "SUBTOTAL")}
                              </Grid>
                            </Grid>
                            {bookings.estimate.Customer.TimeCharges &&
                              bookings.estimate.Customer.TimeCharges.map(
                                (tc, i) => (
                                  <Grid
                                    container
                                    spacing={1}
                                    justify="center"
                                    key={i}
                                    style={{ fontSize: "10px" }}
                                  >
                                    <Grid item xs={6} style={{ textAlign: "left" }}>
                                      {tc.Description}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ textAlign: "right" }}
                                    >
                                      {tc.Quantity}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{ textAlign: "right" }}
                                    >
                                      {allowTotalSubtotal && numeral(tc.SubTotal).format("$0,0.00")}
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            {bookings.estimate.Customer.Options &&
                              bookings.estimate.Customer.Options.map((o, i) => (
                                <Grid
                                  container
                                  spacing={1}
                                  justify="center"
                                  key={i}
                                  style={{ fontSize: "10px" }}
                                >
                                  <Grid item xs={6} style={{ textAlign: "left" }}>
                                    {o.Description}
                                  </Grid>
                                  <Grid item xs={2} style={{ textAlign: "right" }}>
                                    {o.Quantity}
                                  </Grid>
                                  <Grid item xs={4} style={{ textAlign: "right" }}>
                                    {allowTotalSubtotal && o.SubTotal.toFixed(2)}
                                  </Grid>
                                </Grid>
                              ))}
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px" }}
                            >
                              <Grid item xs={6} style={{ textAlign: "left" }}>
                                Tax
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{ textAlign: "right" }}
                              ></Grid>
                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {allowTotalSubtotal && bookings.estimate.Voucher &&
                                  (bookings.estimate.Customer.Total / 11).toFixed(
                                    2
                                  )}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#00000080",
                            display: bookings.isFetchingEstimate ? "block" : "none"
                          }}
                        >
                          <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Grid item>
                              <CircularProgress style={{ color: "#fff" }} />
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ padding: "0px 15px 0px 5px", alignText: "center" }}
                    >
                      <Paper className={classes.vehicleCard}>
                        <div
                          style={{
                            background: BLUE,
                            width: "100%",
                            height: "20px",
                            position: "absolute",
                            top: "0px",
                            fontSize: "10px",
                            lineHeight: "20px"
                          }}
                        >
                          {getLabel(context, 
                            "bookingSummary.InsurerCharges",
                            "INSURER CHARGES"
                          )}
                        </div>
                        <img
                          style={{ height: "60px", marginTop: 5 }}
                          src={`https://images.hertz.com/vehicles/220x128/${
                            bookings.vehicles.filter(
                              v => v.code == quotation.insurerChargedAs
                            )[0].image
                          }`}
                        />
                        {quotation.insurerAcrissCategory && (
                          <div>
                            <Grid container spacing={1} justify="center">
                              <Grid
                                item
                                xs={5}
                                style={{
                                  textAlign: "right",
                                  marginBottom: "-2px",
                                  fontSize: "13px"
                                }}
                              >
                                <strong>
                                  {getLabel(context, "bookingSummary.class", "Class")}
                                </strong>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{
                                  textAlign: "left",
                                  marginBottom: "-2px",
                                  fontSize: "13px"
                                }}
                              >
                                <strong>{quotation.insurerChargedAs} ({quotation.insurerAcrissCode})</strong>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} justify="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                  marginBottom: "-2px",
                                  fontSize: "13px"
                                }}
                              >
                                <strong>
                                  {
                                    bookings.vehicles.filter(
                                      v => v.code == quotation.insurerChargedAs
                                    )[0].title
                                  }{" "}
                                  {getLabel(context, "bookingSummary.similar", "or similar")}
                                </strong>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "-2px" }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "right", marginBottom: "-2px" }}
                              >
                                {getLabel(context, "bookingSummary.category", "Category")}:
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "left", marginBottom: "-2px" }}
                              >
                                {quotation.insurerAcrissCategory}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "-2px" }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "right", marginBottom: "-2px" }}
                              >
                                {getLabel(context, "bookingSummary.type", "Type")}:
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "left", marginBottom: "-2px" }}
                              >
                                {quotation.insurerAcrissType}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "-2px" }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "right", marginBottom: "-2px" }}
                              >
                                {getLabel(context, 
                                  "bookingSummary.transmission",
                                  "Transmission"
                                )}
                                :
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ textAlign: "left", marginBottom: "-2px" }}
                              >
                                {quotation.insurerAcrissTransmission}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px", marginBottom: "2px" }}
                            >
                              <Grid item xs={5} style={{ textAlign: "right" }}>
                                {getLabel(context, 
                                  "bookingSummary.fuelAndAir",
                                  "Fuel And Air"
                                )}
                                :
                              </Grid>
                              <Grid item xs={5} style={{ textAlign: "left" }}>
                                {quotation.insurerAcrissFueldAndAir}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        { allowTotalSubtotal && bookings.estimate && (
                          <div style={{ fontSize: "20px" }}>
                            {numeral(bookings.estimate.Voucher.Total).format(
                              "$0,0.00"
                            )}
                          </div>
                        )}
                        <Divider variant="middle" style={{ margin: "0 0 5px 0" }} />
                        {bookings.estimate && (
                          <div style={{ padding: "0 10px" }}>
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px" }}
                            >
                              <Grid item xs={6} style={{ textAlign: "left" }}>
                                {getLabel(context, 
                                  "bookingSummary.description",
                                  "DESCRIPTION"
                                )}
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "right" }}>
                                {getLabel(context, "bookingSummary.qty", "QTY")}
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {allowTotalSubtotal && getLabel(context, "bookingSummary.subtotal", "SUBTOTAL")}
                              </Grid>
                            </Grid>
                            {bookings.estimate.Voucher.TimeCharges &&
                              bookings.estimate.Voucher.TimeCharges.map((tc, i) => (
                                <Grid
                                  container
                                  spacing={1}
                                  justify="center"
                                  key={i}
                                  style={{ fontSize: "10px" }}
                                >
                                  <Grid item xs={6} style={{ textAlign: "left" }}>
                                    {tc.Description}
                                  </Grid>
                                  <Grid item xs={2} style={{ textAlign: "right" }}>
                                    {tc.Quantity}
                                  </Grid>
                                  <Grid item xs={4} style={{ textAlign: "right" }}>
                                    {allowTotalSubtotal && numeral(tc.SubTotal).format("$0,0.00")}
                                  </Grid>
                                </Grid>
                              ))}
                            {bookings.estimate.Voucher.Options &&
                              bookings.estimate.Voucher.Options.map((o, i) => (
                                <Grid
                                  container
                                  spacing={1}
                                  justify="center"
                                  key={i}
                                  style={{ fontSize: "10px" }}
                                >
                                  <Grid item xs={6} style={{ textAlign: "left" }}>
                                    {o.Description}
                                  </Grid>
                                  <Grid item xs={2} style={{ textAlign: "right" }}>
                                    {o.Quantity}
                                  </Grid>
                                  <Grid item xs={4} style={{ textAlign: "right" }}>
                                    {allowTotalSubtotal && o.SubTotal.toFixed(2)}
                                  </Grid>
                                </Grid>
                              ))}
                            <Grid
                              container
                              spacing={1}
                              justify="center"
                              style={{ fontSize: "10px" }}
                            >
                              <Grid item xs={6} style={{ textAlign: "left" }}>
                                Tax
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{ textAlign: "right" }}
                              ></Grid>
                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {allowTotalSubtotal && bookings.estimate.Voucher &&
                                  (bookings.estimate.Voucher.Total / 11).toFixed(2)}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#00000080",
                            display: bookings.isFetchingEstimate ? "block" : "none"
                          }}
                        >
                          <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Grid item>
                              <CircularProgress style={{ color: "#fff" }} />
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} style={{ padding: "0px 15px" }}>
                  <Card className={classes.infoCard} style={{ marginTop: "10px" }}>
                    <CardContent>
                      <Grid item container spacing={1}>
                        <Grid item xs={3}>
                          <TextField
                            id="sphone"
                            label={getLabel(context, "customer.phone", "Phone Number")}
                            value={quotation.customer.phone}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="smobile"
                            label={getLabel(context, "customer.mobile", "Mobile Number")}
                            value={quotation.customer.mobile}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="semail"
                            label={getLabel(context, "customer.email", "Email")}
                            value={quotation.customer.email}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="saddress"
                            label={getLabel(context, "customer.address", "Address")}
                            value={quotation.customer.address}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="scity"
                            label={getLabel(context, "customerDetails.city", "City")}
                            value={quotation.customer.city}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="spostcode"
                            label={getLabel(context, "customer.postcode", "Post Code")}
                            value={quotation.customer.postcode}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="scountry"
                            label={getLabel(context, "customer.country", "Country")}
                            value={quotation.customer.country}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="slicense"
                            label={getLabel(context, "customer.license", "License Number")}
                            value={quotation.customer.license}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="sissuer"
                            label={getLabel(context, "customer.issuer", "License Issuer")}
                            value={quotation.customer.issuer}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="sexpiry"
                            label={getLabel(context, "customer.expiry", "License Expiry")}
                            value={quotation.customer.expiry}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Divider
                          style={{
                            background: "grey",
                            height: 1,
                            width: "100%",
                            marginTop: 15,
                            marginBottom: 10
                          }}
                        />
                        <Grid item xs={6}>
                          <TextField
                            id="sadfirstname"
                            label={getLabel(context, 
                              "customer.addfirstname",
                              "Driver 2 First Name"
                            )}
                            value={quotation.customer.adfirstname}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="sadlastname"
                            label={getLabel(context, 
                              "customer.addlastname",
                              "Driver 2 Last Name"
                            )}
                            value={quotation.customer.adlastname}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="sadphone"
                            label={getLabel(context, "customer.adphone", "Phone Number")}
                            value={quotation.customer.adphone}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="sadmobile"
                            label={getLabel(context, "customer.admobile", "Mobile Number")}
                            value={quotation.customer.admobile}
                            margin="dense"
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Box>
        </React.Fragment>
      )}
    </i18n.Consumer>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden"
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  vehicleCard: cardWidget,
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& input": {
      fontSize: "12px",
      color: "#000",
      padding: "3px 0"
    },
    "& .MuiInputBase-root": {
      marginTop: "12px"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#000"
    }
  },
  quotationInfo: {
    "& input": {
      fontSize: "15px",
      color: "#000",
      padding: "3px 0"
    },
    "& .MuiInputBase-root": {
      marginTop: "12px"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#000"
    }
  }
}))

export default BookingsSummary
