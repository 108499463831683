import { takeLatest, put, call } from "redux-saga/effects";
import callApi from "./helpers";
import * as types from "../constants/actionTypes";
import * as appActions from "../actions/appActions";
import url from "url";
import { AUTH_SERVER_URL } from "../config";

const DefaultConfig = {
  mode: "cors",
  headers: {
    "Content-Type": "application/json"
  }
};

export function* login(payload) {
  const config = {
    ...DefaultConfig,
    method: "POST",
    body: JSON.stringify({
      scope: payload.scope,
      service: payload.service,
      username: payload.username,
      password: payload.password
    })
  };
  const uri = url.resolve(AUTH_SERVER_URL, "");

  try {
    yield call(callApi, () => fetch(uri, config), [types.LOGIN_REQUEST_SUCCESS, types.LOGIN_REQUEST_FAILURE]);
  } catch (error) {
    yield put(appActions.showMessage("Login Error:", error.message, "danger"));
  }
}

export function* watchLogin() {
  yield takeLatest(types.LOGIN_REQUEST, login);
}
