/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import TopBar from "../partials/TopBar";
import * as changePasswordActions from "../../actions/changePasswordActions"
import * as appActions from "../../actions/appActions"
import { Grid, Paper, TextField, Typography, Button, CircularProgress } from "@material-ui/core"

const ChangePassword = () => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => ({ ...state.auth }))
    const user = useSelector((state) => state.userInfo.settings.user)
    const success = useSelector((state) => state.changePassword.success)
    const isFetching = useSelector((state) => state.changePassword.isFetching)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [cPassword, setCPassword] = useState("")

    function handleChangePassword(e) {
        e.preventDefault()
        const user = {
            oldPassword,
            newPassword,
            cPassword
        }
        console.log({user})
        if (newPassword !== cPassword) {
            dispatch(appActions.showMessage("Request Error", "The new password doesn't match", "danger"))
        } else {
            dispatch(changePasswordActions.changePassword({ token: auth.token }, user))
        }
    }

    React.useEffect((() => {
        if(success && !isFetching) {
            setOldPassword("");
            setNewPassword("");
            setCPassword("");
        }
    }), [success, isFetching])

    return (
        <React.Fragment>
            <TopBar />
            <Grid
                container
                style={{
                    backgrounColor: "#ccc",
                    height: "100vh",
                }}
                alignContent="center"
                justify="center"
            >
                {user && (
                    <Paper style={{ width: "550px", height: "600px", padding: "30px 20px" }}>
                        <Typography variant="h4">Change Password</Typography>
                        <form>
                            <Grid item xs={12}>
                                <TextField
                                    id="currentPassword"
                                    label="Current Password"
                                    margin="normal"
                                    type="password"
                                    value={oldPassword}
                                    variant="outlined"
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="newPassword"
                                    label="New Password"
                                    value={newPassword}
                                    type="password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="passwordConfirm"
                                    label={"Confirm Password"}
                                    value={cPassword}
                                    type="password"
                                    onChange={(e) => setCPassword(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item xs={12} justify="center">
                                <Button
                                    type="submit"
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                    onClick={(e) => handleChangePassword(e)}
                                    disabled={isFetching}
                                >
                                    Change Password{" "}
                                    {isFetching && (
                                        <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                                    )}
                                </Button>
                            </Grid>
                        </form>
                    </Paper>
                )}
            </Grid>
        </React.Fragment>

    )
}

export default ChangePassword
