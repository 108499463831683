/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import { push } from "connected-react-router"
// import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
// import ListItem from "@material-ui/core/ListItem"
// import ListItemText from "@material-ui/core/ListItemText"

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LockResetIcon from '@mui/icons-material/LockReset';
import SettingsIcon from '@mui/icons-material/Settings';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from "@material-ui/core/Grid"
import Collapse from '@mui/material/Collapse';
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"

import * as userInfoActions from "../../actions/userInfoActions"
import * as authActions from "../../actions/authActions"

import moment from "moment"

const useStyles = makeStyles({
  list: {
    width: 380,
  },
})


const ProfileDrawer = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  // eslint-disable-next-line react/prop-types
  const drawer = props.open

  const userInfo = useSelector((state) => state.userInfo)
  const user = userInfo.settings.user
  const [configExpand, setConfigExpand] = useState(false)
  // const [defaultLocExpand, setDefaultLocExpand] = useState(false)


  // const allowedLocations = _.map(userInfo.settings.locations, "code") ?? []

  const toggleDrawer = () => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    props.setOpenProfileDrawer(false)
  }


  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  function handleChangePassword() {
    dispatch(push({ pathname: "/" + location.pathname.split("/")[1] + "/changePassword" }))
    // setCurrentTab("/changePassword")
  }

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={5} style={{ textAlign: "left", marginBottom: 10 }}>
          <h4 style={{ marginBottom: 0, paddingBottom: 0 }}>Your Profile</h4>
          <a
            onClick={handleLogout}
            style={{
              fontSize: 12,
              fontStyle: "italic",
              colour: "grey",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Logout
          </a>
        </Grid>
        <Grid item xs={5} style={{ textAlign: "right", marginRight: -7 }}>
          {props.errors && (
            <React.Fragment>
              <h4>{props.errors.length}</h4>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Divider style={{ background: "white" }} />
      {/* <List style={{ padding: 0 }}>
        {props.errors &&
          props.errors.map(({ error }, key) => (
            <React.Fragment key={key}>
              <ListItem style={{ background: "#f7d5d2" }}>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={1}>
                    {key + 1}.
                  </Grid>
                  <Grid item xs={11}>
                    <ListItemText>
                      <span dangerouslySetInnerHTML={{ __html: error }} />
                    </ListItemText>
                  </Grid>
                </Grid>
                <ListItemSecondaryAction>
                  <IconButton style={{ marginRight: -10 }} onClick={(e) => handleRemoveNotification(e, key)}>
                    <ClearIcon edge="end" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ background: "white" }} />
            </React.Fragment>
          ))}
      </List> */}
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Name" secondary={user.firstName + " " + user.lastName} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MailOutlineIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Email" secondary={user.emailAddress} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <HistoryToggleOffIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Last Login" secondary={moment(+user.lastLogin).format("YYYY-MM-DD HH:mm")} />
        </ListItem>
        <ListItemButton onClick={() => setConfigExpand(!configExpand)}>
          <ListItemAvatar>
            <Avatar>
              <SettingsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Configurate" />
          {configExpand ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={configExpand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 6 }} onClick={handleChangePassword}>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              <ListItemText primary="Reset Password" />
            </ListItemButton>
          </List>
          {/* <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 6 }} onClick={() => setDefaultLocExpand(!defaultLocExpand)}>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Set Default Location" />
              {defaultLocExpand ? <ExpandLess /> : <ExpandMore />}

            </ListItemButton>
            <Collapse in={defaultLocExpand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Autocomplete

                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={allowedLocations}
                  sx={{ pl: 12, pr: 6 }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </List>
            </Collapse>
          </List> */}
        </Collapse>
      </List>
    </div>
  )

  return (
    <div>
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer("right", false)}>
        {sideList("right")}
      </Drawer>
    </div>
  )
}

export default ProfileDrawer
