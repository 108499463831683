/* eslint-disable no-case-declarations */
import * as types from "../constants/actionTypes"
import initialState from "./initialState"
//import _ from "lodash"

export default function serviceManagerReducer(state = initialState.serviceManagers, action) {
    switch (action.type) {
        case types.SERVICE_MANAGERS.GET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.SERVICE_MANAGERS.GET.SUCCESS:
            return {
                ...state,
                data: action.result.data.GetServiceManagers,
                isFetching: false,
            }
        case types.SERVICE_MANAGERS.GET.FAILURE:
            return {
                ...state,
                data: [],
                isFetching: false,
            }
        case types.SERVICE_MANAGERS.DEMOTE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.SERVICE_MANAGERS.DEMOTE.SUCCESS:
            return {
                ...state,
                data: action.result.data.GetServiceManagers,
                isFetching: false,
            }
        case types.SERVICE_MANAGERS.DEMOTE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.SERVICE_MANAGERS.CLEAR:
            return {
                ...state,
                data: [],
                isFetching: false,
            }
        default:
            return state
    }
}