import { createContext } from "react"

export const i18n = createContext()

export const fetchi18n = async (serviceName, setService) => {
  try {
    const response = await fetch(`/i18n/${serviceName.toLowerCase()}/config.json`, {
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const contentType = response.headers.get("content-type")
    if (!contentType || !contentType.includes("application/json")) {
      throw new TypeError("Oops, we haven't got JSON!")
    }
    const json = await response.json()
    setService(json)
    return json
  } catch (error) {
    return false
  }
}
