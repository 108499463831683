import * as types from "../constants/actionTypes"

export function getReservationAnalytics(params, timePeriod) {
  return {
    type: types.RESERVATION_ANALYTICS.GET.REQUEST,
    payload: {
      ...params,
      timePeriod,
    },
  }
}
