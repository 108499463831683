import * as types from "../constants/actionTypes"
import { v4 as uuidv4 } from "uuid"

export function getRentals(params) {
  return {
    type: types.GET_RENTALS_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function getRental(params, rentalNumber, referenceId) {
  return {
    type: types.GET_RENTAL_REQUEST,
    payload: {
      ...params,
      rentalNumber,
      referenceId,
    },
  }
}

export function clearModifySuccess() {
  return {
    type: types.CLEAR_MODIFY_RESERVATION_SUCCESS,
  }
}

export function clearRental() {
  return {
    type: types.CLEAR_RESERVATION_REQUEST,
  }
}

export function clearEstimate() {
  return {
    type: types.ESTIMATE.CLEAR.SUCCESS,
  }
}

export function getReservations(params) {
  return {
    type: types.GET_RESERVATIONS_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function getReservation(params) {
  return {
    type: types.GET_RESERVATION_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setReservation(params) {
  return {
    type: types.SET_RESERVATION_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function modifyReservation(params) {
  return {
    type: types.MODIFY_RESERVATION_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function extendReservation(params) {
  return {
    type: types.RESERVATION.EXTEND.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function sequenceCancelReservation(params) {
  return {
    type: types.RESERVATION.SEQUENCE.DELETE.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function cancelReservation(params) {
  return {
    type: types.RESERVATION.DELETE.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function getVehicleModels(params) {
  return {
    type: types.GET_VEHICLE_MODEL_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function clearReservations(params) {
  return {
    type: types.CLEAR_RESERVATIONS_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function clearReservation(params) {
  return {
    type: types.CLEAR_RESERVATION_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function getRatesAvailability(params) {
  return {
    type: types.GET_RATESAVAILABILITY_REQUEST,
    payload: {
      ...params,
      // eslint-disable-next-line no-prototype-builtins
      referenceId: params.hasOwnProperty("referenceId") ? params.referenceId : uuidv4(),
    },
  }
}

export function getOptions(params) {
  return {
    type: types.GET_OPTIONS_REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setQuotation(params) {
  return {
    type: types.SET_QUOTATION,
    payload: {
      ...params,
    },
  }
}

export function clearQuotation(params) {
  return {
    type: types.CLEAR_QUOTATION,
    payload: {
      ...params,
    },
  }
}

export function getReservationEstimate(params) {
  return {
    type: types.ESTIMATE.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function getRentalEstimate(params) {
  return {
    type: types.RENTAL_ESTIMATE.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function extendRental(params) {
  return {
    type: types.RENTAL.EXTEND.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function modifyRental(params) {
  return {
    type: types.RENTAL.MODIFY.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setBookings(params) {
  return {
    type: "SETBOOKINGS",
    payload: {
      ...params,
    },
  }
}

export function downloadConfirmationEmail(params) {
  return {
    type: types.DOWNLOAD_CONFIRMATION_EMAIL,
    payload: {
      ...params,
    },
  }
}

export function existingResEstimate(params) {
  return {
    type: types.EXISTING_RES_ESTIMATE.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}
