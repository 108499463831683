import { takeLatest, put, call } from "redux-saga/effects"
import callApi from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"

export function* getLocations(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.locationObject.token != "" ? `Bearer ${action.payload.locationObject.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{ GetLocations(
          locationSearch: {
            street: "${action.payload.locationObject.street}"
            suburb: "${action.payload.locationObject.suburb}"
            state: "${action.payload.locationObject.state}"
            postCode: "${action.payload.locationObject.postcode}"
            country: "${action.payload.locationObject.country}"
          }
        ){
          locationName
          locationCode
          address1
          address2
          address3
          city
          state
          postCode
          country
          latitude
          longitude
          commercial
          distance
          hours {
            Monday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Tuesday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Wednesday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Thursday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Friday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Saturday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Sunday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
          }
        }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.LOCATIONS.GET.SUCCESS, types.LOCATIONS.GET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* getAllLocations(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{ 
        GetLocations(
          locationSearch:{   
            country: "Australia"
          }
        )
        {
          locationName
          locationCode
          address1
          address2
          address3
          city
          state
          postCode
          phone
          latitude
          longitude
          franchiseType
          locationType
          commercial
          hours {
            Monday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Tuesday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Wednesday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Thursday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Friday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Saturday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
            Sunday {
              closed
              open1
              close1
              open2
              close2
              open3
              close3
            }
          }
        }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.ALL_LOCATIONS.GET.SUCCESS, types.ALL_LOCATIONS.GET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* searchAddress(params) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: params.payload.token != "" ? `Bearer ${params.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: ` query {
        KleberDataValidation(
          Address: "${params.payload.address}",
          CountryCode : "${params.payload.countryCode}"
          Method:"${params.payload.method}",
  
        ){
          Success,
          Error,
          Status,
          International,
          SuggestionCountry,
          AddressResult{
            RecordId
            AddressLine,
            State,
            Locality,
            Postcode,
            Suburb,
            TownCityMailtown
            }
          }
        }   
    `,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.SEARCHADDRESS.GET.SUCCESS, types.SEARCHADDRESS.GET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
  // yield call(
  //   callApi,
  //   () => fetch(apiGraphQLURI, getGraphQLConfig(params, updateQuery)),
  //   types.SEARCHADDRESS,
  //   { ...params.payload }
  // );
}

export function* watchLocationsRequest() {
  yield takeLatest(types.LOCATIONS.GET.REQUEST, getLocations)
  yield takeLatest(types.ALL_LOCATIONS.GET.REQUEST, getAllLocations)
  yield takeLatest(types.SEARCHADDRESS.GET.REQUEST, searchAddress)
  yield takeLatest(types.RETRIEVEADDRESS.GET.REQUEST, getAllLocations)
}
