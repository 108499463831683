/* eslint-disable react/prop-types */

import React, { useEffect, useContext, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import InputMask from "react-input-mask"
import { Grid, TextField, MenuItem, Divider, Typography } from "@material-ui/core"

import * as bookingsActions from "../../actions/bookingsActions"
import { i18n } from "../i18n"
import _ from "lodash"

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    "& label.MuiFormLabel-root": {
      backgroundColor: "#fff",
      marginLeft: "-5px",
      padding: "0 6px",
    },
  },
}))

const BookingDetails = ({ clickedNext }) => {
  const classes = useStyles()
  const quotation = useSelector((state) => ({ ...state.quotation }))
  const [localQuotation, setLocalQuotation] = useState({ ...quotation })
  const dispatch = useDispatch()

  useEffect(() => {
    if (quotation.cleared) {
      setLocalQuotation({ ...quotation })
    }
  }, [quotation.cleared])

  const labelContext = useContext(i18n)
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  const expiryLabel = getLabel("customer.expiry", "License Expiry")
  const phoneNoLabel = getLabel("customer.phone", "Phone Number")
  const mobileNoLabel = getLabel("customer.mobile", "Mobile Number")
  const postCodeLabel = getLabel("customer.postcode", "Post Code")
  const adPhoneLabel = getLabel("customer.adphone", "Phone Number")
  const adMobileLabel = getLabel("customer.admobile", "Mobile Number")

  const handleCustomerBlur = (e) => {
    dispatch(
      bookingsActions.setQuotation({
        customer: {
          ...quotation.customer,
          [e.target.name || e.target.id]: e.target.value,
        },
      })
    )
  }

  const handleCustomerChange = (e) => {
    setLocalQuotation({
      ...localQuotation,
      customer: {
        ...localQuotation.customer,
        [e.target.name || e.target.id]: e.target.value,
      },
    })
  }

  const handleQuotationBlur = (e) => {
    dispatch(
      bookingsActions.setQuotation({
        [e.target.name || e.target.id]: e.target.value,
      })
    )
  }

  const handleQuotationChange = (e) => {
    setLocalQuotation({
      ...localQuotation,
      [e.target.name || e.target.id]: e.target.value,
    })
  }

  return (
    <Grid container spacing={0} justify="center" className={classes.root}>
      <form autoComplete="off">
        <Grid container item xs={12} spacing={1} justify="space-between">
          <Grid item xs={6}>
            <TextField
              required
              id="firstname"
              autoComplete="off-1"
              value={localQuotation.customer.firstname}
              error={_.isEmpty(localQuotation.customer.firstname) && clickedNext}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              label={getLabel("customer.firstname", "First Name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="lastname"
              autoComplete="off-2"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.lastname}
              error={_.isEmpty(localQuotation.customer.lastname) && clickedNext}
              label={getLabel("customer.lastname", "Last Name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        
        <Grid container item spacing={1} justify="center" xs={12}>
          <Grid item xs={12}>
            <TextField
              required
              id="address"
              autoComplete="off-6"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.address}
              error={_.isEmpty(localQuotation.customer.address) && clickedNext}
              label={getLabel("customer.address", "Address")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1} justify="flex-end" xs={12}>
          <Grid item xs={6}>
            <TextField
              required
              id="city"
              autoComplete="off-7"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.city}
              error={_.isEmpty(localQuotation.customer.city) && clickedNext}
              label={getLabel("customer.city", "City")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="country"
              autoComplete="off-8"
              name="country"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              select
              value={localQuotation.customer.country}
              error={_.isEmpty(localQuotation.customer.country) && clickedNext}
              label={getLabel("customer.country", "Country")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key={1} value="AU">
                Australia
              </MenuItem>
              <MenuItem key={2} value="NZ">
                New Zealand
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <InputMask
              mask="9999"
              value={localQuotation.customer.postcode}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              maskChar=" "
            >
              {() => (
                <TextField
                  required
                  id="postcode"
                  autoComplete="off-9"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label={postCodeLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </Grid>

        </Grid>
        <Grid container item spacing={1} justify="center" xs={12}>
          <Grid item xs={6}>
            <TextField
              required
              id="email"
              autoComplete="off-3"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.email}
              error={_.isEmpty(localQuotation.customer.email) && clickedNext}
              label={getLabel("customer.email", "Email")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type = "email"
            />
          </Grid>
          <Grid item xs={3}>
            <InputMask
              mask="\+\(69\)999999999"
              value={localQuotation.customer.phone}
              error={_.isEmpty(localQuotation.customer.phone) && clickedNext}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              maskChar=" "
            >
              {() => (
                <TextField
                  required
                  id="phone"
                  autoComplete="off-4"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label={phoneNoLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={3}>
            <InputMask
              mask="\+\(69\)999999999"
              value={localQuotation.customer.mobile}
              error={_.isEmpty(localQuotation.customer.mobile) && clickedNext}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              maskChar=" "
            >
              {() => (
                <TextField
                  required
                  id="mobile"
                  autoComplete="off-5"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label={mobileNoLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
        <Grid container item spacing={1} justify="flex-end" xs={12}>
          <Grid item xs={12}>
            <Divider variant="middle" style={{ margin: "10px 0" }} />
            <Typography variant="h6">Driver&apos;s License</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="license"
              autoComplete="off-10"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.license}
              /* error={_.isEmpty(localQuotation.customer.license) && clickedNext} */
              label={getLabel("customer.license", "License Number")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="issuer"
              autoComplete="off-11"
              name="issuer"
              select
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.issuer}
              label={getLabel("customer.issuer", "License Issuer")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key={1} value="AC">
                A.C.T
              </MenuItem>
              <MenuItem key={1} value="NS">
                New South Wales
              </MenuItem>
              <MenuItem key={1} value="NT">
                Northern Territory
              </MenuItem>
              <MenuItem key={1} value="QL">
                Queensland
              </MenuItem>
              <MenuItem key={1} value="SA">
                South Australia
              </MenuItem>
              <MenuItem key={1} value="TS">
                Tasmania
              </MenuItem>
              <MenuItem key={1} value="VI">
                Victoria
              </MenuItem>
              <MenuItem key={1} value="WA">
                Western Australia
              </MenuItem>
              <MenuItem key={2} value="NZ">
                New Zealand
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <InputMask
              mask="9999-99-99"
              value={localQuotation.customer.expiry}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              maskChar=" "
            >
              {() => (
                <TextField
                  id="expiry"
                  autoComplete="off-12"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder="YYYY-MM-DD"
                  label={expiryLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" style={{ margin: "10px 0" }} />
            <Typography variant="h6">Additional Driver</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="adfirstname"
              autoComplete="off-13"
              type="search"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.adfirstname}
              label={getLabel("customer.addfirstname", "First Name")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="adlastname"
              autoComplete="off-14"
              type="search"
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              value={localQuotation.customer.adlastname}
              label={getLabel("customer.addlastname", "Last Name")}
              margin="dense"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputMask
              mask="\+\(69\) 9999 9999"
              value={localQuotation.customer.adphone}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              maskChar=" "
            >
              {() => (
                <TextField
                  id="adphone"
                  autoComplete="off-15"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder=""
                  label={adPhoneLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={6}>
            <InputMask
              mask="\+\(69\) 999 999 999"
              value={localQuotation.customer.admobile}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              maskChar=" "
            >
              {() => (
                <TextField
                  id="admobile"
                  autoComplete="off-16"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label={adMobileLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" style={{ margin: "10px 0" }} />
            <Typography variant="h6">Policy Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="claimnumber"
              autoComplete="off-17"
              value={localQuotation.claimnumber}
              error={_.isEmpty(localQuotation.claimnumber) && clickedNext}
              onChange={handleQuotationChange}
              onBlur={handleQuotationBlur}
              inputProps={{ maxLength: 20 }}
              label={getLabel("customer.claimnumber", "Claim Number")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="policynumber"
              autoComplete="off-18"
              onChange={handleQuotationChange}
              onBlur={handleQuotationBlur}
              value={localQuotation.policynumber}
              error={_.isEmpty(localQuotation.policynumber) && clickedNext}
              inputProps={{ maxLength: 15 }}
              label={getLabel("customer.policynumber", "Policy Number")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default BookingDetails
