import * as types from "../constants/actionTypes"

export function getVehicleGroups(params) {
    return {
        type: types.VEHICLE_GROUPS.GET.REQUEST,
        payload: {
            ...params,
        },
    }
}

export function createVehicleGroup(params, vehicleGroup) {
    return {
        type: types.VEHICLE_GROUPS.CREATE.REQUEST,
        payload: {
            ...params,
            vehicleGroup
        },
    }
}

export function deleteVehicleGroup(params, vehicleGroup) {
    return {
        type: types.VEHICLE_GROUPS.DELETE.REQUEST,
        payload: {
            ...params,
            vehicleGroup
        },
    }
}

export function setVehicleGroup(params, vehicleGroup) {
    return {
        type: types.VEHICLE_GROUPS.SET.REQUEST,
        payload: {
            ...params,
            vehicleGroup,
        },
    }
}

export function disableVehicleGroup(params, vehicleGroup) {
    return {
        type: types.VEHICLE_GROUPS.SET.REQUEST,
        payload: {
            ...params,
            vehicleGroup,
        },
    }
}

export function clearLocationGroupss() {
    return {
        type: types.VEHICLE_GROUPS.CLEAR,
    }
}