/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import * as authActions from "../../actions/authActions"
import * as forgotPasswordActions from "../../actions/forgotPasswordActions"
import * as userInfoActions from "../../actions/userInfoActions"
import * as locationsActions from "../../actions/locationsActions"
import * as bookingsActions from "../../actions/bookingsActions"
import { i18n } from "../i18n"
import _ from "lodash"
import axios from "axios"

import { Grid, Paper, TextField, Typography, Button, CircularProgress } from "@material-ui/core"

let path = window.location.pathname
path = path.split("/")
const brand = _.toLower(path[1])

export default function forgotPassword(props) {
  const dispatch = useDispatch()
  const success = useSelector((state) => state.forgotPassword.success)
  const isFetching = useSelector((state) => state.forgotPassword.isFetching)
  const [redirect, setRedirect] = useState(false);

  const base = location.pathname.split("/")[1].toUpperCase()
  const labelContext = useContext(i18n)


  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  const [emailAddress, setEmailAdress] = useState("");
  const backToLogin = () => {
    dispatch(forgotPasswordActions.clearForgotPassword())
    setRedirect(true);
  }
  const clearForgotPasswordState = () => {
    dispatch(forgotPasswordActions.clearForgotPassword())
  }
  const requestResetPassword = () => {
    dispatch(forgotPasswordActions.requestPasswordReset(emailAddress ,base.toUpperCase()))
  }

  if (redirect === true) {
    return <Redirect to={`/${base}/login`} />
  }
  if (!isFetching && success === true) {
    return (<Grid
      container
      style={{
        backgrounColor: "#ccc",
        height: "100vh",
        backgroundImage: 'url("https://static.hertz.asiapac.io/portal/img/LoYLaas.jpg")',
      }}
      justify="flex-end"
    >
      <Grid
        container
        item
        style={{ width: "650px", backgroundColor: "#000", opacity: "0.8" }}
        justify="center"
        alignItems="center"
      >
        <Paper style={{ width: "550px", height: "600px", padding: "30px 20px", background: "#e0dada" }}>
          <Grid container direction="row" justify="center" alignItems="flex-start" style={{ marginBottom: 50 }}>
            <img src={`${"/i18n/hertzlogo.png"}`} height="50px" />
            <img src={`https://static.hertz.asiapac.io/mybookingportal/${brand}-logo.png`} height="50px" />
          </Grid>
          <Typography variant="h4">Forgot Password</Typography>
          <Typography variant="p">A message will be sent to your email to reset your password</Typography>
          <Button
            type="submit"
            onClick={backToLogin}
            color="secondary"
            size="large"
            variant="contained"
          >
            Back To Login
          </Button>
        </Paper>
      </Grid>
    </Grid>)
  }

  if (!isFetching && success === false) {
    return (<Grid
      container
      style={{
        backgrounColor: "#ccc",
        height: "100vh",
        backgroundImage: 'url("https://static.hertz.asiapac.io/portal/img/LoYLaas.jpg")',
      }}
      justify="flex-end"
    >
      <Grid
        container
        item
        style={{ width: "650px", backgroundColor: "#000", opacity: "0.8" }}
        justify="center"
        alignItems="center"
      >
        <Paper style={{ width: "550px", height: "600px", padding: "30px 20px", background: "#e0dada" }}>
          <Grid container direction="row" justify="center" alignItems="flex-start" style={{ marginBottom: 50 }}>
            <img src={`${"/i18n/hertzlogo.png"}`} height="50px" />
            <img src={`https://static.hertz.asiapac.io/mybookingportal/${brand}-logo.png`} height="50px" />
          </Grid>
          <Typography variant="h4">Forgot Password</Typography>
          <Typography variant="body1">Something went wrong please try again</Typography>
          <Button
            type="submit"
            onClick={clearForgotPasswordState}
            color="secondary"
            size="large"
            variant="contained"
          >
            Try Again
          </Button>
        </Paper>
      </Grid>
    </Grid>)
  }
  return (
    <Grid
      container
      style={{
        backgrounColor: "#ccc",
        height: "100vh",
        backgroundImage: 'url("https://static.hertz.asiapac.io/portal/img/LoYLaas.jpg")',
      }}
      justify="flex-end"
    >
      <Grid
        container
        item
        style={{ width: "650px", backgroundColor: "#000", opacity: "0.8" }}
        justify="center"
        alignItems="center"
      >
        <Paper style={{ width: "550px", height: "600px", padding: "30px 20px", background: "#e0dada" }}>
          <Grid container direction="row" justify="center" alignItems="flex-start" style={{ marginBottom: 50 }}>
            <img src={`${"/i18n/hertzlogo.png"}`} height="50px" />
            <img src={`https://static.hertz.asiapac.io/mybookingportal/${brand}-logo.png`} height="50px" />
          </Grid>
          <Typography variant="h4">Forgot Password</Typography>
          <Grid item xs={12}>
            <TextField
              id="emailAddress"
              label={getLabel("login.Email", "Email")}
              value={emailAddress}
              onChange={(e) => setEmailAdress(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justify="space-between">
            <Grid item>
              <Link to={`/${base}/login`}>
                <Typography variant="body1">Back to Login</Typography>
              </Link>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                onClick={requestResetPassword}
                color="secondary"
                size="large"
                variant="contained"
                disabled={isFetching}
              >
                Reset Password{" "}
                {isFetching && (
                  <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
