/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { takeLatest, put, call, all, delay } from "redux-saga/effects"
import moment from "moment"
import _ from "lodash"
import ejs from "ejs/ejs.min.js"

import callApi, { makeRequest } from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import * as bookingsActions from "../actions/bookingsActions"
import * as emailLogic from "../businesslogic/email"
import url from "url"
import { API_SERVER_URL } from "../config"

export function* getRentals(actions) {
  const since = moment().subtract(2, 'days').format("YYYY-MM-DDT00:00")
  const sinceParams = actions.payload.overdue ? "" : `Since: "${since}",`
  const overdueParams = actions.payload.overdue ? `Overdue: true,` : ""
  const graphqlParams = `${sinceParams} ${overdueParams} Modifications: true`
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{  ReservationSync(${graphqlParams}) {
            ReferenceId
            ReservationNumber
            ReservationStatus
            RentalNumber
            PickUp {
              DateTime
              Location
            }
            DropOff {
              DateTime
              Location
            }
            RentalNumber
            RentalStatus
            LastModifiedTime
            PackageCode
            StaffId
            Modifications {
              Original {
                PickUp {
                  LocationCode
                  DateTime
                }
                DropOff {
                  LocationCode
                  DateTime
                }
                Service
                ServiceUser
                RateSession
                PackageCode
                DateDayRentalBegins
                DateDayRentalEnds
                DateCreated
                DateModified
                ClaimNumber
                OptionSession
                ReservationNumber
                ReservationStatus
                VehicleClass
                VoucherClass
              }
              Update {
                PickUp {
                  LocationCode
                  DateTime
                }
                DropOff {
                  LocationCode
                  DateTime
                }
                Service
                ServiceUser
                RateSession
                PackageCode
                DateDayRentalBegins
                DateDayRentalEnds
                DateCreated
                DateModified
                ClaimNumber
                OptionSession
                ReservationNumber
                ReservationStatus
                VehicleClass
                VoucherClass
              }
            }
            DobOfYoungestDriver
            VehicleClass
            VoucherClass
          }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.GET_RENTALS_REQUEST_SUCCESS,
      types.GET_RENTALS_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* getRental(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
        RetrieveRental
        (
          RentalNumber: "${actions.payload.rentalNumber}",
          withBilling: true
          withLocationDetails: true
        )
        {
            Success
            ReferenceId
            RentalStatus
            RentalNumber
            ReservationNumber
            PhoneNumber
            MainNote
            Notes
            Pickup{
              Location {
                locationName
                locationCode
              }
              DateTime
            }
            DropOff{
              Location {
                locationName
                locationCode
              }
              DateTime
            }
            AdditionalDriverNotes{
              FirstName
              LastName
              Phone
              Mobile
            }
            Renter{
              Title
              FirstName
              LastName
              Address{
                Street
                City
                PostalCode
                CountryCode
                Email
                StateOrProvCode
                HomeTelephoneNumber
                CellTelephoneNumber
              }
              DrivingLicense{
                Number
                Issuer
              }
            }
            RateClass
            Insurance{
              ClaimNumber
              PolicyNumber
            }
            Voucher{
              Class
              VoucherDays
              Days
              RevAmount
            }
            PackageCode
            PackageName
            Options{
              Code
              Quantity
            }
            Billing{
            Customer{
              Total
              Options{
                  Code
                  Quantity
              }
              TimeCharges{
                  Quantity
                  Description
                  RateExclTax
                  SubTotal
                  Total
                  Tax
              }
              Distance{
                  RateExclTax
                  RateInclTax
                  Tax
                  Included
                  Unit
                  Unlimited
              }
            }
            Voucher{
              Total
              Options{
                  Code
                  Quantity
              }
              TimeCharges{
                  Quantity
                  Description
                  RateExclTax
                  SubTotal
                  Total
                  Tax
              }
              Distance{
                  RateExclTax
                  RateInclTax
                  Tax
                  Included
                  Unit
                  Unlimited
              }
            }
          }
        }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  let estimateCall = null
  try {
    estimateCall = yield call(callApi, () => fetch(uri, config), [
      types.GET_RENTAL_REQUEST_SUCCESS,
      types.GET_RENTAL_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(estimateCall, "errors")) {
    yield put({
      type: "FAILED_EXTENSION",
      payload: {
        rental: {
          RentalNumber: actions.payload.rentalNumber,
          referenceId: actions.payload.referenceId,
        },
        // TODO, loop through errors and exceptions to get all handled.
        error: estimateCall.errors[0].exceptions[0],
      },
    })
  }
}

function* getReservations(actions) {
  const since = moment().subtract(2, 'days').format("YYYY-MM-DDT00:00")
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{  ReservationSync(Modifications: true, Since: "${since}") {
              ClaimNumber
              ReferenceId      
              ReservationNumber
              ReservationStatus
              PickUp {
                DateTime
                Location
              }
              DropOff {
                DateTime
                Location
              }
              RentalNumber
              RentalStatus
              LastModifiedTime
              StaffId
              PackageCode
              Modifications {
                Original {
                  PickUp {
                    LocationCode
                    DateTime
                  }
                  DropOff {
                    LocationCode
                    DateTime
                  }
                  Service
                  ServiceUser
                  RateSession
                  PackageCode
                  DateDayRentalBegins
                  DateDayRentalEnds
                  DateCreated
                  DateModified
                  ClaimNumber
                  OptionSession
                  ReservationNumber
                  ReservationStatus
                  VehicleClass
                  VoucherClass
                }
                Update {
                  PickUp {
                    LocationCode
                    DateTime
                  }
                  DropOff {
                    LocationCode
                    DateTime
                  }
                  Service
                  ServiceUser
                  RateSession
                  PackageCode
                  DateDayRentalBegins
                  DateDayRentalEnds
                  DateCreated
                  DateModified
                  ClaimNumber
                  OptionSession
                  ReservationNumber
                  ReservationStatus
                  VehicleClass
                  VoucherClass
                }
              }
              DobOfYoungestDriver
              VehicleClass
              VoucherClass
            }
          }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.GET_RESERVATIONS_REQUEST_SUCCESS,
      types.GET_RESERVATIONS_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* getReservation(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{ RetrieveReservation
                (
                  ReservationNumber: "${actions.payload.reservationNumber}"
                  withBilling: ${!_.get(actions, "payload.nobilling", false)}
                  withLocationDetails: true
                ){
                  Success
                  ReservationStatus
                  ReservationNumber
                  MainNote
                  Notes
                  ReferenceId
                  ReservationConfirmed
                  ConfirmationNumber
                  SourceCode
                  Pickup{
                    Location{
                      locationName
                      locationCode
                    }
                    DateTime
                  }
                  DropOff{
                    Location{
                      locationName
                      locationCode
                    }
                    DateTime
                  }
                  DobOfYoungestDriver
                  Renter{
                    Title
                    FirstName
                    LastName
                    Address{
                      Street
                      StateOrProvCode
                      PostalCode
                      CountryCode
                      City
                      Email
                      HomeTelephoneNumber
                      CellTelephoneNumber
                    }
                    DrivingLicense{
                      Number
                      Issuer
                      ExpiryDate
                    }
                  }
                  AdditionalDriverNotes{
                    FirstName
                    LastName
                    Phone
                    Mobile
                  }
                  RateClass
                  VoucherRate{
                    Class
                  }
                  Insurance{
                    ClaimNumber
                    PolicyNumber
                    StaffId
                  }
                  PackageCode
                  PackageName
                  Options{
                    Code
                    Quantity
                  }
                  PhoneNumber
                  DesiredOptions
                  Billing {
                    Customer {
                      Total
                      CurrencyCode
                      Options {
                        Code
                        Quantity
                        Description
                        SubTotal
                        Total
                        Tax
                        Included
                      }
                      TimeCharges {
                        Quantity
                        Description
                        RateExclTax
                        SubTotal
                        Total
                        Tax
                      }
                      Distance {
                        RateExclTax
                        RateInclTax
                        Tax
                        Included
                        Unit
                        Unlimited
                      }
                    }
                    Voucher {
                      Total
                      CurrencyCode
                      Options {
                        Code
                        Quantity
                        Description
                        SubTotal
                        Total
                        Tax
                        Included
                      }
                      TimeCharges {
                        Quantity
                        Description
                        RateExclTax
                        SubTotal
                        Total
                        Tax
                      }
                      Distance {
                        RateExclTax
                        RateInclTax
                        Tax
                        Included
                        Unit
                        Unlimited
                      }
                    }
                  }
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  let estimateCall = null
  try {
    estimateCall = yield call(callApi, () => fetch(uri, config), [
      types.GET_RESERVATION_REQUEST_SUCCESS,
      types.GET_RESERVATION_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(estimateCall, "errors")) {
    yield put({
      type: "FAILED_EXTENSION",
      payload: {
        rental: {
          RentalNumber: actions.payload.reservationNumber,
          referenceId: actions.payload.referenceId,
        },
        // TODO, loop through errors and exceptions to get all handled.
        error: estimateCall.errors[0].exceptions[0],
      },
    })
  }

  return estimateCall
}

function* setReservation(action) {
  const options =
    action.payload.options.length != 0
      ? `Options: ` + JSON.stringify(action.payload.options).replace(/\"([^(\")"]+)\":/g, "$1:")
      : ``
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
            CreateReservation(
              ReferenceId: "${action.payload.referenceId}"
              Insurance: {
                ClaimNumber: "${action.payload.claimnumber}"
                PolicyNumber: "${action.payload.policynumber}"
                StaffId: "${action.payload.staffid}"
              }
              Renter: {
                Title: ""
                FirstName: "${action.payload.customer.firstname}"
                LastName: "${action.payload.customer.lastname}"
                Address: {
                  Street: "${action.payload.customer.address.slice(0,30)}"
                  City: "${action.payload.customer.city}"
                  PostalCode: "${action.payload.customer.postcode}"
                  CountryCode: "${action.payload.customer.country}"
                  StateOrProvCode:""
                  Email: "${action.payload.customer.email}"
                  HomeTelephoneNumber: "${action.payload.customer.phone}"
                  CellTelephoneNumber: "${action.payload.customer.mobile}"
                } 
                DrivingLicense: {
                  Number: "${action.payload.customer.license}"
                  Issuer: "${action.payload.customer.issuer}"
                  ExpiryDate: "${action.payload.customer.expiry}"
                }
              }
              AdditionalDriver: {
                FirstName: "${action.payload.customer.adfirstname}"
                LastName: "${action.payload.customer.adlastname}"
                HomeTelephoneNumber: "${action.payload.customer.adphone}"
                CellTelephoneNumber: "${action.payload.customer.admobile}"
              }
              RateClass: "${action.payload.customerToReceive}"
              Voucher: {
                Class: "${action.payload.insurerChargedAs}"
              }
              ${options}
            ){
              Success
              ReservationStatus
              ReservationNumber
              ReservationConfirmed
              ConfirmationNumber
            }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.SET_RESERVATION_REQUEST_SUCCESS,
      types.SET_RESERVATION_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* modifyReservation(action) {
  // const options = action.payload.options
  //   ? action.payload.options.length != 0
  //     ? `Options: ` + JSON.stringify(action.payload.options).replace(/\"([^(\")"]+)\":/g, "$1:")
  //     : ``
  //   : ``

  let options;
  if (action.payload.options && action.payload.options?.length != 0) {
    const converted = action.payload.options.map((option) => {
      return JSON.stringify({ Code: option.Code, Quantity: option.Quantity}).replace(/\"([^(\")"]+)\":/g, "$1:")
    })
    options = converted
  } else {
    options = ""
  }
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
            ModifyReservation(
              ReservationNumber: "${action.payload.reservationNumber}"
              Insurance: {
                ClaimNumber: "${action.payload.claimnumber}"
                PolicyNumber: "${action.payload.policynumber}"
                StaffId: "${action.payload.staffid}"
              }
              Renter: {
                Title: ""
                FirstName: "${action.payload.customer.firstname}"
                LastName: "${action.payload.customer.lastname}"
                Address: {
                  Street: "${action.payload.customer.address.slice(0,30)}"
                  City: "${action.payload.customer.city}"
                  PostalCode: "${action.payload.customer.postcode}"
                  CountryCode: "${action.payload.customer.country}"
                  StateOrProvCode:""
                  Email: "${action.payload.customer.email}"
                  HomeTelephoneNumber: "${action.payload.customer.phone}"
                  CellTelephoneNumber: "${action.payload.customer.mobile}"
                } 
                DrivingLicense: {
                  Number: "${action.payload.customer.license}"
                  Issuer: "${action.payload.customer.issuer}"
                  ExpiryDate: "${action.payload.customer.expiry}"
                }
              }
              RateClass: "${action.payload.customerToReceive}"
              Voucher: {
                Class: "${action.payload.insurerChargedAs}"
              }
              Options: [${options}]
            ){
              Success
              ReservationStatus
              ReservationNumber
              ReservationConfirmed
              ConfirmationNumber
            }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  let estimateCall = null
  try {
    estimateCall = yield call(callApi, () => fetch(uri, config), [
      types.MODIFY_RESERVATION_REQUEST_SUCCESS,
      types.MODIFY_RESERVATION_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(estimateCall, "errors")) {
    yield put({
      type: types.SHOW_MESSAGE,
      payload: {
        title: "Error",
        text: estimateCall.errors[0].exceptions[0],
      },
    })
  } else {
    yield put({
      type: types.SHOW_MESSAGE,
      success: true,
      payload: {
        title: "Success",
        text: "Reservation Modified",
      },
    })
    yield put({
      type: types.GET_RESERVATIONS_REQUEST,
      payload: {
        token: action.payload.token,
      },
    })
    yield put({
      type: types.GET_RESERVATION_REQUEST,
      payload: {
        token: action.payload.token,
        reservationNumber: action.payload.reservationNumber,
        referenceId: action.payload.referenceId,
      },
    })
  }
}

function* getRatesAvailability(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
              GetRatesAvailability(
                ReferenceId: "${action.payload.referenceId}",
                Pickup:{
                  Location: "${action.payload.pickupLocation}",
                  DateTime: "${action.payload.pickupDateTime}"
                },
                DropOff:{
                  Location: "${action.payload.dropoffLocation}",
                  DateTime: "${action.payload.dropoffDateTime}"
                },
                PackageCode: "${action.payload.packageCode}",
                RateClass: "${action.payload.rateClass}",
                Renter: {
                  BirthDate: "${action.payload.dobOfYoungestDriver}"
                },
                Source:{
                  CountryCode:""
                }
              ){
            Success
            Rates {
              Class
              Availability
              CurrencyCode
              Rental {
                Estimate
                Distance {
                  Included
                  Unlimited
                  ExtraRate
                  Unit
                }
                OneWayFee
                RateOnlyEstimate
                Liability
              }
              Voucher {
                Estimate
                RateOnlyEstimate
                Liability
                OneWayFee
              }
            }
          }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.GET_RATESAVAILABILITY_REQUEST_SUCCESS,
      types.GET_RATESAVAILABILITY_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* getOptions(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `query getOptions{
          GetOptions(
            ReferenceId:"${action.payload.referenceId}"
            RateClass: "${action.payload.vehicleClass ?? action.payload.rateClass}"
          ){
            Success
            Options{
              Customer{
                Code
                Description
                CurrencyCode
                DamageLiability
                SubTotal
                Total
                Tax
                AllowQuantity
                Type
                Included
                UnitOfMeasure
              }
              Voucher{
                Code
                Description
                CurrencyCode
                DamageLiability
                SubTotal
                Total
                Tax
                AllowQuantity
                Type
                Included
                UnitOfMeasure
              }
            }
          }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    const result = yield call(callApi, () => fetch(uri, config), [
      types.GET_OPTIONS_REQUEST_SUCCESS,
      types.GET_OPTIONS_REQUEST_FAILURE,
    ])
    if (_.has(result, "errors")) {
      yield put(appActions.showMessage("Error ", result.errors[0].exceptions[0], "danger"))
    }
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* getReservationEstimate(action) {
  const options = action.payload.options
    ? action.payload.options.length != 0
      ? `Options: ` + JSON.stringify(action.payload.options).replace(/\"([^(\")"]+)\":/g, "$1:")
      : ``
    : ``
  const reservationNumber = action.payload.reservationNumber
    ? `ReservationNumber: "${action.payload.reservationNumber}"`
    : ``

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
          GetReservationEstimate(
            ReferenceId: "${action.payload.referenceId}"
            RateClass: "${action.payload.rateClass}"
            Voucher: {
              Class: "${action.payload.voucherClass}"
            }
            ${options}
            ${reservationNumber}
        ){
          Success
          Customer {
            Total
            CurrencyCode
            TimeCharges {
              Quantity
              Description
              RateExclTax
              SubTotal
              Total
              Tax
            }
            Options {
              Code
              Quantity
              Description
              SubTotal
              Total
              Tax
              Included
            }
            Distance {
              RateInclTax
              RateExclTax
              Included
              Unit
              Unlimited
            }
          }
          Voucher {
            Total
            CurrencyCode
            TimeCharges {
              Quantity
              Description
              RateExclTax
              SubTotal
              Total
              Tax
            }
            Options {
              Code
              Quantity
              Description
              SubTotal
              Total
              Tax
              Included
            }
            Distance {
              RateInclTax
              RateExclTax
              Included
              Unit
              Unlimited
            }
          }
        }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    const result = yield call(callApi, () => fetch(uri, config), [
      types.ESTIMATE.GET.SUCCESS,
      types.ESTIMATE.GET.FAILURE,
    ])
    if (_.has(result, "errors")) {
      yield put(appActions.showMessage("Error ", result.errors[0].exceptions[0], "danger"))
    }
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* getRentalEstimate(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
        GetRentalEstimate(
            RentalNumber: "${action.payload.rentalNumber}"
            DropOff: {
              Location: "${action.payload.location}"
              DateTime: "${action.payload.dateTime}"
            }
        ){
          Success
          Customer {
            Total
            Options {
              Code
              Quantity
              Description
              SubTotal
              Total
              Tax
              Included
            }
            TimeCharges {
              Quantity
              Description
              RateExclTax
              SubTotal
              Total
              Tax
            }
            Distance {
              RateExclTax
              RateInclTax
              RateExclTax
              Included
              Unit
              Unlimited
            }
          }
          Voucher {
            Total
            Options {
              Code
              Quantity
              Description
              SubTotal
              Total
              Tax
              Included
            }
            TimeCharges {
              Quantity
              Description
              RateExclTax
              SubTotal
              Total
              Tax
            }
            Distance {
              RateExclTax
              RateInclTax
              RateExclTax
              Included
              Unit
              Unlimited
            }
          }
        }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  let estimateCall = null
  try {
    estimateCall = yield call(callApi, () => fetch(uri, config), [
      types.RENTAL_ESTIMATE.GET.SUCCESS,
      types.RENTAL_ESTIMATE.GET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(estimateCall, "errors")) {
    yield put({
      type: "FAILED_EXTENSION",
      payload: {
        rental: {
          RentalNumber: action.payload.rentalNumber,
          referenceId: action.payload.referenceId,
        },
        // TODO, loop through errors and exceptions to get all handled.
        error: estimateCall.errors[0].exceptions[0],
      },
    })
  }
}

function* getVehicleModels(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{ GetVehicleModels{
                    code
                    image
                    title
                    acriss
                  }
                }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.GET_VEHICLE_MODEL_REQUEST_SUCCESS,
      types.GET_VEHICLE_MODEL_REQUEST_FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* cancelReservation(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{ CancelReservation
                    (
                      ReservationNumber: "${action.payload.reservationNumber}"
                    ){
                      Success
                    }
                }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.RESERVATION.DELETE.SUCCESS, types.RESERVATION.DELETE.REQUEST])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  yield getReservations({
    payload: { token: action.payload.token },
  })
}

function* sequenceCancelReservation(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{ CancelReservation
                  (
                    ReservationNumber: "${action.payload.ReservationNumber}"
                  ){
                    Success
                  }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    const result = yield call(makeRequest, () => fetch(uri, config), "json")
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  //delete unnecessary params in the action
  delete action.payload.ReservationNumber

  yield getReservations(action)
}

function* modifyRental(action) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: ` {  
                ModifyRental (
                  RentalNumber: "${action.payload.rentalNumber}",
                  DropOff: {
                    Location: "${action.payload.location}"
                    DateTime: "${action.payload.dateTime}"
                  }
                )
                {
                  Success
                  RentalStatus
                  ReferenceId
                  RentalNumber
                  ReservationNumber
                  Estimate {
                    Customer {
                      Total
                      Options {
                        Code
                        Quantity
                        Description
                        SubTotal
                        Total
                        Tax
                        Included
                      }
                      TimeCharges {
                        Quantity
                        Description
                        RateExclTax
                        SubTotal
                        Total
                        Tax
                      }
                      Distance {
                        RateExclTax
                        RateInclTax
                        Tax
                        Included
                        Unit
                        Unlimited
                      }
                    }
                  }
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  let estimateCall = null
  try {
    estimateCall = yield call(callApi, () => fetch(uri, config), [
      types.RENTAL.MODIFY.SUCCESS,
      types.RENTAL.MODIFY.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(estimateCall, "errors")) {
    yield put({
      type: "FAILED_EXTENSION",
      payload: {
        rental: {
          RentalNumber: action.payload.rentalNumber,
          referenceId: action.payload.referenceId,
        },
        // TODO, loop through errors and exceptions to get all handled.
        error: estimateCall.errors[0].exceptions[0],
      },
    })
  } else if (estimateCall) {
    yield put({
      type: "SUCCESSFUL_EXTENSION",
      payload: {
        rental: {
          RentalNumber: action.payload.rentalNumber,
          referenceId: action.payload.referenceId,
        },
      },
    })
    yield put(bookingsActions.getRentals({token: action.payload.token}))
    yield put(bookingsActions.getRental(
      { token: action.payload.token }, 
      action.payload.rentalNumber, 
      action.payload.referenceId
    ))
  }
}

function* extendRental(action) {
  yield put({
    type: "MODIFYRESULT",
    payload: {
      modifyIndex: 0,
    },
  })
  yield put({
    type: "CLEAR_FAILED_EXTENSIONS",
  })
  yield put({
    type: "CLEAR_SUCCESSFUL_EXTENSIONS",
  })
  yield put({
    type: "START_EXTENDING_RENTALS",
  })

  for (let i = 0; i < action.payload.items.length; i++) {
    const newAction = {
      payload: {
        token: action.payload.token,
        rentalNumber: action.payload.items[i].RentalNumber,
        location: action.payload.items[i].DropOff.Location,
        dateTime: moment(action.payload.items[i].DropOff.DateTime, "YYYY-MM-DDTHH:mm")
          .add(action.payload.extendDays, "d")
          .format("YYYY-MM-DDTHH:mm"),
        index: i,
      },
    }

    yield put({
      type: "MODIFYRESULT",
      payload: {
        modifyIndex: i + 1,
      },
    })

    const config = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: newAction.payload.token != "" ? `Bearer ${newAction.payload.token}` : null,
      },
      method: "POST",
      body: JSON.stringify({
        query: `{
                  GetRentalEstimate(
                    RentalNumber: "${newAction.payload.rentalNumber}"
                    DropOff: {
                      Location: "${newAction.payload.location}"
                      DateTime: "${newAction.payload.dateTime}"
                    }
                  )
                  {
                    Success
                    Customer {
                      Total
                      TimeCharges {
                        Quantity
                        Description
                        RateExclTax
                        SubTotal
                        Total
                        Tax
                      }
                      Options {
                        Code
                        Quantity
                        Description
                        SubTotal
                        Total
                        Tax
                        Included
                      }
                      Distance {
                        RateExclTax
                        RateInclTax
                        RateExclTax
                        Included
                        Unit
                        Unlimited
                      }
                    }
            
                    Voucher {
                      Total
                      TimeCharges {
                        Quantity
                        Description
                        RateExclTax
                        SubTotal
                        Total
                        Tax
                      }
                      Options {
                        Code
                        Quantity
                        Description
                        SubTotal
                        Total
                        Tax
                        Included
                      }
                      Distance {
                        RateExclTax
                        RateInclTax
                        RateExclTax
                        Included
                        Unit
                        Unlimited
                      }
                    }
                  }
                }`,
      }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    let estimateCall = null
    try {
      estimateCall = yield call(callApi, () => fetch(uri, config), [
        types.RENTAL.ESTIMATE.SUCCESS,
        types.RENTAL.ESTIMATE.FAILURE,
      ])
    } catch (error) {
      appActions.showMessage("Request Error", error.message, "danger")
    }

    if (_.has(estimateCall, "errors")) {
      yield put({
        type: "FAILED_EXTENSION",
        payload: {
          rental: action.payload.items[i],
          // TODO, loop through errors and exceptions to get all handled.
          error: estimateCall.errors[0].exceptions[0],
        },
      })
    } else if (estimateCall && estimateCall.data.GetRentalEstimate) {
      yield put({
        type: "SUCCESSFUL_EXTENSION",
        payload: {
          rental: action.payload.items[i],
        },
      })
      const modRes = yield modifyRental(newAction)
    }
  }

  yield put({
    type: "MODIFYRESULT",
    payload: {
      modifyIndex: action.payload.items.length + 1,
    },
  })

  yield put({
    type: "FINISH_EXTENDING_RENTALS",
  })

  yield getRentals(action)
}

function* existingResEstimate(action) {
  yield getRatesAvailability(action)
  yield getOptions(action)

  // const options = action.payload.options
  //   ? action.payload.options.length != 0
  //     ? `Options: ` + JSON.stringify(action.payload.options).replace(/\"([^(\")"]+)\":/g, "$1:")
  //     : ``
  //   : ``

  let options;
  if (action.payload.options && action.payload.options?.length != 0) {
    const converted = action.payload.options.map((option) => {
      return JSON.stringify({ Code: option.Code, Quantity: + option.Quantity.replace(/\"([^(\")"]+)\":/g, "$1:") }).replace(/\"([^(\")"]+)\":/g, "$1:")
    })
    options = converted
  } else {
    options = ""
  }

  const reservationNumber = action.payload.reservationNumber
    ? `ReservationNumber: "${action.payload.reservationNumber}"`
    : ``

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{
          GetReservationEstimate(
            ReferenceId: "${action.payload.referenceId}"
            RateClass: "${action.payload.rateClass}"
            Voucher: {
              Class: "${action.payload.voucherClass}"
            }
            Options: [${options}]
            ${reservationNumber}
        ){
          Success
          Customer {
            Total
            CurrencyCode
            TimeCharges {
              Quantity
              Description
              RateExclTax
              SubTotal
              Total
              Tax
            }
            Options {
              Code
              Quantity
              Description
              SubTotal
              Total
              Tax
              Included
            }
            Distance {
              RateInclTax
              RateExclTax
              Included
              Unit
              Unlimited
            }
          }
          Voucher {
            Total
            CurrencyCode
            TimeCharges {
              Quantity
              Description
              RateExclTax
              SubTotal
              Total
              Tax
            }
            Options {
              Code
              Quantity
              Description
              SubTotal
              Total
              Tax
              Included
            }
            Distance {
              RateInclTax
              RateExclTax
              Included
              Unit
              Unlimited
            }
          }
        }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.EXISTING_RES_ESTIMATE.GET.SUCCESS,
      types.EXISTING_RES_ESTIMATE.GET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* downloadConfirmationEmail(action) {
  let response = {}
  let data = {}

  if (!action.payload.test) {
    if (action.payload.mode == "reservation") {
      if (action.payload.type == "cancelReservation") {
        action.payload.nobilling = true
      }
      response = yield getReservation(action)
    } else {
      response = yield getRental(action)
    }

    data = response.data[action.payload.mode == "reservation" ? "RetrieveReservation" : "RetrieveRental"]
    data = emailLogic.transformSchema(data, {
      locations: action.payload.locations,
    })
  } else {
    data = action.payload.data
  }

  const userInfo = action.payload.userInfo
  let template = JSON.parse(_.get(_.find(userInfo.settings.meta, { "key": "obtGenericEmailTemplate" }), "value", ""))
  const emailTemplates = _.get(_.find(userInfo.settings.meta, { "key": "emailTemplates" }), "value", {})

  try {
    const template_data = {
      ...data,
      to: `${data.Renter.FirstName} ${data.Renter.LastName} <${data.Renter.Email}>`,
      cc: "",
      bcc: _.get(_.find(userInfo.settings.meta, { "key": "defaultEmailBCCAddresses" }), "value", []).join("; "),
      subject: ejs.render(emailTemplates[action.payload.type].subject, data),
      body: ejs.render(emailTemplates[action.payload.type].body, data),
      senderName: "",
      senderString: "",
    }

    template = ejs.render(template, template_data)
    const eml = new Blob([template], { type: "text/plain" })
    const emlblob = window.URL.createObjectURL(eml)

    // Construct the <a> element
    let link = document.createElement("a")
    if (action.payload.mode == "reservation") {
      link.download = `RES_${data.ReservationNumber}.eml`
    } else {
      link.download = `REN_${data.RentalNumber}.eml`
    }
    link.href = emlblob

    document.body.appendChild(link)
    link.click()

    // Cleanup the DOM
    document.body.removeChild(link)
  } catch (error) {
    const msgs = error.message.split("\n")
    yield put(appActions.showMessage("Request Error", msgs.pop(), "danger"))
  }
}

export function* watchBooking() {
  yield all([
    yield takeLatest(types.GET_RESERVATIONS_REQUEST, getReservations),
    yield takeLatest(types.GET_RESERVATION_REQUEST, getReservation),
    yield takeLatest(types.SET_RESERVATION_REQUEST, setReservation),
    yield takeLatest(types.MODIFY_RESERVATION_REQUEST, modifyReservation),
    yield takeLatest(types.GET_VEHICLE_MODEL_REQUEST, getVehicleModels),
    yield takeLatest(types.GET_RATESAVAILABILITY_REQUEST, getRatesAvailability),
    yield takeLatest(types.GET_OPTIONS_REQUEST, getOptions),
    yield takeLatest(types.ESTIMATE.GET.REQUEST, getReservationEstimate),
    yield takeLatest(types.RENTAL_ESTIMATE.GET.REQUEST, getRentalEstimate),
    yield takeLatest(types.RESERVATION.DELETE.REQUEST, cancelReservation),
    yield takeLatest(types.RENTAL.MODIFY.REQUEST, modifyRental),
    yield takeLatest(types.GET_RENTALS_REQUEST, getRentals),
    yield takeLatest(types.GET_RENTAL_REQUEST, getRental),
    yield takeLatest(types.RESERVATION.SEQUENCE.DELETE.REQUEST, sequenceCancelReservation),
    yield takeLatest(types.RENTAL.EXTEND.REQUEST, extendRental),
    yield takeLatest(types.EXISTING_RES_ESTIMATE.GET.REQUEST, existingResEstimate),
    yield takeLatest(types.DOWNLOAD_CONFIRMATION_EMAIL, downloadConfirmationEmail),
  ])
}
