import * as types from "../constants/actionTypes"

export function getLocationGroups(params) {
    return {
        type: types.LOCATION_GROUPS.GET.REQUEST,
        payload: {
            ...params,
        },
    }
}

export function createLocationGroup(params, locationGroupObject) {
    return {
        type: types.LOCATION_GROUPS.CREATE.REQUEST,
        payload: {
            ...params,
            locationGroupObject
        },
    }
}

export function deleteLocationGroup(params, locationGroupObject) {
    return {
        type: types.LOCATION_GROUPS.DELETE.REQUEST,
        payload: {
            ...params,
            locationGroupObject
        },
    }
}

export function setLocationGroup(params, locationGroupObject) {
    return {
        type: types.LOCATION_GROUPS.SET.REQUEST,
        payload: {
            ...params,
            locationGroupObject,
        },
    }
}

export function disableLocationGroup(params, locationGroupObject) {
    return {
        type: types.LOCATION_GROUPS.SET.REQUEST,
        payload: {
            ...params,
            locationGroupObject,
        },
    }
}

export function clearLocationGroupss() {
    return {
        type: types.LOCATION_GROUPS.CLEAR,
    }
}