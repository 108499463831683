import * as types from "../constants/actionTypes"

export function getUserGroups(params) {
    return {
        type: types.USER_GROUPS.GET.REQUEST,
        payload: {
            ...params,
        },
    }
}

export function createUserGroup(params, userGroup) {
    return {
        type: types.USER_GROUPS.CREATE.REQUEST,
        payload: {
            ...params,
            userGroup
        },
    }
}

export function deleteUserGroup(params, userGroup) {
    return {
        type: types.USER_GROUPS.DELETE.REQUEST,
        payload: {
            ...params,
            userGroup
        },
    }
}

export function setUserGroup(params, userGroup) {
    return {
        type: types.USER_GROUPS.SET.REQUEST,
        payload: {
            ...params,
            userGroup,
        },
    }
}

export function disableLocationGroup(params, userGroup) {
    return {
        type: types.USER_GROUPS.SET.REQUEST,
        payload: {
            ...params,
            userGroup,
        },
    }
}

export function clearLocationGroupss() {
    return {
        type: types.USER_GROUPS.CLEAR,
    }
}