/* eslint-disable react/prop-types */
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"
import ClearIcon from "@material-ui/icons/Clear"
import { useSelector, useDispatch } from "react-redux"
import { IconButton, ListItemSecondaryAction } from "@material-ui/core"
import _ from "lodash"

import * as userInfoActions from "../../actions/userInfoActions"

const useStyles = makeStyles({
  list: {
    width: 380,
  },
})

const NotificationDrawer = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  // eslint-disable-next-line react/prop-types
  const drawer = props.open

  const toggleDrawer = () => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    props.setNotificationDrawer(false)
  }

  function handleClearNotifications() {
    const notifications = []
    dispatch(userInfoActions.updateNotifications({ token: auth.token }, notifications))
  }

  function handleRemoveNotification(event, key) {
    const notifications = _.cloneDeep(props.errors)
    const temp = props.errors[props.errors.length - 1]
    notifications[key] = temp
    notifications.pop()

    dispatch(userInfoActions.updateNotifications({ token: auth.token }, notifications))
  }

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={5} style={{ textAlign: "left", marginBottom: 10 }}>
          <h4 style={{ marginBottom: 0, paddingBottom: 0 }}>Your Notifications</h4>
          <a
            onClick={handleClearNotifications}
            style={{
              fontSize: 12,
              fontStyle: "italic",
              colour: "grey",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Clear All
          </a>
        </Grid>
        <Grid item xs={5} style={{ textAlign: "right", marginRight: -7 }}>
          {props.errors && (
            <React.Fragment>
              <h4>{props.errors.length}</h4>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Divider style={{ background: "white" }} />
      <List style={{ padding: 0 }}>
        {props.errors &&
          props.errors.map(({ error }, key) => (
            <React.Fragment key={key}>
              <ListItem style={{ background: "#f7d5d2" }}>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={1}>
                    {key + 1}.
                  </Grid>
                  <Grid item xs={11}>
                    <ListItemText>
                      <span dangerouslySetInnerHTML={{ __html: error }} />
                    </ListItemText>
                  </Grid>
                </Grid>
                <ListItemSecondaryAction>
                  <IconButton style={{ marginRight: -10 }} onClick={(e) => handleRemoveNotification(e, key)}>
                    <ClearIcon edge="end" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ background: "white" }} />
            </React.Fragment>
          ))}
      </List>
    </div>
  )

  return (
    <div>
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer("right", false)}>
        {sideList("right")}
      </Drawer>
    </div>
  )
}

export default NotificationDrawer
