import * as types from "../constants/actionTypes"
export function getUsers(params) {
    return {
        type: types.USERS.GET.REQUEST,
        payload: {
            ...params,
        },
    }
}

export function getServiceUsers(params) {
    return {
        type: types.USERS.GET.REQUEST,
        payload: {
            ...params,
            user: {
                userType: "SERVICE_USERS"
            }
        },
    }
}

export function getServiceManagers(params) {
    return {
        type: types.USERS.GET.REQUEST,
        payload: {
            ...params,
            user: {
                userType: "SERVICE_MANAGERS"
            }
        },
    }
}

export function createUser(params, user) {
    return {
        type: types.USERS.CREATE.REQUEST,
        payload: {
            ...params,
            user
        },
    }
}

export function promoteUser(params, user) {
    return {
        type: types.USERS.PROMOTE.REQUEST,
        payload: {
            ...params,
            user
        },
    }
}

export function changePassword(params, user) {
    return {
        type: types.USERS.CHANGE_PASSWORD.REQUEST,
        payload: {
            ...params,
            user
        },
    }
}

export function deleteUser(params, user) {
    return {
        type: types.USERS.DELETE.REQUEST,
        payload: {
            ...params,
            user
        },
    }
}

export function setUser(params, user) {
    return {
        type: types.USERS.SET.REQUEST,
        payload: {
            ...params,
            user,
        },
    }
}

export function requireResetPassword(params, user) {
    return {
        type: types.USERS.REQUIRE_RESET_PASSWORD.REQUEST,
        payload: {
            ...params,
            user
        },
    }
}