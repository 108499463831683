/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Autocomplete from "@material-ui/lab/Autocomplete"
import EditIcon from "@material-ui/icons/Edit"
import EventIcon from "@material-ui/icons/Event"
import moment from "moment"
import React, { useEffect, Fragment, useContext } from "react"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import { useSelector, useDispatch } from "react-redux"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import RefreshIcon from "@material-ui/icons/Refresh"
import { userLogic } from "../../businesslogic"
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Button,
  makeStyles,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Modal,
  Fade
} from "@material-ui/core"
import _ from "lodash"
import { i18n } from "../i18n"

import * as bookingsActions from "../../actions/bookingsActions"
import tableIcons from "../../utils/helper"
import { capitaliseWord } from "../../utils/functions"

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const BulkExtend = () => {
  const auth = useSelector(state => state.auth)
  const bookings = useSelector(state => state.bookings)
  const classes = useStyles()
  const dispatch = useDispatch()
  const locations = useSelector(state => state.locations.locations)
  const rentalRules = useSelector(state => state.userInfo.settings.rentalRules)
  const user = useSelector(state => state.userInfo.settings.user)
  const [open, setOpen] = React.useState(false)
  const [dropOffLocation, setDropOffLocation] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [extendDays, setExtendDays] = React.useState(1)
  const [pickUpLocation, setPickUpLocation] = React.useState(null)
  const [rentalFilter, setRentalFilter] = React.useState(
    _.cloneDeep(bookings.reservations)
  )
  const [startDate, setStartDate] = React.useState(null)
  const [maximum, setMaximum] = React.useState(1)
  // const [pageLoaded, setPageLoaded] = React.useState(false)

  const labelContext = useContext(i18n)
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  const itCodes = userLogic.getITCodes()

  useEffect(() => {
    dispatch(bookingsActions.getRentals({ token: auth.token }))
    // setPageLoaded(true)

    dispatch({
      type: "FINISH_EXTENDING_RENTALS"
    })
  }, [])

  useEffect(() => {
    setRentalFilter(_.cloneDeep(bookings.reservations))
  }, [bookings.reservations])

  useEffect(() => {
    const filter = filterData()
    filter.map(f => {
      rentalFilter.map(i => {
        if (f.ReferenceId == i.ReferenceId) {
          f.tableData = i.tableData
        }
      })
    })
    setRentalFilter(filter)
  }, [startDate, endDate, pickUpLocation, dropOffLocation])

  // function disableRow(data) {
  //   if (_.intersection(user.allowedItCodes, [data.PackageCode]).length > 0) {
  //     return false
  //   } else if (user.userType != "SERVICE_USER") {
  //     return false
  //   }

  //   return true
  // }

  function handleSelectedRows(rows) {
    rentalFilter.map((i, index) => {
      // if (_.has(i, "tableData.checked")) {
      //   i.tableData.checked = false
      // }
      rows.map(r => {
        if (index == r) {
          i.tableData = { checked: true }
        }
      })
    })

    setRentalFilter(_.cloneDeep(rentalFilter))
  }

  let rowsData = rentalFilter ?
    rentalFilter.map((x, index) =>
      Object.assign({
        ...x,
        id: index,
        PickUpDate: moment(x.PickUp.DateTime, "YYYY-MM-DDTHH:mm").format("YYYY/MM/DD"),
        PickUpTime: moment(x.PickUp.DateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        PickUpLoc: x.PickUp.Location,
        DropOffDate: moment(x.DropOff.DateTime, "YYYY-MM-DDTHH:mm").format("YYYY/MM/DD"),
        DropOffTime: moment(x.DropOff.DateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        DropOffLoc: x.DropOff.Location,
      }, x))
    : []


  console.log('rentalFilter, rowsData', rentalFilter, rowsData)

  function filterData() {
    let filteredList = _.cloneDeep(bookings.reservations)

    if (pickUpLocation != null) {
      filteredList = filteredList.filter(val =>
        val.PickUp.Location.includes(pickUpLocation.locationCode)
      )
    }
    if (dropOffLocation != null) {
      filteredList = filteredList.filter(val =>
        val.DropOff.Location.includes(dropOffLocation.locationCode)
      )
    }
    if (startDate != null && endDate != null) {
      filteredList = filteredList.filter(
        val =>
          new Date(val.DropOff.DateTime).setHours(0, 0, 0, 0) >=
          startDate.toDate().setHours(0, 0, 0, 0) &&
          new Date(val.DropOff.DateTime).setHours(0, 0, 0, 0) <=
          endDate.toDate().setHours(0, 0, 0, 0)
      )
    } else if (startDate != null) {
      filteredList = filteredList.filter(
        val =>
          new Date(val.DropOff.DateTime).setHours(0, 0, 0, 0) >=
          startDate.toDate().setHours(0, 0, 0, 0)
      )
    } else if (endDate != null) {
      filteredList = filteredList.filter(
        val =>
          new Date(val.DropOff.DateTime).setHours(0, 0, 0, 0) <=
          endDate.toDate().setHours(0, 0, 0, 0)
      )
    }
    return filteredList
  }

  function handleLocationChange(value, locationType) {
    if (locationType == "pickUpLocation") {
      setPickUpLocation(value)
    } else if (locationType == "dropOffLocation") {
      setDropOffLocation(value)
    }
  }

  const handleDateTimeChange = (date, type) => {
    if (type == "startDateTime") {
      setStartDate(date)
    } else if (type == "endDateTime") {
      setEndDate(date)
    }
  }

  function handleItCodeChange(value) {
    // eslint-disable-next-line no-console
    console.log(value)
  }

  // eslint-disable-next-line no-unused-vars
  const handleExtendDaysBlur = e => {
    if (extendDays == "") {
      setExtendDays(1)
    }
  }

  const handleExtendDaysChange = e => {
    if (e.target.value >= 0) {
      setExtendDays(e.target.value)
    }
  }

  const addDaysDuration = e => {
    e.preventDefault()
    e.stopPropagation()
    setExtendDays(parseInt(extendDays) + 1)
  }

  const subtractDaysDuration = e => {
    e.preventDefault()
    e.stopPropagation()
    setExtendDays(parseInt(extendDays) - 1)
  }

  const data = rentalFilter ? rentalFilter.map(x => Object.assign({}, x)) : []

  function extendBookings() {
    let temp = 0
    let selected = []

    data.map(value => {
      if (
        _.has(value, "tableData.checked") &&
        value.tableData.checked == true
      ) {
        temp = temp + 1
        selected.push(value)
      }
    })

    dispatch(
      bookingsActions.extendRental({
        token: auth.token,
        extendDays: extendDays,
        items: selected
      })
    )

    handleClose()
  }

  // function rowCheckCondition(rowData) {
  //   if (
  //     _.filter(bookings.failedExtensions, {
  //       rentalNumber: rowData.RentalNumber
  //     }).length > 0
  //   ) {
  //     return "failed"
  //   } else if (
  //     _.filter(bookings.successfulExtensions, {
  //       rentalNumber: rowData.RentalNumber
  //     }).length > 0
  //   ) {
  //     return "success"
  //   }
  //   return false
  // }

  const handleOpen = () => {
    setOpen(true)
    let temp = 0
    setMaximum(0)

    // fix this so we don't repeat it in the extendBookings
    data.map(value => {
      if (
        _.has(value, "tableData.checked") &&
        value.tableData.checked == true
      ) {
        temp = temp + 1
      }
    })
    setMaximum(temp)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const CustomToolbar = () => {
    return (
      <React.Fragment>
        <GridToolbarContainer>
          <h3>Bulk Extend</h3>
          <div style={{ display: "flex", flexGrow: 1 }}></div>
          <div style={{ verticalAlign: "center" }}>
            <Tooltip title="Refresh Data">
              <span>
                <IconButton
                  disabled={bookings.isFetching}
                  onClick={() => dispatch(bookingsActions.getRentals({ token: auth.token }))
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>

            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <GridToolbarQuickFilter
              id="search_ra_input"
              style={{ width: "150px" }}
              quickFilterFormatter={(quickFilterValues) => {
                return quickFilterValues.join(", ");
              }}
            />
          </div>
        </GridToolbarContainer>
      </React.Fragment>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500
      // }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              Warning: Extending {maximum} rentals
            </h2>
            <p id="transition-modal-description">
              Are you sure you want to extend {maximum} rentals by {extendDays}{" "}
              days?
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ margin: "5px" }}
                onClick={extendBookings}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClose}
                style={{ margin: "5px" }}
              >
                No
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={5} lg={7} style={{
          height: "calc(100vh - 90px)"
        }}>
          <DataGrid
            isLoading={bookings.isFetching}
            className={classes.dataGrid + ' ' + classes.paper + ' ' + classes.checkBox}
            checkboxSelection
            disableRowSelectionOnClick
            slots={{ toolbar: CustomToolbar }}
            columns={[
              {
                headerName: "Claim No.",
                field: "ClaimNumber",
                minWidth: 120,
                headerClassName: classes.header
              },
              {
                headerName: "Rental No",
                field: "RentalNumber",
                headerClassName: classes.header
              },
              {
                headerName: "Status",
                field: "ReservationStatus",
                headerClassName: classes.header,
                maxWidth: 60,
                render: (r) => <Fragment>{capitaliseWord(r.ReservationStatus)}</Fragment>,
              },
              {
                headerName: "Pick Up Date",
                field: "PickUpDate",
                headerClassName: classes.header,
              },
              {
                headerName: "Pick Up Time",
                field: "PickUpTime",
                headerClassName: classes.header,
                hideable: true,
              },
              {
                headerName: "Pick Up Loc",
                field: "PickUpLoc",
                type: "string",
                headerClassName: classes.header,
              },
              {
                headerName: "Drop Off Date",
                field: "DropOffDate",
                headerClassName: classes.header,
              },
              {
                title: "Drop Off Time",
                field: "DropOffTime",
                headerClassName: classes.header,
                hideable: true,
              },
              {
                headerName: "Drop Off Loc",
                field: "DropOffLoc",
                headerClassName: classes.header,
                flex: 1
              },
            ]}
            rows={rowsData}
            icons={tableIcons}
            initialState={{
              sorting: {
                sortModel: [{ field: 'PickUpDate', sort: 'desc' }],
              },
            }}
            title="Extend Bookings 2"
            // onSelectionChange={rows => handleSelectedRows(rows)}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              console.log('newRowSelectionModel', newRowSelectionModel)
              handleSelectedRows(newRowSelectionModel);
            }}
          />
        </Grid>
        <Grid item xs={7} lg={5}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filter Bookings
              </Typography>
              <Grid
                container
                spacing={1}
                justify="flex-start"
                style={{ marginTop: 10 }}
              >
                <Grid item xs={12}>
                  DropOff Date Between
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    id="startDateTime"
                    label={getLabel("bulkextend.startDate", "Start Date")}
                    value={startDate}
                    onChange={d => {
                      handleDateTimeChange(d, "startDateTime")
                    }}
                    inputVariant="outlined"
                    fullWidth
                    format="dd DD MMM YYYY"
                    showTodayButton={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    id="endDateTime"
                    label={getLabel("bulkextend.endDate", "End Date")}
                    value={endDate}
                    onChange={d => {
                      handleDateTimeChange(d, "endDateTime")
                    }}
                    fullWidth
                    format="dd DD MMM YYYY"
                    showTodayButton
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <span style={{ color: "lightgrey", marginLeft: 3 }}>
                    * date inclusive
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <span style={{ color: "lightgrey", marginLeft: 3 }}>
                    * date inclusive
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={1}
                justify="flex-start"
                style={{ marginTop: 10 }}
              >
                <Grid item xs={6} style={{ marginTop: 5 }}>
                  <Autocomplete
                    options={locations}
                    getOptionLabel={option => option.locationName}
                    id="pickUpLocation"
                    name="pickUpLocation"
                    onChange={(event, value) =>
                      handleLocationChange(value, "pickUpLocation")
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        multiline
                        maxrows={1}
                        label="Pick Up Location"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 5 }}>
                  <Autocomplete
                    autoComplete={true}
                    options={locations}
                    getOptionLabel={option => option.locationName}
                    id="dropOffLocation"
                    name="dropOffLocation"
                    onChange={(event, value) =>
                      handleLocationChange(value, "dropOffLocation")
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        multiline
                        maxrows={1}
                        label="Drop Off Location"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={1}
                justify="flex-start"
                style={{ marginTop: 20 }}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    autoComplete={true}
                    options={itCodes}
                    getOptionLabel={option => option.name}
                    id="itCodes"
                    name="itCodes"
                    onChange={(event, value) => handleItCodeChange(value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="IT Codes"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>

              {/*  */}
              <Divider style={{ marginTop: 15, marginBottom: 10 }} />
              {/*  */}

              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item xs={5} style={{ marginBottom: -5 }}>
                  <TextField
                    id="dayExtend"
                    name="dayExtend"
                    label="No. of Days"
                    className={classes.textField}
                    onChange={handleExtendDaysChange}
                    value={extendDays}
                    onBlur={handleExtendDaysBlur}
                    margin="dense"
                    type="number"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      style: { textAlign: "center" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={e => subtractDaysDuration(e)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={e => addDaysDuration(e)}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {bookings.bulkExtendFinished && (
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={handleOpen}
                      style={{ paddingTop: 9 }}
                    >
                      <EditIcon style={{ fontSize: 20, marginRight: 15 }} />{" "}
                      Extend Selected By {extendDays} days
                    </Button>
                  )}
                  {!bookings.bulkExtendFinished && (
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={handleOpen}
                      style={{ paddingTop: 9 }}
                      disabled
                    >
                      <CircularProgress
                        size={22}
                        style={{ margin: "0 15px 0 10px", color: "#fff" }}
                      />{" "}
                      Extending by {extendDays} days...
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden"
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  header: {
    backgroundColor: "#fff2bf",
    fontSize: "bold"
  },
  dataGrid: {
    backgroundColor: "white",
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outlineColor: "transparent"
    }
  },
  modalTextField: {
    width: 80,
    marginTop: 0,
    marginLeft: 10,
    padding: 0
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "& .MuiInputBase-input": {
      textAlign: "center"
    }
  },
  checkBox: {
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "#fff2bf"
    },
    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
      color: "#ffcc00"
    }

  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0"
    },
    "& .MuiInputBase-root": {
      marginTop: "12px"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE"
    }
  }
}))

export default BulkExtend
