import { all } from "redux-saga/effects"
import { watchLogin } from "./authSaga"
import { watchLocationsRequest } from "./locationSaga"
import { watchBooking } from "./bookingsSaga"
import { watchUserInfo } from "./userInfoSaga"
import { watchAnalytics } from "./analyticsSaga"
import { watchServiceManagersRequest } from "./serviceManagerSaga"
import { watchLocationGroupssRequest } from "./locationGroupSaga"
import { watchVehicleGroupsRequest } from "./vehicleGroupSaga"
import { watchUserGroupsRequest } from "./userGroupSaga"
import { watchUsersRequest } from "./userSaga"
import { watchChangePasswordRequest } from "./changePasswordSaga"
import { watchForgotPasswordRequest } from "./forgotPasswordSaga"
import { watchResetPasswordRequest } from "./resetPasswordSaga"

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchLocationsRequest(),
    watchUserInfo(),
    watchBooking(),
    watchAnalytics(),
    watchServiceManagersRequest(),
    watchLocationGroupssRequest(),
    watchVehicleGroupsRequest(),
    watchUserGroupsRequest(),
    watchUsersRequest(),
    watchChangePasswordRequest(),
    watchForgotPasswordRequest(),
    watchResetPasswordRequest()
  ])
}
