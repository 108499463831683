/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import { useSelector, useDispatch } from "react-redux"
import * as userInfoActions from "./../../actions/userInfoActions"
import _ from "lodash"

const AddVehicle = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const availableVehicleGroups = useSelector(
    (state) => state.userInfo.settings.meta.find(e => e.key === "availableAggregateVehicleGroups").value
  )
  const allowedVehicleGroups = useSelector(
    (state) => state.userInfo.settings.meta.find(e => e.key === "availableAggregateVehicleGroups").value
  )

  function dispatchGroups(available, allowed) {
    dispatch(userInfoActions.setAvailableAggregateVehicleGroups({ token: auth.token }, _.uniq(available)))
    //dispatch(userInfoActions.setAllowedAggregateVehicleGroups({ token: auth.token }, _.uniq(allowed)))
  }

  return (
    <div>
      <MaterialTable
        columns={[
          { title: "Vehicle Code", field: "code" },
          { title: "Vehicle Title", field: "name" },
        ]}
        data={availableVehicleGroups ? availableVehicleGroups.map((x) => Object.assign({}, x)) : []}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                {
                  let tempAvailable = availableVehicleGroups ? _.cloneDeep(availableVehicleGroups) : []
                  let tempAllowed = allowedVehicleGroups ? _.cloneDeep(allowedVehicleGroups) : []

                  tempAvailable.push({ code: newData.code, name: newData.name })
                  tempAllowed.push(String(newData.code))

                  dispatchGroups(tempAvailable, tempAllowed)
                }
                resolve()
              }, 1000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                {
                  let tempAvailable = availableVehicleGroups ? _.cloneDeep(availableVehicleGroups) : []
                  let tempAllowed = allowedVehicleGroups ? _.cloneDeep(allowedVehicleGroups) : []
                  let availableIndex = _.findIndex(tempAvailable, {
                    code: oldData.code,
                    name: oldData.name,
                  })
                  let allowedIndex = _.findIndex(tempAllowed, newData.code)

                  tempAvailable[availableIndex] = { code: newData.code, name: newData.name }
                  tempAllowed[allowedIndex] = { code: newData.code, name: newData.name }

                  dispatchGroups(tempAvailable, tempAllowed)
                }
                resolve()
              }, 1000)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                {
                  let tempAvailable = availableVehicleGroups ? _.cloneDeep(availableVehicleGroups) : []
                  tempAvailable = _.reject(tempAvailable, { code: oldData.code, name: oldData.name })
                  let tempAllowed = allowedVehicleGroups ? _.cloneDeep(allowedVehicleGroups) : []
                  tempAllowed = _.without(tempAllowed, oldData.name)

                  dispatchGroups(tempAvailable, tempAllowed)
                }
                resolve()
              }, 1000)
            }),
        }}
        icons={tableIcons}
        title="Vehicles"
        options={{
          pageSize: 10,
          minBodyHeight: "calc(100vh - 320px)",
          maxBodyHeight: "calc(100vh - 320px)",
        }}
      />
    </div>
  )
}

export default AddVehicle
