import * as types from "../constants/actionTypes"

export function validateResetPasswordToken(token, serviceName) {
    return {
        type: types.RESET_PASSWORD.GET.REQUEST,
        payload: {
            token,
            serviceName,
        },
    }
}

export function resetPassword(token, password, cpassword) {
    return {
        type: types.RESET_PASSWORD.SET.REQUEST,
        payload: {
            token,
            password,
            cpassword
        },
    }
}

export function clearResetPassword() {
    return {
        type: types.RESET_PASSWORD.CLEAR.REQUEST
    }
}