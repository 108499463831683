/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect, Fragment } from "react"
import Moment from "react-moment"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import { Backdrop, Grid, Button, makeStyles, TextField, Modal, Fade, Typography, Tooltip, IconButton, Checkbox } from "@material-ui/core"
import FormControlLabel from '@mui/material/FormControlLabel';
import EditIcon from "@material-ui/icons/Edit"
import EventIcon from "@material-ui/icons/Event"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import _ from "lodash"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import RefreshIcon from "@material-ui/icons/Refresh"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import { capitaliseWord } from "../../utils/functions"
import Divider from "@material-ui/core/Divider"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import BookingModal from "./BookingModal"

import { useSelector, useDispatch } from "react-redux"
import * as bookingsActions from "../../actions/bookingsActions"
import * as appActions from "../../actions/appActions"
import { HERTZ_YELLOW } from "../../constants/style"

import ReservationSummary from "./ReservationSummary"
import { push } from "connected-react-router"
import { useLocation } from "react-router-dom";
import queryString from 'query-string'

import {
  DataGrid,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden"
}
const base = location.pathname.split("/")[1];
const OverdueCheckbox = (props) => {  
  return <FormControlLabel 
    control={<Checkbox onChange={props.handleChange} checked={props.checked}/>} 
    label={<Typography color="primary" variant="button" style={{color: "#1976d2"}}>Overdue</Typography>} />
}


const Rentals = () => {
  const classes = useStyles()
  const bookings = useSelector(state => state.bookings)
  const dispatch = useDispatch()
  const auth = useSelector(state => ({ ...state.auth }))
  const user = useSelector(state => state.userInfo.settings.user)
  const rentals = useSelector(state => state.bookings.reservations)
  const { search } = useLocation()
  const query = queryString.parse(search)
  const overdue = Boolean(query.overdue)
  const [open, setOpen] = React.useState(false)
  const [openExtend, setOpenExtend] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [disabledRow, setDisabledRow] = React.useState(false)
  const [extendDays, setExtendDays] = React.useState(0)
  const [dropOffDate, setDropOffDate] = React.useState(null)

  const apiRef = useGridApiRef();

  useEffect(() => {
    dispatch(bookingsActions.getRentals({ token: auth.token, overdue }))
    dispatch(bookingsActions.clearEstimate())
  }, [])

  function disableRow(data) {
    if (_.intersection(user.allowedItCodes, [data.PackageCode]).length > 0) {
      return false
    } else if (user.userType != "SERVICE_USER") {
      return false
    }

    return true
  }

  function onReservationClick(selectedRow) {
    setDisabledRow(false)
    let rentalNumber = selectedRow.RentalNumber
    if (selectedRow.status == "cancelled") {
      dispatch(bookingsActions.clearReservation({ token: auth.token }))
    } else if (disableRow(selectedRow)) {
      dispatch(bookingsActions.clearReservation({ token: auth.token }))
      setDisabledRow(true)
    } else {
      dispatch(bookingsActions.clearReservation({ token: auth.token }))
      dispatch(bookingsActions.getRental({ token: auth.token }, rentalNumber))
    }
  }
  const handleCheckboxChange = (event) => {
    const urlPath = event.target.checked ? {
      pathname: `/${base}/bookings/manage/rentals`,
      search: '?overdue=true'
    } : { pathname: `/${base}/bookings/manage/rentals` }
    dispatch(push(urlPath))
  }

  useEffect(() => {
    if (bookings.reservation) {
      const row = rowsData.filter((r) => r.RentalNumber == bookings.reservation.RentalNumber)
      setSelectedRow(row[0])
    }
  }, [bookings.reservation])

  useEffect(() => {
    dispatch(bookingsActions.getRentals({ token: auth.token, overdue }))
    dispatch(bookingsActions.clearEstimate())
  }, [overdue])

  const handleOpenExtend = () => {
    setOpen(true)
    setOpenExtend(true)
  }

  const handleKeyDown = (e) => {
    const inputBox = document.getElementById("search_ra_input");
    const ra = inputBox.value;
    // console.log(e)
    // console.log(inputBox.value)
    if (e.key == "Enter" && ra.length >= 8) {
      if (isNaN(+ra)) {
        dispatch(appActions.showMessage("Warning", "RA# MUST CONTAIN NUMBERS ONLY", "warning"));
        return;
      }
      dispatch(bookingsActions.getRental({ token: auth.token }, ra))
      setSelectedRow(null)
    }else if(ra.length >= 8 && !isNaN(+ra)){
      dispatch(bookingsActions.getRental({ token: auth.token }, ra))
      setSelectedRow(null)
    }
  };

  const handleChange = (RA) => {
    if (isNaN(+RA)) return; //* this check if RA is Number first
    if (RA.length < 8) return; //* It won't do nothing if RA lenght less than 8
    dispatch(bookingsActions.getRental({ token: auth.token }, RA));
    setSelectedRow(null)
  };

  function getVehicleModelImage(bookings) {
    let vehicle = bookings.vehicles.filter(vehicles => {
      return vehicles.code == bookings.rental.RateClass
    })

    return "https://images.hertz.com/vehicles/220x128/" + vehicle[0].image
  }

  const rowsData = rentals ?
    rentals.map((x, index) =>
      Object.assign({
        ...x,
        id: index,
        PickUpDate: moment(x.PickUp.DateTime, "YYYY-MM-DDTHH:mm").format("YYYY/MM/DD"),
        PickUpTime: moment(x.PickUp.DateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        PickUpLoc: x.PickUp.Location,
        DropOffDate: moment(x.DropOff.DateTime, "YYYY-MM-DDTHH:mm").format("YYYY/MM/DD"),
        DropOffTime: moment(x.DropOff.DateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        DropOffLoc: x.DropOff.Location,
      }, x))
    : []


  const CustomToolbar = () => {
    return (
      <React.Fragment>
        <GridToolbarContainer>
          <h3>Rentals</h3>
          <div style={{ display: "flex", flexGrow: 1 }}></div>
          <div style={{ verticalAlign: "center" }}>
            <Tooltip title="Refresh Data">
              <span>
                <IconButton
                  disabled={bookings.isFetching}
                  onClick={() => dispatch(bookingsActions.getRentals({ token: auth.token }))
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
            <OverdueCheckbox handleChange={handleCheckboxChange} auth={auth} checked={overdue} />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <GridToolbarQuickFilter
              id="search_ra_input"
              style={{ width: "150px" }}
              quickFilterParser={(searchInput) => {
                const inputs = searchInput
                  .split(",")
                  .map((value) => value?.trim().slice(0, 8))
                  .filter((value) => value?.slice(0, 8) !== "");
                handleChange(inputs[0]);
                return inputs;
              }}
              debounceMs={500}
              quickFilterFormatter={(quickFilterValues) => {
                return quickFilterValues.join(", ");
              }}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
        </GridToolbarContainer>
      </React.Fragment>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2} className={classes.root}>
        {/* Modal */}
        {
          selectedRow ? <BookingModal selectedRow={selectedRow} open={open} setOpen={setOpen} openExtend={openExtend} setOpenExtend={setOpenExtend}></BookingModal> : null
        }
        <Grid item xs={5} lg={7} style={{
          height: "calc(100vh - 90px)"
        }}>
          <DataGrid
            loading={bookings.isFetching}
            ref={useGridApiRef()}
            className={classes.dataGrid + ' ' + classes.paper + ' ' + classes.checkBox}
            slots={{ toolbar: CustomToolbar }}
            columns={[
              {
                headerName: "Claim No.",
                field: "ClaimNumber",
                minWidth: 120,
                headerClassName: classes.header
              },
              {
                headerName: "Rental No.",
                field: "RentalNumber",
                headerClassName: classes.header
              },
              {
                headerName: "Status",
                field: "RentalStatus",
                headerClassName: classes.header,
                maxWidth: 60,
                render: (r) => <Fragment>{capitaliseWord(r.RentalStatus)}</Fragment>,
              },
              {
                headerName: "Pick Up Date",
                field: "PickUpDate",
                headerClassName: classes.header,
              },
              {
                headerName: "Pick Up Time",
                field: "PickUpTime",
                headerClassName: classes.header,
                hideable: true,
              },
              {
                headerName: "Pick Up Loc",
                field: "PickUpLoc",
                type: "string",
                headerClassName: classes.header,
              },
              {
                headerName: "Drop Off Date",
                field: "DropOffDate",
                headerClassName: classes.header,
              },
              {
                title: "Drop Off Time",
                field: "DropOffTime",
                headerClassName: classes.header,
                hideable: true,
              },
              {
                headerName: "Drop Off Loc",
                field: "DropOffLoc",
                headerClassName: classes.header,
                minWidth: 100,
                flex: 1
              },

              // {
              //   title: "Last Modified Time",
              //   field: "LastModifiedTime",
              //   headerClassName: classes.header,
              //   hideable: true,
              // },
              // {
              //   title: "ReferenceId",
              //   field: "ReferenceId",
              //   hideable: true,
              // },

            ]}
            rows={rowsData}
            icons={tableIcons}
            title="Rentals 2"
            onRowClick={(selectedRow, event) => {
              setSelectedRow(selectedRow.row)
              onReservationClick(selectedRow.row)
            }}
            pageSizeOptions={[10, 15, 20, 50, 100]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'PickUpDate', sort: 'desc' }],
              },
              pagination: {
                paginationModel: { pageSize: 10 }
              },
            }}

          // components={{
          //   Toolbar: CustomToolbar
          // }}
          />
        </Grid>
        <ReservationSummary selectedRow={selectedRow} disabledRow={disabledRow} handleOpenExtend={handleOpenExtend} bookingType={"rental"} />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px"
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalTextField: {
    width: 80,
    marginTop: 0,
    marginLeft: 10,
    padding: 0
  },
  header: {
    backgroundColor: "#fff2bf",
    fontSize: "bold"
  },
  dataGrid: {
    backgroundColor: "white",
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "& .MuiInputBase-input": {
      textAlign: "center"
    },
    maxWidth: 300
  },
  checkBox: {
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "#fff2bf"
    },
    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
      color: "#ffcc00"
    }  
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3)
  },
  noFocus: {
    "&:focus": {
      outlineColor: "transparent"
    }
  }
}))

export default Rentals
