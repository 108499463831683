import { put, call } from "redux-saga/effects"
import _ from "lodash"

export function makeRequest(request, returnType) {
  return request()
    .then(response =>
      response[returnType]().then(result => ({ result, response }))
    )
    .then(({ result, response }) => {
      if (!response.ok) {
        throw result
      }
      return result
    })
}

export default function* callApi(request, statusTypes, returnType = "json") {
  const [SUCCESS, FAILURE] = statusTypes
  try {
    const result = yield call(makeRequest, request, returnType)
    if (!_.has(result, "errors")) {
      yield put({ type: SUCCESS, result })
    } else {
      yield put({ type: FAILURE, result })
    }
    return result
  } catch (error) {
    yield put({ type: FAILURE, error: { message: error.message } })
    throw error
  }
}

export function* callApiWithSuccessNotification(request, statusTypes, returnType = "json") {
  const [SUCCESS, FAILURE] = statusTypes
  try {
    const result = yield call(makeRequest, request, returnType)
    if (!_.has(result, "errors")) {
      yield put({ type: SUCCESS, result })
    } else {
      yield put({ type: FAILURE, result })
    }
    return result
  } catch (error) {
    yield put({ type: FAILURE, error: { message: error.message } })
    throw error
  }
}

export function* deleteCallApi(request, statusTypes, returnType = "json", options) {
  const [SUCCESS, FAILURE] = statusTypes
  console.log({options});
  try {
    const result = yield call(makeRequest, request, returnType)
    if (!_.has(result, "errors")) {
      yield put({ type: SUCCESS, result: {...result, _id: options.params } })
    } else {
      yield put({ type: FAILURE, result: {...result, _id: options.params } })
    }
    return result
  } catch (error) {
    yield put({ type: FAILURE, error: { message: error.message } })
    throw error
  }
}
