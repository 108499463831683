/* eslint-disable react/prop-types */
import React from "react"
import TopBar from "../partials/TopBar"
import ManageBookingTabs from "../partials/ManageBookingTabs"

class ManageBooking extends React.Component {
  constructor() {
    super()
    this.state = {
      isFlipped: false,
      expanded: "panel1",
      showLocationPopUp: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleLocationPopup = this.handleLocationPopup.bind(this)
  }

  handleChange(panel) {
    return (event, isExpanded) => {
      event.preventDefault()
      this.setState(() => ({ expanded: isExpanded ? panel : false }))
    }
  }

  handleClick(event) {
    event.preventDefault()
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
  }

  handleLocationPopup(event) {
    event.preventDefault()
    this.setState(prevState => ({
      showLocationPopUp: !prevState.showLocationPopUp
    }))
  }

  render() {
    return (
      <React.Fragment>
        <TopBar />
        <ManageBookingTabs location={this.props.location} />
      </React.Fragment>
    )
  }
}

export default ManageBooking
