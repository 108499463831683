/* eslint-disable no-case-declarations */
import * as types from "../constants/actionTypes"
import initialState from "./initialState"
import { converter } from "../utils/functions"

export default function userInfoReducer(state = initialState.userInfo, action) {
  switch (action.type) {
    case types.USER_SETTINGS.GET.SUCCESS:
      return {
        ...state,
        settings: action.result.data.OBT,
        serviceUsers: action.result.data.GetServiceUsers,
        serviceManagers: action.result.data.GetServiceManagers,
        isFetching: false,
      }
    case types.GET_SERVICE_SETTINGS.GET.REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_SERVICE_SETTINGS.GET.SUCCESS:

      const convertedData = converter(action.result.data.GetServiceSettings);

      convertedData.user = action.result.data.GetCurrentUser;
      convertedData.itCodeGroups = action.result.data.GetITCodeGroups;
      return {
        ...state,
        settings: convertedData,
        // settings: JSON.parse(action.result.data.GetServiceSettings),
        isFetching: false,
        success:true
      }
    case types.GET_SERVICE_SETTINGS.GET.FAILURE:
      return {
        ...state,
        isFetching: false,
        success:false
      }
    case types.USER_SETTINGS.CREATE.REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.USER_SETTINGS.CREATE.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          serviceRules: {
            ...state.settings.serviceRules,
            serviceUsers: action.result.data.OBT.serviceRules.serviceUsers,
            serviceAdmins: action.result.data.OBT.serviceRules.serviceAdmins,
          },
        },
        isFetching: false,
      }
    case types.USER_SETTINGS.CREATE.FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case types.USER_SETTINGS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...converter(action.result.data.UpdateServiceSettings),
        },
        isFetching: false,
      }
    case types.USER.CREATE.REQUEST:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isFetching: false,
        },
      }
    case types.USER.CREATE.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.result.data.OBT,
        },
        isFetching: false,
      }
    case types.USERS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          user: state.settings.user._id === action.result.data.UpdateUser._id ? action.result.data.UpdateUser : state.settings.user
        },
        isFetching: false,
      }
    case types.LOGOUT_REQUEST:
      return {
        ...initialState.userInfo,
      }
    case types.FAVOURITE_LOCATIONS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            favouriteLocations: action.result.data.OBT.user.favouriteLocations,
          },
        },
      }
    case types.IT_GROUP.CREATE.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          itCodeGroups: action.result.data.GetITCodeGroups,
        },
      }
    case types.IT_GROUP.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          itCodeGroups: action.result.data.GetITCodeGroups,
        },
      }
    case types.IT_GROUP.DISABLE.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          itCodeGroups: action.result.data.GetITCodeGroups,
        },
      }
    case types.IT_GROUP.DELETE.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          itCodeGroups: action.result.data.GetITCodeGroups,
        },
      }
    case types.IT_CODES.SET.REQUEST:
        return {
          ...state,
          isFetching: true
        }
    case types.IT_CODES.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...converter(action.result.data.UpdateServiceSettings)
        },
        isFetching: false
      }
    case types.IT_CODES.SET.FAILURE:
      return {
        ...state,
        isFetching: false
      }
    case types.IT_CODES.GET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          businessRules: {
            ...state.settings.businessRules,
            itCodes: action.result.data.OBT.businessRules.itCodes,
          },
          serviceRules: {
            ...state.settings.serviceRules,
            itCodes: action.result.data.OBT.serviceRules.itCodes,
          },
        },
        isFetching: false,
      }
    case types.SERVICE_USER.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,

        },
        serviceUsers: action.result.data.GetServiceUsers,
        serviceManagers: action.result.data.GetServiceManagers,
        isFetching: false,
      }
    case types.BUSINESS_RULES.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          businessRules: {
            ...state.settings.businessRules,
            ...action.result.data.OBT.businessRules,
          },
        },
        isFetching: false,
      }
    case types.SERVICE_SETTINGS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          businessRules: {
            ...state.settings.businessRules,
            ...action.result.data.OBT.businessRules,
          },
        },
        isFetching: false,
      }
    case types.ALLOW_ONE_WAY.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...converter(action.result.data.UpdateServiceSettings)
        },
      }
    case types.ALLOW_PICKUP_AND_DROPOFF.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...converter(action.result.data.UpdateServiceSettings)
        },
      }
    case types.USER_IT_CODE_GROUPS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          serviceRules: {
            ...action.result.data.OBT.serviceRules,
          },
        },
        isFetching: false,
      }
    case types.USER_LOCATIONS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          serviceRules: {
            ...action.result.data.OBT.serviceRules,
          },
        },
        isFetching: false,
      }
    case types.USER_VEHICLES.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          serviceRules: {
            ...action.result.data.OBT.serviceRules,
          },
        },
        isFetching: false,
      }
    case types.USER_PASSWORD.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            passwordResetRequired: action.result.data.OBT.user.passwordResetRequired,
          },
        },
      }
    case types.NOTIFICATION.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            rentalErrorMessageHistory: action.result.data.OBT.user.rentalErrorMessageHistory,
          },
        },
      }
    case types.ALLOWED_AGGREGATE_VEHICLE_GROUPS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          serviceRules: {
            ...state.settings.serviceRules,
            allowedAggregateVehicleGroups: action.result.data.OBT.serviceRules.allowedAggregateVehicleGroups,
          },
        },
      }
    case types.AVAILABLE_AGGREGATE_VEHICLE_GROUPS.SET.SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...converter(action.result.data.UpdateServiceSettings)
        },
      }
    case types.CONFIRMATION_SPIEL_TEMPLATE.SET.REQUEST:
        return {
          ...state,
          isFetching: true,
        }
    case types.CONFIRMATION_SPIEL_TEMPLATE.SET.SUCCESS:
        return {
          ...state,
          isFetching: false,
          settings: {
            ...state.settings,
            ...converter(action.result.data.UpdateServiceSettings)
          },
        }
    case types.CONFIRMATION_SPIEL_TEMPLATE.SET.FAILURE:
        return {
          ...state,
          isFetching: false
        }
    case types.RESET_REQUIRED.SET.SUCCESS:
      return {
        ...state,
        message: {
          title: "SUCCESS",
          text: `An email has been sent to the user with instructions to reset their password`,
          success: true,
          show: true,
        },
      }
    case types.RESET_REQUIRED.SET.ERROR:
      return {
        ...state,
        message: {
          title: "ERROR",
          text: action.result.errors[0].exceptions[0],
          success: true,
          show: true,
        },
      }
    case "saveEmailTemplates_SUCCESS":
      const emailData = converter(action.result.data.UpdateServiceSettings);
      return {
        ...state,
        settings: {
          ...state.settings,
          ...emailData
        },
      }
    default:
      return state
  }
}
