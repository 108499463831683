import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.LOGOUT_REQUEST:
      return {
        ...initialState.auth,
      }
    case types.LOGIN_REQUEST:
      return {
        ...state,
        token: "",
        error: "",
        isFetching: true,
      }
    case types.SSO_LOGIN:
      return {
        ...state,
        token: action.token,
        expiry: action.expiry,
      }
    case types.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        token: action.result.auth ? action.result.token : "",
        error: action.result.auth ? "" : action.result.error,
        isFetching: false,
      }
    case types.LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
