/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { useSnackbar, SnackbarProvider } from "notistack"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import _ from "lodash"

import * as userInfoActions from "../../actions/userInfoActions"
import * as appActions from "../../actions/appActions"

const notistackRef = React.createRef()
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key)
}

const cleanedString = (value) => {
  let cleanedString = value.replace(/^:[0-9]*[|$%@"<>()+?]*/g, "")

  return cleanedString
}

const toast = (message, snackbar, variant = "success") => {
  snackbar(message, {
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    variant: variant,
  })
}

export const NotificationProvider = (props) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      maxSnack={5}
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          {" "}
          <ClearIcon style={{ color: "white" }} />{" "}
        </Button>
      )}
    >
      {props.children}
    </SnackbarProvider>
  )
}

const NotificationCenter = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const bookings = useSelector((state) => ({ ...state.bookings }))
  const rentalErrorMessageHistory = useSelector((state) =>
    _.isArray(state, "userInfo.settings.user.rentalErrorMessageHistory")
      ? state.userInfo.settings.user.rentalErrorMessageHistory
      : []
  )
  const [pageLoaded, setPageLoaded] = useState(false)
  const auth = useSelector((state) => state.auth)
  const message = useSelector((state) => state.userInfo.message)
  const appMessages = useSelector((state) => state.app.messages)

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  useEffect(() => {
    if (message && message.success && message.show) {
      toast(message.title + ": " + cleanedString(message.text), enqueueSnackbar, "success")
      // dispatch(userInfoActions.clearMessage({token: auth.token}))
    } else if (message && !message.success && message.show) {
      toast(message.title + ": " + cleanedString(message.text), enqueueSnackbar, "error")
      // dispatch(userInfoActions.clearMessage({token: auth.token}))
    }
  }, [message])

  useEffect(() => {
    const message = appMessages ? appMessages[appMessages.length - 1] : ""
    if (message && message.success && message.payload && message.payload.title && message.payload.text) {
      toast(message.payload.title + ": " + cleanedString(message.payload.text), enqueueSnackbar, "success")
      dispatch(appActions.clearMessages({ token: auth.token }))
    } else if (message && message.payload && message.payload.title && message.payload.text) {
      toast(message.payload.title + ": " + cleanedString(message.payload.text), enqueueSnackbar, "error")
      dispatch(appActions.clearMessages({ token: auth.token }))
    } else if (message && message.title && message.text) {
      toast(message.title + ": " + cleanedString(message.text), enqueueSnackbar, "error")
      dispatch(appActions.clearMessages({ token: auth.token }))
    }
  }, [appMessages])

  useEffect(() => {
    const msg = bookings.failedExtensions ? bookings.failedExtensions[bookings.failedExtensions.length - 1] : []
    let notifications = _.cloneDeep(rentalErrorMessageHistory)

    if (_.has(msg, "error") && pageLoaded) {
      toast(msg.rentalNumber + ": " + cleanedString(msg.error), enqueueSnackbar, "error")
      notifications.push({
        referenceId: msg.referenceId,
        error: "RentalNumber: " + msg.rentalNumber + "<br/>Error: " + cleanedString(msg.error),
      })
      dispatch(userInfoActions.updateNotifications({ token: auth.token }, notifications))
    }
  }, [bookings.failedExtensions])

  useEffect(() => {
    const msg = bookings.failedCancellations
      ? bookings.failedCancellations[bookings.failedCancellations.length - 1]
      : []
    let notifications = _.cloneDeep(rentalErrorMessageHistory)

    if (_.has(msg, "error") && pageLoaded) {
      toast(msg.rentalNumber + ": " + cleanedString(msg.error), enqueueSnackbar, "error")
      notifications.push({ referenceId: msg.referenceId, error: msg.error })
      dispatch(userInfoActions.updateNotifications({ token: auth.token }, notifications))
    }
  }, [bookings.failedCancellations])

  useEffect(() => {
    const msg = bookings.successfulExtensions
      ? bookings.successfulExtensions[bookings.successfulExtensions.length - 1]
      : []
    if (_.has(msg, "rentalNumber") && pageLoaded) {
      toast(msg.rentalNumber + ": SUCCESS!", enqueueSnackbar)
    }
  }, [bookings.successfulExtensions])

  useEffect(() => {
    if (bookings.errors != "") {
      toast(bookings.errors, enqueueSnackbar, "error")
      dispatch({ type: "CLEAR_ERRORS" })
    }
  }, [bookings.errors])

  useEffect(() => {
    if (auth.error != "") {
      toast(auth.error, enqueueSnackbar, "error")
      dispatch({ type: "CLEAR_ERRORS" })
    }
  }, [auth.error])

  return false
}

export default NotificationCenter
