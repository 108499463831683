import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function changePasswordReducer(state = initialState.changePassword, action) {
    switch (action.type) {
        case types.CHANGE_PASSWORD.SET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.CHANGE_PASSWORD.SET.SUCCESS:
            return {
                ...state,
                credentials: {
                    oldPassword: "",
                    newPassword: "",
                    cPassword: "",
                },
                isFetching: false,
                success: true
            }
        case types.CHANGE_PASSWORD.SET.FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false
            }
        default:
            return state
    }
}
