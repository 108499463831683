export const acrissCategories = [
  {
    Code: "M",
    Desc: "Mini"
  },
  {
    Code: "N",
    Desc: "Mini Elite"
  },
  {
    Code: "E",
    Desc: "Economy"
  },
  {
    Code: "H",
    Desc: "Economy Elite"
  },
  {
    Code: "C",
    Desc: "Compact"
  },
  {
    Code: "D",
    Desc: "Compact Elite"
  },
  {
    Code: "I",
    Desc: "Intermediate"
  },
  {
    Code: "J",
    Desc: "Intermediate Elite"
  },
  {
    Code: "S",
    Desc: "Standard"
  },
  {
    Code: "R",
    Desc: "Standard Elite"
  },
  {
    Code: "F",
    Desc: "Fullsize"
  },
  {
    Code: "G",
    Desc: "Fullsize Elite"
  },
  {
    Code: "P",
    Desc: "Premium"
  },
  {
    Code: "U",
    Desc: "Premium Elite"
  },
  {
    Code: "L",
    Desc: "Luxury"
  },
  {
    Code: "W",
    Desc: "Luxury Elite"
  },
  {
    Code: "O",
    Desc: "Oversize"
  },
  {
    Code: "X",
    Desc: "Special"
  }
];
export const acrissTypes = [
  {
    Code: "B",
    Desc: "2-3 Door"
  },
  {
    Code: "C",
    Desc: "2 Or 4 Door"
  },
  {
    Code: "D",
    Desc: "4-5 Door"
  },
  {
    Code: "W",
    Desc: "Wagon/Estate"
  },
  {
    Code: "V",
    Desc: "Passenger Van"
  },
  {
    Code: "L",
    Desc: "Limousine/Sedan"
  },
  {
    Code: "S",
    Desc: "Sport"
  },
  {
    Code: "T",
    Desc: "Convertible"
  },
  {
    Code: "F",
    Desc: "SUV"
  },
  {
    Code: "J",
    Desc: "Open Air All Terrain"
  },
  {
    Code: "X",
    Desc: "Special"
  },
  {
    Code: "P",
    Desc: "Pick up Regular Car"
  },
  {
    Code: "Q",
    Desc: "Pick up Extended Car"
  },
  {
    Code: "Z",
    Desc: "Special Offer Car"
  },
  {
    Code: "E",
    Desc: "Coupe"
  },
  {
    Code: "M",
    Desc: "Monospace"
  },
  {
    Code: "R",
    Desc: "Recreational Vehicle"
  },
  {
    Code: "H",
    Desc: "Motor Home"
  },
  {
    Code: "Y",
    Desc: "2 Wheel Vehicle"
  },
  {
    Code: "N",
    Desc: "Roadster"
  },
  {
    Code: "G",
    Desc: "Crossover"
  },
  {
    Code: "K",
    Desc: "Commercial Van/Truck"
  }
];

export const acrissTransmision = [
  {
    Code: "M",
    Desc: "Manual Unspecified Drive"
  },
  {
    Code: "N",
    Desc: "Manual 4WD"
  },
  {
    Code: "C",
    Desc: "Manual AWD"
  },
  {
    Code: "A",
    Desc: "Auto Unspecified Drive"
  },
  {
    Code: "B",
    Desc: "Auto 4WD"
  },
  {
    Code: "D",
    Desc: "Auto 4WD"
  }
];

export const acrissFuelAndAir = [
  {
    Code: "R",
    Desc: "Unspecified Fuel/Power"
  },
  {
    Code: "N",
    Desc: "Unspecified Fuel/Power Without Air Conditioning"
  },
  {
    Code: "D",
    Desc: "Diesel"
  },
  {
    Code: "Q",
    Desc: "Diesel Without Air Conditioning"
  },
  {
    Code: "H",
    Desc: "Hybrid"
  },
  {
    Code: "I",
    Desc: "Hybrid Without Air Conditioning"
  },
  {
    Code: "E",
    Desc: "Electric"
  },
  {
    Code: "C",
    Desc: "Electric Without Air Conditioning"
  },
  {
    Code: "L",
    Desc: "LPG/Compressed Gas"
  },
  {
    Code: "S",
    Desc: "LPG/Compressed Gas Without Air Conditioning"
  },
  {
    Code: "A",
    Desc: "Hydrogen"
  },
  {
    Code: "B",
    Desc: "Hydrogen Without Air Conditioning"
  },
  {
    Code: "M",
    Desc: "Multi Fuel/Power"
  },
  {
    Code: "F",
    Desc: "Multi Fuel/Power Without Air Conditioning"
  },
  {
    Code: "V",
    Desc: "Petrol"
  },
  {
    Code: "Z",
    Desc: "Petrol Without Air Conditioning"
  },
  {
    Code: "U",
    Desc: "Ethanol"
  },
  {
    Code: "X",
    Desc: "Ethanol Without Air Conditioning"
  }
];
