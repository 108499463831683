import * as types from "../constants/actionTypes"

export function requestPasswordReset(emailAddress, serviceName) {
    return {
        type: types.FORGOT_PASSWORD.SET.REQUEST,
        payload: {
            emailAddress,
            serviceName,
        },
    }
}

export function clearForgotPassword() {
    return {
        type: types.FORGOT_PASSWORD.CLEAR.REQUEST
    }
}