import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ssoLogin } from "../../actions/authActions"
import { Grid, CircularProgress } from "@material-ui/core"
import * as userInfoActions from "../../actions/userInfoActions"

const SsoAuth = () => {
  const dispatch = useDispatch()
  const base = location.pathname.split("/")[1].toUpperCase()
  const stateToken = useSelector((state) => state.auth.token)
  const user = useSelector((state) => state.userInfo.settings.user)
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    if (!loaded) {
      const token = JSON.parse(atob(location.search.replace("?auth_string=", "")))
      dispatch(ssoLogin(token.token, token.expiry))
      dispatch(userInfoActions.getUserSettings({ token: token.token }))
      setLoaded(true)
    }
  }, [])

  useEffect(() => {
    console.log(stateToken)
    if (stateToken && user) {
      window.location.replace(`${window.location.origin}/${base}`)
    }
  }, [stateToken, user])
  return (
    <Grid container direction="row" justify="center" alignItems="center" style={{ minHeight: 500 }}>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress size={50} thickness={4} />
        <h4 style={{ marginBottom: 5 }}>Logging you in...</h4>* If you&apos;re not redirected within 30 seconds please
        click&nbsp;
        <a href={`${window.location.origin}/${base}/dashboard`}>here</a>.
      </Grid>
    </Grid>
  )
}

export default SsoAuth
