import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import { makeStyles } from "@material-ui/core/styles"
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu, Tabs, Tab } from "@material-ui/core"
import _ from "lodash"

import AccountCircle from "@material-ui/icons/AccountCircle"
import MailIcon from "@material-ui/icons/Mail"
import NotificationsIcon from "@material-ui/icons/Notifications"
import MoreIcon from "@material-ui/icons/MoreVert"

import * as authActions from "../../actions/authActions"
import NotificationDrawer from "../partials/NotificationDrawer"
import ProfileDrawer from "../partials/ProfileDrawer"


const TopBar = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [notificationDrawer, setNotificationDrawer] = React.useState(false)
  const [openProfileDrawer, setOpenProfileDrawer] = React.useState(false)
  const notificationCount = useSelector((state) => state.userInfo.settings.user?.rentalErrorMessageHistory?.length)
  const errors = useSelector((state) => state.userInfo.settings.user?.rentalErrorMessageHistory)
  const userType = useSelector((state) => state.userInfo.settings.user?.userType)

  const currentPathname = useSelector((state) => state.router.location.pathname)
  const [currentTab, setCurrentTab] = React.useState(
    currentPathname.match(/(\/bookings\/manage|\/bookings\/create|\/controlpanel|\/dashboard|\/changePassword)/g)[0]
  )
  const dispatch = useDispatch()
  let path = window.location.pathname
  path = path.split("/")
  const brand = _.toLower(path[1])

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  function handleTabChange(event, newValue) {
    dispatch(push({ pathname: "/" + location.pathname.split("/")[1] + newValue }))
    setCurrentTab(newValue)
  }

  function handleProfileMenuOpen() {
    setOpenProfileDrawer(true)
    // setAnchorEl(event.currentTarget)
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  function handleMenuClose() {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  function handleNotification() {
    setNotificationDrawer(true)
  }

  function handleChangePassword() {
    dispatch(push({ pathname: "/" + location.pathname.split("/")[1] + "/changePassword" }))
    setCurrentTab("/changePassword")
  }

  const menuId = "primary-search-account-menu"
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
      <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  )

  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {" "}
      <MenuItem>
        <IconButton aria-label="show 4 new mails">
          <Badge badgeContent={4} style={{ color: "white" }}>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 5 new notifications">
          <Badge badgeContent={5} style={{ color: "white" }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
        >
          {" "}
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton aria-controls="primary-search-account-menu" aria-haspopup="true">
          {" "}
          <AccountCircle />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div style={{ marginLeft: "10px", marginTop: 2 }}>
            <img src={`${"/i18n/hertzlogo.png"}`} height="34px" />
          </div>
          <div className={classes.grow}>
            <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="secondary" centered>
              <Tab value="/dashboard" label="Dashboard" />              
              <Tab value="/bookings/create" label="Create A Booking" />
              <Tab value="/bookings/manage" label="Manage Bookings" />
              {userType != "SERVICE_USER" && <Tab value="/controlpanel" label="Control Panel" />}
            </Tabs>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show new notifications" onClick={handleNotification}>
              <Badge badgeContent={notificationCount} color="secondary">
                <NotificationsIcon style={{ color: "white" }} />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              {" "}
              <AccountCircle style={{ color: "white" }} />
            </IconButton>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <img src={`https://static.hertz.asiapac.io/mybookingportal/${brand}-logo.png`} height="25px" />
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              {" "}
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
        <NotificationDrawer
          open={notificationDrawer}
          setNotificationDrawer={setNotificationDrawer}
          errors={errors}
        ></NotificationDrawer>
        <ProfileDrawer
          open={openProfileDrawer}
          setOpenProfileDrawer={setOpenProfileDrawer}
          errors={errors}
        ></ProfileDrawer>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: 99, //theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    color: "#ffd100",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}))

export default TopBar
