/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Paper, Button } from "@material-ui/core";
import Grid from '@mui/material/Grid';



const Marker = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickedMarker, setClickedMarker] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!props.highlightedLocation) {
      setOpen(false)
      return false
    } else {
      setOpen(props.highlightedLocation.locationCode == props.location.locationCode)
      const anchorEle = document.getElementById(`marker-${props.location.locationCode}`)
      setAnchorEl(anchorEle);
    }
  }, [props.highlightedLocation])


  const handleClickMarker = (event) => {
    setClickedMarker(!clickedMarker);
    props.setHighlightedLocation(!clickedMarker ? props.location : null)
    setOpen(!clickedMarker);
    props.setViewingLocation(clickedMarker ? null : props.location)
  }

  useEffect(() => {
    if (!props.viewingLocation) {
      return
    }
    if (props.viewingLocation.locationCode == props.location.locationCode) {

      setClickedMarker(true)

    } else { setClickedMarker(false) }
  }, [props.viewingLocation])
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    props.setHighlightedLocation(props.location)

  };

  const handlePopoverClose = () => {
    props.setHighlightedLocation(null)
    setAnchorEl(null);
    // setOpen(false)
  };

  const selectLocation = (event) => {
    props.onSelectLocation(event)
    handleClickMarker(event)
  }
  const iconSize = props.$hover ? 46 : 46;

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        // if (true) {
        //   return;
        // }
        // e.preventDefault();
        // setClickedMarker(null);
        // setAnchorEl(null);
        // props.setHighlightedLocation(null);
        // props.setViewingLocation(null);
      }}
    >
      <div>
        <Popper
          id="mouse-over-popover"
          sx={{
            position: "absolute",
            top: props.lowerThanCenter ? 0 : 0,
            left: 0,
            cursor: 'default',
          }}
          open={open || clickedMarker}
          anchorEl={anchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={500}>
              <Paper>
                <Box textAlign="center" pt={1} px={2} >
                  <h3 style={{ margin: "0px", cursor: 'pointer' }}>
                    {props.location.locationName}
                    <br />
                  Code: {props.location.locationCode}
                  </h3>
                  {(clickedMarker) && <Icon
                    sx={{
                      fontSize: iconSize / 2,
                      cursor: 'pointer'
                    }}
                    onClick={handleClickMarker}
                  >close</Icon>}
                </Box>
                <hr style={{ padding: 0 }} />

                <Box textAlign="left" px={2}>
                  Address: {props.location.address1}
                  <br />
                  City: {props.location.city}
                  <br />
                  State: {props.location.state}
                  <br />
                  Country: {props.location.country}
                  <br />

                </Box>
                <Box py={1} px={2}>

                  {/* {Object.keys(props.location.hours).map((day) => <div >{day + ': '}{props.location.hours[day].closed ? 'closed' : props.location.hours[day]['open1'] + '-' + props.location.hours[day]['close1']}<br /></div>)} */}
                  {Object.keys(props.location.hours).map((day, index) =>
                    <Grid container spacing={2} columns={16} key={index}>
                      <Grid item xs={7}>
                        <div>{day + ': '}</div>
                      </Grid>
                      <Grid item xs={9}>
                        {props.location.hours[day].closed && <div>CLOSED</div>}
                        {props.location.hours[day]['open1'] && <div>{props.location.hours[day]['open1'] + '-' + props.location.hours[day]['close1']}</div>}
                        {props.location.hours[day]['open2'] && <div>{props.location.hours[day]['open2'] + '-' + props.location.hours[day]['close2']}</div>}
                        {props.location.hours[day]['open3'] && <div>{props.location.hours[day]['open3'] + '-' + props.location.hours[day]['close3']}</div>}

                      </Grid>
                    </Grid>
                  )}
                </Box>
                <Box textAlign="center" py={1} >
                  {(clickedMarker) && <Button
                    variant="contained"
                    color="secondary"
                    value={props.location.locationCode}
                    onClick={selectLocation}
                  >
                    Select
              </Button>}
                </Box>
              </Paper>
            </Fade>
          )}

        </Popper>

        <Icon
          sx={{
            fontSize: iconSize,
            position: "absolute",
            left: -iconSize / 2,
            top: -iconSize,
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={clickedMarker ? null : handlePopoverClose}
          onClick={(e) => handleClickMarker(e)}
          id={`marker-${props.location.locationCode}`}
        >
          place
    </Icon>
      </div>
    </ClickAwayListener>
  );

}

export default Marker
