import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function forgotPasswordReducer(state = initialState.forgotPassword, action) {
    switch (action.type) {
        case types.FORGOT_PASSWORD.SET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.FORGOT_PASSWORD.SET.SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: true
            }
        case types.FORGOT_PASSWORD.SET.FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false
            }
        case types.FORGOT_PASSWORD.CLEAR.REQUEST:
            return {
                ...state,
                isFetching: false,
                success: undefined
            }
        default:
            return state
    }
}
