/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React, { useState, useContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Redirect, useParams } from "react-router-dom"
import * as resetPasswordActions from "../../actions/resetPasswordActions"
import * as appActions from "../../actions/appActions"
import { i18n } from "../i18n"
import _ from "lodash"

import { Grid, Paper, TextField, Typography, Button } from "@material-ui/core"

const ResetPassword = () => {
  const dispatch = useDispatch()
  const base = location.pathname.split("/")[1].toUpperCase()
  const brand = base.toLowerCase()
  const { token } = useParams()
  const isFetching = useSelector((state) => state.resetPassword.isFetching);
  const [redirect, setRedirect] = useState(false)
  const success = useSelector((state) => state.resetPassword.success);
  const isValidResetToken = useSelector((state) => state.resetPassword.isValidResetToken);
  const [password, setPassword] = useState("")
  const [cPassword, setCPassword] = useState("")

  const labelContext = useContext(i18n)
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  useEffect(() => {
    if(isValidResetToken === undefined) {
      dispatch(resetPasswordActions.validateResetPasswordToken(token, base.toUpperCase()))
    } 
  }, [])

  function handleReset(e) {
    e.preventDefault()
    if (password !== cPassword) {
      dispatch(appActions.showMessage("Request Error", "The new password doesn't match", "danger"))
    } else if(password === "") {
      dispatch(appActions.showMessage("Request Error", "The password cannot be blank", "danger"))
    } else {
      dispatch(resetPasswordActions.resetPassword(token, password, cPassword))
    }
  }

  function backToLogin(e) {
    e.preventDefault();
    dispatch(resetPasswordActions.clearResetPassword())
    setRedirect(true)
  }

  if(redirect === true || isValidResetToken === false) {
    return <Redirect to={`/${base}/login`} />
  }

  if (!isFetching && success === true) {
    return (<Grid
      container
      style={{
        backgrounColor: "#ccc",
        height: "100vh",
        backgroundImage: 'url("https://static.hertz.asiapac.io/portal/img/LoYLaas.jpg")',
      }}
      justify="flex-end"
    >
      <Grid
        container
        item
        style={{ width: "650px", backgroundColor: "#000", opacity: "0.8" }}
        justify="center"
        alignItems="center"
      >
        <Paper style={{ width: "550px", height: "600px", padding: "30px 20px", background: "#e0dada" }}>
          <Grid container direction="row" justify="center" alignItems="flex-start" style={{ marginBottom: 50 }}>
            <img src={`${"/i18n/hertzlogo.png"}`} height="50px" />
            <img src={`https://static.hertz.asiapac.io/mybookingportal/${brand}-logo.png`} height="50px" />
          </Grid>
          <Typography variant="h4">Reset Password</Typography>
          <Typography variant="body1">The password has been reset</Typography>
          <Button
            type="submit"
            onClick={backToLogin}
            color="secondary"
            size="large"
            variant="contained"
          >
            Back To Login
          </Button>
        </Paper>
      </Grid>
    </Grid>)
  }

  return (
    
    <Grid
      container
      style={{
        backgrounColor: "#ccc",
        height: "100vh",
        backgroundImage: 'url("https://static.hertz.asiapac.io/portal/img/LoYLaas.jpg")',
      }}
      justify="flex-end"
    >
      { (
        <Grid
          container
          item
          style={{ width: "650px", backgroundColor: "#000", opacity: "0.7" }}
          justify="center"
          alignItems="center"
        >
          <Paper style={{ width: "550px", height: "600px", padding: "30px 20px" }}>
            <Typography variant="h4">Reset Password</Typography>
            <form>
              <Grid item xs={12}>
                <TextField
                  id="pasword"
                  label={getLabel("login.Password", "Password")}
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="passwordConfirm"
                  label={"Confirm Password"}
                  value={cPassword}
                  type="password"
                  onChange={(e) => setCPassword(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12} justify="flex-end">
                <Button
                  type="submit"
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={(e) => handleReset(e)}
                >
                  Submit{" "}
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default ResetPassword
