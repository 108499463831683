import { takeLatest, put, call } from "redux-saga/effects"
import callApi, { deleteCallApi } from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"
import _ from "lodash"

export function* getLocationGroups(action) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `query GetLocationGroups {
                GetLocationGroups {
                    _id
                    name
                    active
                    locations {
                        allowed {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                        denied {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                    }
                }
            }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.LOCATION_GROUPS.GET.SUCCESS, types.LOCATION_GROUPS.GET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* createLocationGroup(actions) {
    const allowedString = JSON.stringify(actions.payload.locationGroupObject.locations.allowed)
    const deniedString = JSON.stringify(["*"]);
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation CreateLocationGroups {
                CreateLocationGroups(
                    name: "${actions.payload.locationGroupObject.name}"
                    locations: { allowed: ${allowedString}, denied: ${deniedString} }
                    active: true
                ) {
                    _id
                    name
                    active
                    locations {
                        allowed {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                        denied {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                    }
                }
            }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.LOCATION_GROUPS.CREATE.SUCCESS, types.LOCATION_GROUPS.CREATE.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* setLocationGroup(actions) {
    const allowedString = JSON.stringify(actions.payload.locationGroupObject.locations.allowed);
    const deniedString = JSON.stringify(["*"]);
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation UpdateLocationGroups {
                UpdateLocationGroups(
                    _id: "${actions.payload.locationGroupObject._id}"
                    name: "${actions.payload.locationGroupObject.name}"
                    locations: { allowed: ${allowedString}, denied: ${deniedString} }
                    active: ${actions.payload.locationGroupObject.active}
                ) {
                    _id
                    name
                    active
                    locations {
                        allowed {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                        denied {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                    }
                }
            }   
            `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")
    let callResponse;
    try {
        callResponse = yield call(callApi, () => fetch(uri, config), [types.LOCATION_GROUPS.SET.SUCCESS, types.LOCATION_GROUPS.SET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }

    if (_.has(callResponse, "errors")) {
        yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
    } else {
        yield put(appActions.showSuccessMessage("Success", "Location group has been modified"))
    }
}

export function* deleteLocationGroup(actions) {
    
    
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation DeleteLocationGroups {
                        DeleteLocationGroups(GroupIDs: ["${actions.payload.locationGroupObject._id}"]) {
                            message
                        }
                    }   
                    `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(
            deleteCallApi,
            () => fetch(uri, config),
            [types.LOCATION_GROUPS.DELETE.SUCCESS, types.LOCATION_GROUPS.DELETE.FAILURE],
            "json",
            {params: actions.payload.locationGroupObject._id}
        )
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* watchLocationGroupssRequest() {
    yield takeLatest(types.LOCATION_GROUPS.GET.REQUEST, getLocationGroups)
    yield takeLatest(types.LOCATION_GROUPS.SET.REQUEST, setLocationGroup)
    yield takeLatest(types.LOCATION_GROUPS.CREATE.REQUEST, createLocationGroup)
    yield takeLatest(types.LOCATION_GROUPS.DELETE.REQUEST, deleteLocationGroup)
}
