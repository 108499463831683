import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import MaterialTable from "material-table"
import { Grid, Button, makeStyles, TextField, Typography } from "@material-ui/core"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import tableIcons from "../../utils/helper"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import * as userInfoActions from "../../actions/userInfoActions"
import ItGroupSummary from "./ItGroupSummary"

const MaterialTableDemo = () => {
  const _ = require("lodash")
  const classes = useStyles()
  const dispatch = useDispatch()
  // const settings = useSelector(state => state.userInfo.settings)
  const userType = useSelector(state => _.get(state, "userInfo.settings.user.userType", ""))
  const auth = useSelector(state => ({ ...state.auth }))
  const [selectedRow, setSelectedRow] = React.useState(null)
  const serviceRulesItGroups = useSelector(state => _.get(state, "userInfo.settings.itCodeGroups", []))
  const isFetching = useSelector((state) => _.get(state, "userInfo.isFetching", false))
  const itCodes = useSelector(state => _.get(state, "userInfo.settings.itcodes", []))
  const [itGroups, setItGroups] = React.useState([])
  const [pageLoad, setPageLoad] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [newGroupName, setNewGroupName] = React.useState("")
  const [hiddenNameText, setHiddenNameText] = React.useState(true)
  const [duplicateRow, setDuplicateRow] = React.useState()

  function handleOnCheck(event) {
    var itGroupTemp = null
    let itGroupIndex = _.findIndex(itGroups[selectedRow.tableData.id].itCodes.allowed, {
      name: event.target.value
    })
    let itCodesIndex = _.findIndex(itCodes, {
      name: event.target.value
    })

    // If not found in itGroup then push the given itCode into the array
    if (itGroupIndex == -1) {
      itGroupTemp = _.cloneDeep(itGroups)
      itGroupTemp[selectedRow.tableData.id].itCodes.push(itCodes[itCodesIndex])
      setItGroups(itGroupTemp)
    }
    // If found in the itGroup, then filter it out and return the new list
    else {
      itGroupTemp = _.cloneDeep(itGroups)

      itGroupTemp[selectedRow.tableData.id].itCodes = itGroupTemp[selectedRow.tableData.id].itCodes.filter(function (el) {
        return el.name != event.target.value
      })

      setItGroups(itGroupTemp)
    }
  }

  function duplicateItGroup(rowData) {
    setDuplicateRow(rowData)
    setOpen(true)
  }

  function confirmDuplicate() {
    if (newGroupName) {
      setHiddenNameText(true)
      setOpen(false)
      let itGroupName = newGroupName
      let itCodes = duplicateRow.itCodes

      dispatch(userInfoActions.createItGroup({ token: auth.token }, itGroupName, itCodes))
    } else {
      setHiddenNameText(false)
    }
  }

  function typeNewGroupName(name) {
    setNewGroupName(name)
  }

  useEffect(() => {
    if (pageLoad == false) {
      setItGroups(serviceRulesItGroups)
      setPageLoad(true)
      // setSelectedRow({
      //   tableData: { id: 0 }
      // })
    }
  }, [])

  useEffect(() => {
    setItGroups(serviceRulesItGroups)
  }, [serviceRulesItGroups])

  const handleClose = () => {
    setOpen(false)
  }

  console.log('selectedRow', selectedRow)

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Duplicate Group</h2>
            <TextField
              id="dayExtend"
              name="dayExtend"
              label="Group Name"
              margin="dense"
              variant="outlined"
              value={newGroupName}
              onChange={e => typeNewGroupName(e.target.value)}
              type="text"
              fullWidth
            ></TextField>
            <Typography id="requiredNameText" style={{ fontSize: 13, marginLeft: 2, color: "red" }} hidden={hiddenNameText}>
              * Name is required
            </Typography>
            <Grid container alignItems="center" justify="center" style={{ marginTop: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                style={{
                  minWidth: 100,
                  margin: 5
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={confirmDuplicate}
                style={{
                  minWidth: 100,
                  margin: 5
                }}
              //onClick={confirmExtension}
              >
                Confirm
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={5} lg={7}>
          <MaterialTable
            icons={tableIcons}
            isLoading={isFetching}
            title="IT Groups"
            columns={[
              { title: "IT Group", field: "name" }
              // { title: "active", field: "active" }
              // { title: "ID", field: "id", hidden: "true" }
              /* { title: 'Allowed', field: 'allowed' , type: 'boolean'}, */
            ]}
            options={{
              minBodyHeight: "calc(100vh - 224px)",
              maxBodyHeight: "calc(100vh - 224px)",
              pageSize: 20,
              rowStyle: rowData => ({
                backgroundColor: selectedRow && selectedRow.tableData.id === rowData.tableData.id ? "#EEE" : "#FFF",
                color: itGroups && rowData && rowData.active ? "black" : "lightgrey"
              })
            }}
            data={itGroups ? itGroups.map(x => Object.assign({}, x)) : []}
            onRowClick={(event, selectedRow) => {
              setSelectedRow(selectedRow)
            }}
            editable={{
              isAddable: () => userType == "ADMIN" || userType == "SERVICE_MANAGER",
              isDeletable: () => userType == "ADMIN" || userType == "SERVICE_MANAGER",
              isEditable: rowData => (rowData.active == true && userType == "ADMIN") || (rowData.active == true && userType == "SERVICE_MANAGER"),
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    {
                      let itGroupName = newData.name
                      let itCodes = {
                        allowed: [],
                        denied: []
                      }
                      dispatch(userInfoActions.createItGroup({ token: auth.token }, itGroupName, itCodes))
                    }
                    resolve()
                  }, 1000)
                }),
              // onRowUpdate: newData =>
              //   new Promise(resolve => {
              //     setTimeout(() => {
              //       {
              //         let itGroup = newData
              //         dispatch(
              //           userInfoActions.setItGroup(
              //             { token: auth.token },
              //             itGroup
              //           )
              //         )
              //       }
              //       resolve()
              //     }, 1000)
              //   }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    {
                      let itGroup = oldData
                      setSelectedRow(null)
                      dispatch(userInfoActions.deleteItGroup({ token: auth.token }, itGroup))
                    }
                    resolve()
                  }, 1000)
                })
            }}
            actions={[
              {
                // eslint-disable-next-line react/display-name
                icon: () => <FileCopyOutlinedIcon />,
                tooltip: "Duplicate",
                onClick: (event, rowData) => {
                  duplicateItGroup(rowData)
                }
              }
            ]}
          />
        </Grid>
        {selectedRow && <ItGroupSummary
          selectedRow={selectedRow}
          handleOnCheck={handleOnCheck}
          itGroups={itGroups}
          itCodes={itCodes}
          setSelectedRow={setSelectedRow}
        />}
      </Grid>
    </React.Fragment>
  )
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px"
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalTextField: {
    width: 80,
    marginTop: 0,
    marginLeft: 10,
    padding: 0
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "& .MuiInputBase-input": {
      textAlign: "center"
    },
    maxWidth: 300
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outlineColor: "transparent"
    },
    borderRadius: 3,
    minHeight: 175,
    minWidth: 500
  },
  noFocus: {
    "&:focus": {
      outlineColor: "transparent"
    }
  }
}))

export default MaterialTableDemo
