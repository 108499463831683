import * as userInfoActions from "../../actions/userInfoActions"
import MaterialTable from "material-table"
import React, { useEffect } from "react"
import tableIcons from "../../utils/helper"
import { useSelector, useDispatch } from "react-redux"

const ItCodes = () => {
  const _ = require("lodash")
  const dispatch = useDispatch()
  const userType = useSelector((state) => _.get(state, "userInfo.settings.user.userType", ""))
  const auth = useSelector((state) => ({ ...state.auth }))
  const businessItCodes = useSelector((state) => _.get(state, "userInfo.settings.itcodes", []))
  const isFetching = useSelector((state) => _.get(state, "userInfo.isFetching", false))
  const itCodes = useSelector((state) => _.get(state, "userInfo.settings.itcodes", []))

  useEffect(() => {
    dispatch(userInfoActions.getServiceSettings({ token: auth.token }))
  }, [])

  return (
    <MaterialTable
      icons={tableIcons}
      isLoading={isFetching}
      title="IT Codes"
      columns={
        userType == "ADMIN"
          ? [
              { title: "IT Code", field: "code" },
              { title: "Description", field: "name" },
              { title: "Rider", field: "riderName" },
            ]
          : [
              { title: "IT Code", field: "code", editable: "never" },
              { title: "Description", field: "name" },
            ]
      }
      data={
        businessItCodes && itCodes
          ? userType == "ADMIN"
            ? businessItCodes.map((x) => {
              const riderName = x.meta.find(f => f.key === "riderName").value
              return {
                code: x.code,
                name: x.name,
                meta: x.meta,
                riderName
              }
            })
            : itCodes.map((x) => Object.assign({}, x))
          : []
      }
      options={{
        minBodyHeight: "calc(100vh - 224px)",
        maxBodyHeight: "calc(100vh - 224px)",
        pageSize: 10,
      }}
      editable={{
        isDeletable: () => userType == "ADMIN",
        isEditable: () => userType == "ADMIN" || userType == "SERVICE_MANAGER",
        onRowAdd:
          userType == "ADMIN"
            ? (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    {
                      if (userType == "ADMIN") {
                        const processed = {
                          code: newData.code.toUpperCase(),
                          name: newData.name,
                          riderName: newData.riderName
                        }
                        const changed = _.cloneDeep(businessItCodes).map(e => {
                          const riderMeta = e.meta.find(e => e.key === "riderName");
                          const riderName = riderMeta ? riderMeta.value 
                            : ""
                          return {
                            code: e.code,
                            name: e.name,
                            meta: e.meta,
                            riderName
                          }
                        }).concat(processed)
                        dispatch(userInfoActions.setItCodes({ token: auth.token }, changed, userType))
                      }
                    }
                    resolve()
                  }, 1000)
                })
            : undefined,
        onRowUpdate: (newData, target) =>
          new Promise((resolve) => {
            setTimeout(() => {
              {
                let changed = _.cloneDeep(businessItCodes).map(e => {
                  const riderMeta = e.meta.find(e => e.key === "riderName");
                  const riderName = riderMeta ? riderMeta.value 
                    : ""
                  return {
                    code: e.code,
                    name: e.name,
                    meta: e.meta,
                    riderName
                  }
                })
                let index = target.tableData.id
                const riderName = !_.isEmpty(newData.riderName) 
                  ? newData.riderName 
                  : newData.meta.find(f => f.key === "riderName").value
                changed[index] = { 
                  code: newData.code,
                  name: newData.name,
                  meta: newData.meta,
                  riderName
                }

                dispatch(userInfoActions.setItCodes({ token: auth.token }, changed, userType))
              }
              resolve()
            }, 1000)
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              {
                const callback = (acc, e) => {
                  if(e.code === oldData.code) {
                    return acc;
                  }
                  const riderMeta = e.meta.find(e => e.key === "riderName");
                  const riderName = riderMeta ? riderMeta.value : ""
                  return acc.concat({
                    ...e,
                    riderName
                  })
                }
                let changed = businessItCodes.reduce(callback, [])

                dispatch(userInfoActions.setItCodes({ token: auth.token }, changed, userType))
              }
              resolve()
            }, 1000)
          }),
      }}
    />
  )
}

export default ItCodes
