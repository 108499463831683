import * as types from "../constants/actionTypes"

export function getServiceManagers(params) {
    return {
        type: types.SERVICE_MANAGERS.GET.REQUEST,
        payload: {
            ...params,
        },
    }
}

export function demoteManager(params, _id) {
    return {
        type: types.SERVICE_MANAGERS.DEMOTE.REQUEST,
        payload: {
            ...params,
            _id
        },
    }
}