/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import tableIcons from "../../utils/helper"
import { Grid, Typography } from "@material-ui/core"
import MaterialTable from "material-table"
import UserSettingsSummary from "./UserSettingsSummary"
import * as userInfoActions from "../../actions/userInfoActions"
import * as userGroupActions from "../../actions/userGroupActions"
import * as userActions from "../../actions/userActions"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import _ from "lodash"

const UserSettings = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const users = useSelector((state) => _.get(state, "users.data", []))
  const userGroups = useSelector((state) => _.get(state, "userGroups.data", []))
  const [usersState, setUsersState] = React.useState([]);
  const [userGroupState, setUserGroupState] = React.useState([]);
  const [pageLoad, setPageLoad] = React.useState(false)
  const locations = useSelector((state) => state)
  const vehicles = useSelector((state) => state)
  const serviceRulesItGroups = useSelector((state) => state)
  const [itGroups, setItGroups] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState()

  useEffect(() => {
    if (pageLoad === false) {
      dispatch(userGroupActions.getUserGroups({ token: auth.token }))
      dispatch(userActions.getServiceUsers({ token: auth.token }))
      setPageLoad(true)
      // setSelectedRow({
      //   tableData: { id: 0 }
      // })
    }
  }, [])

 /*  useEffect(() => {
    if(_.isEmpty(users)) {
      console.log({users})
      setUsersState(users)
    }
    if(!_.isEmpty(userGroups)) {
      setUserGroupState(userGroups)
    }
  }, [users, userGroups]) */


  function mapItGroupsToIds(itGroupsArray) {
    let idArray = []
    _.forEach(itGroupsArray, function (userItGroup) {
      for (let i = 0; i < serviceRulesItGroups.length; i++) {
        if (serviceRulesItGroups[i].name == userItGroup) {
          idArray.push(serviceRulesItGroups[i].id)
          break
        }
      }
    })

    return idArray
  }

  function handlePromoteUser(rowData) {
    dispatch(
      userActions.promoteUser({ token: auth.token }, rowData)
    )
  }

  function handleRequireReset(rowData) {
    dispatch(userActions.requireResetPassword({ token: auth.token }, users[rowData.tableData.id]))
  }

  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={5} lg={7}>
        <MaterialTable
          //isLoading={bookings.isFetching}
          columns={[
            //{ title: "Reference ID", field: "ReferenceId" },
            { title: "Email", field: "emailAddress" },
            { title: "First Name", field: "firstName" },
            { title: "Last Name", field: "lastName" },
            { title: "User Type", field: "userType" },
          ]}
          icons={tableIcons}
          title="User Settings"
          options={{
            minBodyHeight: "calc(100vh - 224px)",
            maxBodyHeight: "calc(100vh - 224px)",
            pageSize: 20,
            rowStyle: (rowData) => ({
              backgroundColor: selectedRow && selectedRow.tableData.id === rowData.tableData.id ? "#EEE" : "#FFF",
            }),
            actionsColumnIndex: 4,
          }}
          actions={[
            (rowData) => ({
              // eslint-disable-next-line react/display-name
              icon: () => <SupervisorAccountIcon style={{ fontSize: 20 }} />,
              tooltip: <Typography>Promote to Insurance Manager</Typography>,
              onClick: (event) => handlePromoteUser(rowData, event),
            }),
            (rowData) => ({
              // eslint-disable-next-line react/display-name
              icon: () => <LockOpenIcon style={{ fontSize: 20 }} />,
              tooltip: <Typography>Require Reset Password</Typography>,
              onClick: (event) => handleRequireReset(rowData, event),
            }),
          ]}
          onRowClick={(event, row) => {
            setSelectedRow(row)
          }}
          data={users.map((x) => Object.assign({}, x))}
        />
      </Grid>
      <UserSettingsSummary
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        userGroups={userGroups}
        users={users}
      />
    </Grid>
  )
}

export default UserSettings
