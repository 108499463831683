/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect, Fragment } from "react"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import {
  Backdrop,
  Grid,
  Button,
  makeStyles,
  TextField,
  Modal,
  Fade,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@material-ui/core"
import EventIcon from "@material-ui/icons/Event"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import _ from "lodash"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import Divider from "@material-ui/core/Divider"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import CloseIcon from "@material-ui/icons/Close"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import CircularProgress from "@material-ui/core/CircularProgress"
import { HERTZ_YELLOW } from "../../constants/style"
import { useSelector, useDispatch } from "react-redux"
import * as bookingsActions from "../../actions/bookingsActions"
import EstimateCard from "./EstimateCard"

const BookingModal = (props) => {
  const classes = useStyles()
  const bookings = useSelector((state) => state.bookings)
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const loadingEstimate = useSelector((state) => bookings.isFetchingEstimate)
  const loadingModify = useSelector((state) => bookings.isFetchingModifyReservation)
  const minDate = moment(props.selectedRow.DropOff.DateTime).add(1, "days")
  const [dropOffDate, setDropOffDate] = React.useState(
    props.selectedRow
      ? moment(props.selectedRow.DropOff.DateTime) > minDate
        ? moment(props.selectedRow.DropOff.DateTime)
        : minDate
      : minDate
  )
  const [dropOffTime, setDropOffTime] = React.useState(
    props.selectedRow ? moment(props.selectedRow.DropOff.DateTime).format("HH:mm") : "12:00"
  )
  const [extendDays, setExtendDays] = React.useState(1)
  const [disabled, setDisabled] = React.useState(false)

  useEffect(() => {
    props.selectedRow && props.selectedRow.DropOff && props.selectedRow.DropOff.DateTime
      ? setExtendDays(minDate.diff(moment(props.selectedRow.DropOff.DateTime), "days"))
      : setExtendDays(1)
  }, [props.selectedRow])

  useEffect(() => {
    if (bookings.modifiedRental) {
      dispatch(bookingsActions.clearReservation())
      handleClose()
    }
  }, [bookings.modifiedRental])

  const addDaysDuration = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setDropOffDate(moment(props.selectedRow.DropOff.DateTime).add(parseInt(extendDays) + 1, "d"))
    setExtendDays(parseInt(extendDays) + 1)
    checkValidDate(moment(props.selectedRow.DropOff.DateTime).add(parseInt(extendDays) + 1, "d"))
  }

  const subtractDaysDuration = (e) => {
    e.preventDefault()
    e.stopPropagation()
    var NewDropOff = moment(dropOffDate).add(-1, "d")

    if (NewDropOff.diff(moment(props.selectedRow.PickUp.DateTime), "days") >= 1) {
      setDropOffDate(moment(props.selectedRow.DropOff.DateTime).add(extendDays - 1, "d"))
      setExtendDays(extendDays - 1)
    }
    checkValidDate(moment(props.selectedRow.DropOff.DateTime).add(parseInt(extendDays) - 1, "d"))
  }

  const handleDateChange = (d) => {
    var startDate = moment(props.selectedRow.DropOff.DateTime, "YYYY-MM-DD")
    var endDate = moment(d, "YYYY-MM-DD")

    setExtendDays(endDate.diff(startDate, "days"))
    setDropOffDate(moment(d, "YYYY-MM-DD"))
    checkValidDate(moment(d, "YYYY-MM-DD"))
  }

  function checkValidDate(dropOff) {
    if (
      moment(dropOff) <= moment(props.selectedRow.PickUp.DateTime) ||
      moment(dropOff) <= moment(props.selectedRow.DropOff.DateTime)
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  function modifyEstimate() {
    const mDropOffDate = moment(dropOffDate);
    const newDropOffDate = moment(new Date(`${mDropOffDate.format("YYYY-MM-DD")}T${dropOffTime}:00`))
    dispatch(bookingsActions.clearEstimate());
    dispatch(
      bookingsActions.getRentalEstimate({
        token: auth.token,
        rentalNumber: props.selectedRow.RentalNumber,
        location: bookings.reservation.DropOff.Location.locationCode,
        dateTime: moment(newDropOffDate).format("YYYY-MM-DDTHH:mm"),
        referenceId: bookings.reservation.ReferenceId,
      })
    )
  }

  function modifyRental() {
    const mDropOffDate = moment(dropOffDate);
    const newDropOffDate = moment(new Date(`${mDropOffDate.format("YYYY-MM-DD")}T${dropOffTime}:00`))
    dispatch(
      bookingsActions.modifyRental({
        token: auth.token,
        rentalNumber: props.selectedRow.RentalNumber,
        location: bookings.reservation.DropOff.Location.locationCode,
        dateTime: moment(newDropOffDate).format("YYYY-MM-DDTHH:mm"),
        referenceId: bookings.reservation.ReferenceId,
      })
    )
  }

  const handleClose = () => {
    props.setOpen(false)
    props.setOpenExtend(false)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={props.open} className={classes.paper} style={{ padding: 10 }}>
          <div className={classes.noFocus}>
            {props.openExtend && props.selectedRow.PickUp && props.selectedRow.PickUp.DateTime && (
              <div className={classes.root}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                  <div style={{ cursor: "pointer" }}>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                  </div>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={3}>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Grid item xs={12}>
                      <h2>Modify</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        disabled
                        margin="dense"
                        id="dropoffDateTime"
                        label={"Current Drop Off Date"}
                        minDateMessage="Dropoff should not be before pickup or current date"
                        style={{ maxWidth: 350 }}
                        minutesStep={5}
                        fullWidth
                        format="dd DD MMM YYYY hh:mm A"
                        showTodayButton
                        inputVariant="outlined"
                        value={props.selectedRow.DropOff.DateTime}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Divider style={{ marginTop: 15, marginBottom: 15, width: 350 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        margin="dense"
                        id="dropoffDate"
                        label={"Drop Off Date"}
                        disablePast
                        minDate={minDate.format("YYYY-MM-DD HH:mm")}
                        minDateMessage="Dropoff must be next day or later"
                        style={{ maxWidth: 350 }}
                        minutesStep={5}
                        fullWidth
                        format="dd DD MMM YYYY"
                        showTodayButton
                        inputVariant="outlined"
                        value={moment(dropOffDate).format("YYYY-MM-DD HH:mm")}
                        onChange={(d) => {
                          handleDateChange(d, "dropoffDate")
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="pickupTime"
                        name="pickupTime"
                        select
                        style={{ maxWidth: 350, textAlign: "left" }}
                        label={"Drop Off Time"}
                        value={dropOffTime}
                        onChange={(e) => setDropOffTime(e.target.value)}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="00:00">12:00 AM</MenuItem>
                        <MenuItem value="00:30">12:30 AM</MenuItem>
                        <MenuItem value="01:00">01:00 AM</MenuItem>
                        <MenuItem value="01:30">01:30 AM</MenuItem>
                        <MenuItem value="02:00">02:00 AM</MenuItem>
                        <MenuItem value="02:30">02:30 AM</MenuItem>
                        <MenuItem value="03:00">03:00 AM</MenuItem>
                        <MenuItem value="03:30">03:30 AM</MenuItem>
                        <MenuItem value="04:00">04:00 AM</MenuItem>
                        <MenuItem value="04:30">04:30 AM</MenuItem>
                        <MenuItem value="05:00">05:00 AM</MenuItem>
                        <MenuItem value="05:30">05:30 AM</MenuItem>
                        <MenuItem value="06:00">06:00 AM</MenuItem>
                        <MenuItem value="06:30">06:30 AM</MenuItem>
                        <MenuItem value="07:00">07:00 AM</MenuItem>
                        <MenuItem value="07:30">07:30 AM</MenuItem>
                        <MenuItem value="08:00">08:00 AM</MenuItem>
                        <MenuItem value="08:30">08:30 AM</MenuItem>
                        <MenuItem value="09:00">09:00 AM</MenuItem>
                        <MenuItem value="09:30">09:30 AM</MenuItem>
                        <MenuItem value="10:00">10:00 AM</MenuItem>
                        <MenuItem value="10:30">10:30 AM</MenuItem>
                        <MenuItem value="11:00">11:00 AM</MenuItem>
                        <MenuItem value="11:30">11:30 AM</MenuItem>
                        <MenuItem value="12:00">12:00 PM</MenuItem>
                        <MenuItem value="12:30">12:30 PM</MenuItem>
                        <MenuItem value="13:00">01:00 PM</MenuItem>
                        <MenuItem value="13:30">01:30 PM</MenuItem>
                        <MenuItem value="14:00">02:00 PM</MenuItem>
                        <MenuItem value="14:30">02:30 PM</MenuItem>
                        <MenuItem value="15:00">03:00 PM</MenuItem>
                        <MenuItem value="15:30">03:30 PM</MenuItem>
                        <MenuItem value="16:00">04:00 PM</MenuItem>
                        <MenuItem value="16:30">04:30 PM</MenuItem>
                        <MenuItem value="17:00">05:00 PM</MenuItem>
                        <MenuItem value="17:30">05:30 PM</MenuItem>
                        <MenuItem value="18:00">06:00 PM</MenuItem>
                        <MenuItem value="18:30">06:30 PM</MenuItem>
                        <MenuItem value="19:00">07:00 PM</MenuItem>
                        <MenuItem value="19:30">07:30 PM</MenuItem>
                        <MenuItem value="20:00">08:00 PM</MenuItem>
                        <MenuItem value="20:30">08:30 PM</MenuItem>
                        <MenuItem value="21:00">09:00 PM</MenuItem>
                        <MenuItem value="21:30">09:30 PM</MenuItem>
                        <MenuItem value="22:00">10:00 PM</MenuItem>
                        <MenuItem value="22:30">10:30 PM</MenuItem>
                        <MenuItem value="23:00">11:00 PM</MenuItem>
                        <MenuItem value="23:30">11:30 PM</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <h4>- OR -</h4>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="dayExtend"
                        name="dayExtend"
                        label="Extend By Days"
                        className={classes.textField}
                        value={extendDays}
                        margin="dense"
                        type="number"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: { textAlign: "center" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" disabled={extendDays < 2} onClick={(e) => subtractDaysDuration(e)}>
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" onClick={(e) => addDaysDuration(e)}>
                                <AddCircleOutlineIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center", paddingRight: 50 }}>
                    <Grid item xs={12}>
                      <h2>Estimate</h2>
                    </Grid>
                    {!bookings.estimate && <div>Estimate will be displayed here</div>}
                    {bookings.estimate && (
                      <React.Fragment>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={6}>
                            <EstimateCard prefix={"OLD"} type={"CUSTOMER"} />
                          </Grid>
                          <Grid item xs={6}>
                            <EstimateCard prefix={"OLD"} type={"INSURER"} />
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <ArrowDownwardIcon style={{ fontSize: 30, color: HERTZ_YELLOW }} />
                            <ArrowDownwardIcon style={{ fontSize: 30, color: HERTZ_YELLOW }} />
                            <ArrowDownwardIcon style={{ fontSize: 30, color: HERTZ_YELLOW }} />
                          </Grid>
                          <Grid item xs={6}>
                            <EstimateCard prefix={"NEW"} type={"CUSTOMER"} />
                          </Grid>
                          <Grid item xs={6}>
                            <EstimateCard prefix={"NEW"} type={"INSURER"} />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
            <div className={classes.root} style={{ marginTop: 25, marginBottom: 30 }}>
              <Grid container direction="row" justify="center" alignItems="flex-end">
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ minWidth: 90, margin: 5 }}
                    onClick={() => modifyEstimate()}
                    disabled={loadingEstimate ? true : disabled}
                  >
                    {loadingEstimate ? (
                      <div>
                        Getting Estimate <CircularProgress size={12} />
                      </div>
                    ) : (
                      <div>Get Estimate</div>
                    )}
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center", paddingRight: 30 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ minWidth: 90, margin: 5 }}
                    onClick={() => modifyRental()}
                    disabled={loadingModify ? true : bookings.estimate ? false : true}
                  >
                    {loadingModify ? (
                      <div>
                        Modifying <CircularProgress size={12} />
                      </div>
                    ) : (
                      <div>Confirm Modify</div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
    maxWidth: 350,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "&:focus": {
      outlineColor: "transparent",
    },
    borderRadius: 3,
    width: 1200,
  },
  noFocus: {
    "&:focus": {
      outlineColor: "transparent",
    },
  },
}))

export default BookingModal
