import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function quotationRducer(
  state = initialState.quotation,
  action
) {
  switch (action.type) {
    case types.SET_QUOTATION:
      return {
        ...state,
        ...action.payload,
        cleared: false
      }
    case types.CLEAR_QUOTATION:
      // eslint-disable-next-line no-case-declarations
      const quotation = {
        ...state,
        ...initialState.quotation,
        claimnumber: "",
        policynumber: ""
      }
      return {
        ...quotation
      }
    case types.LOGOUT_REQUEST:
      return {
        ...initialState.quotation
      }
    default:
      return state
  }
}
