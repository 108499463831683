import * as types from "../constants/actionTypes"

export function changePassword(params, credentials) {
    return {
        type: types.CHANGE_PASSWORD.SET.REQUEST,
        payload: {
            ...params,
            credentials,
        },
    }
}