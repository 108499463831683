/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"

import { Card, Grid, makeStyles } from "@material-ui/core"


const AddUsersFilter = props => {
  const classes = useStyles()
  const [selectedInnerRow, setSelectedInnerRow] = React.useState()
  const { userGroups } = props

  return (
    <Fragment>
      <Grid item xs={7} lg={5}>
        <Card className={classes.summaryCardActive}>
          {userGroups  && userGroups.length > 0 && (
            <MaterialTable
              icons={tableIcons}
              title="User Groups"
              columns={[
                { title: "Description", field: "name" },
                { title: "_id", field: "_id", hidden: true},
                // { title: "active", field: "active" }
                // { title: "ID", field: "id", hidden: "true" }
                /* { title: 'Allowed', field: 'allowed' , type: 'boolean'}, */
              ]}
              options={{
                minBodyHeight: "calc(100vh - 224px)",
                maxBodyHeight: "calc(100vh - 224px)",
                pageSize: 300,
                paging: true,
                paginationType: "normal",
                selection: true,
              }}
              data={userGroups ? userGroups : []}
              onRowClick={(event, rowData) => {
                console.log({rowData})
                setSelectedInnerRow(rowData)
              }}
              onSelectionChange={(rowData) => {
                props.setCheckedUserGroups(rowData)
              }}
            />
          )}
        </Card>
      </Grid>
    </Fragment>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden"
}

const useStyles = makeStyles(theme => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px"
    }
  },
  actionButtons: {
    height: "100%",
    textAlign: "center"
  },
  summaryCard: {
    height: "calc(100vh - 106px)"
  },
  summaryCardActive: {
    height: "calc(100vh - 168px)"
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  customerDetails: {
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE"
    }
  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0"
    },
    "& .MuiInputBase-root": {
      marginTop: "12px"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE"
    }
  },
  popover: {
    pointerEvents: "none"
  }
}))

export default AddUsersFilter
