import * as types from "../constants/actionTypes"
import initialState from "./initialState"
import _ from "lodash"

function alphabetise(data) {
  return _.sortBy(data, [
    function (data) {
      return data.locationName
    },
  ])
}

export default function locationReducer(state = initialState.locations, action) {
  switch (action.type) {
    case types.LOCATIONS.GET.REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.LOCATIONS.GET.SUCCESS:
      return {
        ...state,
        data: action.result.data.GetLocations,
        isFetching: false,
      }
    case types.LOCATIONS.GET.FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
      }
    case types.LOCATIONS.CLEAR:
      return {
        ...state,
        data: null,
        isFetching: false,
      }
    case types.ALL_LOCATIONS.GET.REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.ALL_LOCATIONS.GET.SUCCESS:
      return {
        ...state,
        locations: alphabetise(action.result.data.GetLocations),
        success: true,
        isFetching: false,
      }
    case types.ALL_LOCATIONS.GET.FAILURE:
      return {
        ...state,
        success: false,
        isFetching: false,
      }
    case types.SEARCHADDRESS.GET.REQUEST:
      return {
        ...state,
        addressSuggestions: []
      }
    case types.SEARCHADDRESS.GET.SUCCESS:
      {
        const addressSuggestions = [];
        _.get(action, "result.data.KleberDataValidation.AddressResult", []).forEach((address) => {
          if (
            _.get(action, "result.data.KleberDataValidation.International", false) &&
            (_.get(action, "result.data.KleberDataValidation.SuggestionCountry", "") != "AU" ||
              _.get(action, "result.data.KleberDataValidation.SuggestionCountry", "") != "NZ")
          ) {
            addressSuggestions.push({
              label: _.get(address, "AddressLine", ""),
              value: _.get(address, "RecordId", null),
            });
          } else if (
            _.get(action, "result.data.KleberDataValidation.SuggestionCountry", "") == "NZ"
          ) {
            addressSuggestions.push({
              label:
                _.get(address, "AddressLine", "") +
                " " +
                _.get(address, "Suburb", "") +
                ", " +
                _.get(address, "TownCityMailtown", "") +
                ", " +
                _.get(address, "Postcode", ""),
              value: _.get(address, "RecordId", null),
              address
            });
          } else if (
            _.get(action, "result.data.KleberDataValidation.SuggestionCountry", "") == "AU"
          ) {
            addressSuggestions.push({
              label:
                _.get(address, "AddressLine", "") +
                ", " +
                _.get(address, "Locality", "") +
                ", " +
                _.get(address, "Postcode", "") +
                ", " +
                _.get(address, "State", ""),
              value: _.get(address, "RecordId", null),
              address
            });
          }
        });
        return {
          ...state,
          addressSuggestions: addressSuggestions,
        }
      }
    case types.SEARCHADDRESS.GET.FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
      }
    case types.RETRIEVEADDRESS.GET.REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.RETRIEVEADDRESS.GET.SUCCESS:
      return {
        ...state,
        data: action.result.data.GetLocations,
        isFetching: false,
      }
    case types.RETRIEVEADDRESS.GET.FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
      }
    default:
      return state
  }
}
