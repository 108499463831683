import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function resetPasswordReducer(state = initialState.forgotPassword, action) {
    switch (action.type) {
        case types.RESET_PASSWORD.GET.REQUEST:
            return {
                ...state,                
                isValidResetToken: undefined,
                isFetching: true,
            }
        case types.RESET_PASSWORD.GET.SUCCESS:
            return {
                ...state,
                isFetching: false,
                isValidResetToken: true
            }
        case types.RESET_PASSWORD.GET.FAILURE:
            return {
                ...state,
                isFetching: false,
                isValidResetToken: false
            }
        
        case types.RESET_PASSWORD.SET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.RESET_PASSWORD.SET.SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: true
            }
        case types.RESET_PASSWORD.SET.FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: action.result.errors,
                success: false
            }
        case types.RESET_PASSWORD.CLEAR.REQUEST:
            return {
                ...state,
                isFetching: false,
                success: undefined
            }
        default:
            return state
    }
}