import { useSelector } from "react-redux"
import _ from "lodash"

export const getControlITCodes = () => {
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const userType = _.get(userInfo, "settings.user.userType", "DEACTIVATED")

  if (userType) {
    switch (userType) {
      case "ADMIN":
        return _.has(userInfo, "settings.businessRules.itCodes") ? [...userInfo.settings.businessRules.itCodes] : []
      case "SERVICE_MANAGER":
        return _.has(userInfo, "settings.serviceRules.itCodes") ? [...userInfo.settings.serviceRules.itCodes] : []
      case "SERVICE_USER":
        return _.has(userInfo, "settings.rentalRules.itCodes") ? [...userInfo.settings.rentalRules.itCodes] : []
      case "DEACTIVATED":
      default:
        return []
    }
  }
}

// export const getITCodes = () => {
//   const userInfo = useSelector((state) => ({ ...state.userInfo }))
//   if (_.has(userInfo, "settings.user.allowedItCodes")) {
//     if (_.isEmpty(userInfo.settings.user.allowedItCodes)) {
//       return userInfo.settings.rentalRules.itCodes
//     } else {
//       return userInfo.settings.businessRules.itCodes.filter(
//         (i) => userInfo.settings.user.allowedItCodes.indexOf(i.itCode) != -1
//       )
//     }
//   }
// }

export const getITCodes = () => {
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  return _.get(userInfo, "settings.itcodes", [])
}

export const getVehicleClasses = () => {
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  //const bookings = useSelector(state => ({ ...state.bookings }));
  return _.get(userInfo, "settings.vehicleModels", [])
}

export const getAllowOneWay = () => {
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  // return !_.get(userInfo, "settings.rentalRules.allowOneWay", false)

  return _.get(_.find(userInfo.settings.meta, { "key": "allowOneWay" }), "value", false)
}

export const getAllowPickupAndDropoff = () => {
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  return _.get(_.find(userInfo.settings.meta, { "key": "allowPickupAndDropoff" }), "value", false)
}
