/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Login from "./containers/Login"
import CreateBooking from "./containers/CreateBooking"
import ManageBooking from "./containers/ManageBooking"
import Dashboard from "./containers/Dashboard"
import ControlPanel from "./containers/ControlPanel"
import NotFound from "./containers/NotFound"
import ResetPassword from "./containers/ResetPassword"
import ChangePassword from "./containers/ChangePassword"
import ForgotPassword from "./containers/ForgotPassword"
import SsoAuth from "./containers/SsoAuth"
import _ from "lodash"
import jwtdecode from "jwt-decode"
import moment from "moment"

import { i18n, fetchi18n } from "./i18n"
import NotificationCenter from "./containers/NotificationCenter"
import * as authActions from "../actions/authActions"

import * as locationsActions from "../actions/locationsActions"
import * as bookingsActions from "../actions/bookingsActions"

const App = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const passwordResetRequired = useSelector((state) =>
    state.userInfo.settings?.user ? state.userInfo.settings.user.passwordResetRequired : []
  )
  const services = ["RACQ", "SUNCORP", "AIOI", "TOYOTA"]
  const [service, setService] = useState(null)
  const locations = useSelector((state) => state.locations?.locations)
  const vehicles = useSelector((state) => state.bookings?.vehicles)

  const path = location.pathname.split("/")[1]
  const base = services.indexOf(path) != -1 ? path.toUpperCase() : ""

  useEffect(() => {
    if (base != "" && service == null && services.indexOf(base) != -1) {
      fetchi18n(base, setService)
    }
    if (!locations && auth?.token) {
      dispatch(locationsActions.getAllLocations({ token: auth.token }))
    }
    if (!vehicles && auth?.token) {
      dispatch(bookingsActions.getVehicleModels({ token: auth.token }))
    }
  }, []) // fetch i18n

  return (
    <i18n.Provider value={service}>
      <NotificationCenter />
      <Switch>
        {passwordResetRequired &&
          passwordResetRequired === true &&
          location.pathname.split("/")[2] != "resetpassword" && (
            <Redirect from={`/${base}`} to={`/${base}/resetpassword`} />
          )}
        <Route exact path={"/"} component={NotFound} />
        <Route exact path={"/login"} component={NotFound} />        
        <Redirect exact from={`/${base}/`} to={`/${base}/dashboard`} />
        <Redirect exact from={`/${base}/controlpanel`} to={`/${base}/controlpanel/servicesettings`} />
        <Redirect exact from={`/${base}/bookings/manage`} to={`/${base}/bookings/manage/reservations`} />
        <Route path={`/${base}/login`} component={Login} />
        <Route path={`/${base}/forgotPassword`} component={ForgotPassword} />
        <Route path={`/${base}/auth/openid/return`} component={SsoAuth} />
        <Route path={`/${base}/resetpassword/:token`} component={ResetPassword}/>
        <PrivateRoute
          exact
          path={`/${base}/dashboard`}
          component={Dashboard}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <PrivateRoute
          path={`/${base}/controlpanel`}
          component={ControlPanel}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <PrivateRoute
          exact
          path={`/${base}/bookings/create`}
          component={CreateBooking}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <PrivateRoute
          exact
          path={`/${base}/bookings/manage/editreservation`}
          component={CreateBooking}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <PrivateRoute
          path={`/${base}/bookings/manage`}
          component={ManageBooking}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <PrivateRoute
          path={`/${base}/resetpassword`}
          component={ResetPassword}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <PrivateRoute
          path={`/${base}/changePassword`}
          component={ChangePassword}
          base={base}
          auth={auth}
          userInfo={userInfo}
        />
        <Redirect from={`/${base}`} to={`/${base}/login`} />{" "}
        {/* this will redirect all NotFound pages to login instead of showing NotFound page */}
        <Route component={NotFound} /> {/* this is a failsafe to catch not found pages if catch all redirect fails */}
      </Switch>
    </i18n.Provider>
  )
}

const PrivateRoute = ({ component: Component, auth: auth, userInfo: userInfo, base: base, ...rest }) => {
  const dispatch = useDispatch()

  if (auth.token != "") {
    const decodedToken = jwtdecode(auth.token)
    const tokenExpires = moment.unix(decodedToken.exp)
    const minutesLeft = moment.duration(tokenExpires - moment()).asMinutes()

    // if(minutesLeft <= 1439.9){ // expire immediately for testing
    if (minutesLeft <= 10) {
      // 10 minutes before token expires
      dispatch(authActions.logout())
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return _.get(_.find(userInfo.settings.meta, { "key": "serviceName" }), "value", false) && auth.token != "" ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/${base}/login`, state: { from: props.location } }} />
        )
      }
      }
    />
  )
}

export default App
