/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react"
// import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Tabs, Tab } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"

import { Route, Switch } from "react-router-dom"

import Reservations from "./Reservations"
import Rentals from "../partials/Rentals"
import BulkExtend from "./BulkExtend"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "calc(100vh - 64px)"
  },
  mainpane: {
    paddingTop: "20px",
    maxWidth: "100%",
    background: "#f0f0f0"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const ManageBookingTabs = props => {
  const classes = useStyles()
  const [manageBookingTab, setManageBookingTab] = React.useState(
    props.location.pathname
  )
  const dispatch = useDispatch()
  const base = location.pathname.split("/")[1]

  function handleManageBookingTabChange(event, newValue) {
    dispatch(push({ pathname: newValue }))
    setManageBookingTab(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={manageBookingTab}
        onChange={handleManageBookingTabChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          value={"/" + base + "/bookings/manage/reservations"}
          label="Reservations"
        />
        <Tab value={"/" + base + "/bookings/manage/rentals"} label="Rentals" />
        {/* <Tab
          value={"/" + base + "/bookings/manage/bulkextend"}
          label="Bulk Extend"
        /> */}
      </Tabs>
      <Container className={classes.mainpane}>
        <Switch>
          <Route
            exact
            path={"/" + base + "/bookings/manage/reservations"}
            component={Reservations}
          />
          <Route
            exact
            path={"/" + base + "/bookings/manage/rentals"}
            component={Rentals}
          />
          {/* <Route
            exact
            path={"/" + base + "/bookings/manage/bulkextend"}
            component={BulkExtend}
          /> */}
        </Switch>
      </Container>
    </div>
  )
}

export default ManageBookingTabs
