import * as types from "../constants/actionTypes"

export function getLocations(locationObject) {
  return {
    type: types.LOCATIONS.GET.REQUEST,
    payload: {
      locationObject,
    },
  }
}

export function getAllLocations(params) {
  return {
    type: types.ALL_LOCATIONS.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function clearLocations() {
  return {
    type: types.LOCATIONS.CLEAR,
  }
}

export function searchAddress(params) {
  return {
    type: types.SEARCHADDRESS.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function retrieveAddress(params) {
  return {
    type: types.RETRIEVEADDRESS.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}
