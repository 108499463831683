/* eslint-disable react/prop-types */
import React from "react"
import { Button } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"

import * as bookingsActions from "../../actions/bookingsActions"

const ReservationCancel = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const reservation = useSelector((state) => state.bookings.reservation)

  function cancelReservation() {
    dispatch(bookingsActions.cancelReservation({ token: auth.token, reservationNumber: reservation.ReservationNumber }))
    // eslint-disable-next-line react/prop-types
    props.setOpen(false)
  }

  return (
    <div style={{ padding: 30, marginTop: 0 }}>
      <h2 id="transition-modal-title" style={{ marginTop: 0, paddingTop: 0 }}>
        Warning: Cancel Booking
      </h2>
      <p id="transition-modal-description"> Are you sure you want to cancel this booking?</p>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ margin: "5px" }}
          onClick={(e) => cancelReservation(e)}
        >
          Yes
        </Button>
        <Button variant="contained" size="small" color="primary" onClick={props.handleClose} style={{ margin: "5px" }}>
          No
        </Button>
      </div>
    </div>
  )
}

// ReservationCancel.propTypes = {
//   handleClose: PropTypes.function,
// }

export default ReservationCancel
