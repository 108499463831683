import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import * as userInfoActions from "../../actions/userInfoActions"
import { Typography } from "@material-ui/core"
import AddVehicle from "./AddVehicle"

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: 300,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}))

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

export default function TransferList() {
  const _ = require("lodash")
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector(state => ({ ...state.auth }))
  const user = useSelector(state => state.userInfo.settings.user)
  const settings = useSelector(state => state.userInfo.settings)
  const vehicles = useSelector(state => state.bookings.vehicles)
  const disallowedAdmin = useSelector(state => state.userInfo.settings.meta
    .find(e => e.key === "disallowedAdminVehicleModels").value.map(e => e.code)) 
  const availableVehicleGroups = useSelector(
    (state) => state.userInfo.settings.meta
      .find(e => e.key === "availableAggregateVehicleGroups").value.map(e => e.code)) 
  
  const disallowed = useSelector(state => state.userInfo.settings.meta
    .find(e => e.key === "disallowedVehicleModels").value.map(e => e.code))
  const [checked, setChecked] = React.useState([])

  let vehicleCodes = []
  _.forEach(vehicles, function(value) {
    // we never want H to be disabled as it's the default vehicle class for all companies
    if (value.code != "H") {
      vehicleCodes.push(value.code)
    }
  })
  vehicleCodes = _.orderBy(vehicleCodes)

  const [left, setLeft] = React.useState(_.difference(vehicleCodes, [...disallowed, ...availableVehicleGroups]))
  const [right, setRight] = React.useState(disallowed)
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    const changed = {
      disallowedVehicleModels: _.union(_.difference(
        disallowed.concat(leftChecked),
        disallowedAdmin
     ))
   };

    dispatch(userInfoActions.setUserSettings({ token: auth.token }, changed, settings))

    setRight(_.orderBy(right.concat(leftChecked)))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    const changed = {
      disallowedVehicleModels: _.union(_.difference(
        disallowed,
        rightChecked
     ))
   };

    dispatch(userInfoActions.setUserSettings({ token: auth.token }, changed, settings))

    setLeft(_.orderBy(left.concat(rightChecked)))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const getVehicleName = vehicleCode => {
    const vehicle = vehicles.find(e => e.code === vehicleCode);
    if(_.isEmpty(vehicle)) {
      console.log("vehicleCode***", vehicleCode)
    }
    const vehicleTitle = _.get(vehicle, "name", vehicle.title);
    return vehicleTitle;
  }

  function isBusinessDisabled(code) {
    /* if (user.userType == "ADMIN") {
      return false
    } else if (
      _.intersection(settings.businessRules.disallowedLocations, [locationCode])
        .length > 0
    ) {
      return true
    } else return false */
     return disallowedAdmin.includes(code)
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={items && numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={items && numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items && items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items && items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items &&
          items.map(value => {
            const labelId = `transfer-list-all-item-${value}-label`
            return (
              <ListItem key={value} role="listitem" button disabled={isBusinessDisabled(value)} onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                </ListItemIcon>
                <ListItemText id={labelId}>{getVehicleName(value)}</ListItemText>
              </ListItem>
            )
          })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <div>
      <h2 style={{ paddingBottom: 0, marginBottom: 0, marginLeft: 5 }}>Vehicles</h2>
      <Typography style={{ color: "grey", fontSize: 13, marginBottom: 5, marginLeft: 5 }}>
        This page is used to control disallowed vehicles site wide. <br />
        Any vehicle that is disallowed will not appear in availablity results.
      </Typography>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid item xs={8}>
          <Grid container spacing={2} alignItems="center" direction="row">
            <Grid item>{customList("Allowed", left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList("Disallowed", right)}</Grid>
          </Grid>
        </Grid>
        {user && user.userType == "ADMIN" && (
          <Grid item xs={4}>
            <AddVehicle />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
