/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect } from "react"
import { Backdrop, Grid, makeStyles, Modal, Fade } from "@material-ui/core"
import _ from "lodash"
import moment from "moment"
import CloseIcon from "@material-ui/icons/Close"
import { useSelector, useDispatch } from "react-redux"

import * as bookingsActions from "../../actions/bookingsActions"
import ReservationCancel from "./ReservationCancel"
import ReservationModify from "./ReservationModify"
import ReservationHistory from "./ReservationHistory"

const ReservationModal = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClose = () => {
    props.setOpen(false)
    dispatch(bookingsActions.clearEstimate())
  }

  return (
    <React.Fragment>
      {props.open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={props.open} className={classes.paper} style={{ padding: 10 }}>
            <div className={classes.noFocus}>
              <div className={classes.root}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                  <div style={{ cursor: "pointer" }}>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                  </div>
                </Grid>
                {props.modify && <ReservationModify selectedRow={props.selectedRow} handleClose={handleClose} />}
                {props.cancel && (
                  <ReservationCancel
                    selectedRow={props.selectedRow}
                    handleClose={handleClose}
                    setOpen={props.setOpen}
                  />
                )}
                {props.history && <ReservationHistory selectedRow={props.selectedRow} handleClose={handleClose} />}
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
    maxWidth: 350,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "&:focus": {
      outlineColor: "transparent",
    },
    borderRadius: 3,
    // width: 1200,
  },
  noFocus: {
    "&:focus": {
      outlineColor: "transparent",
    },
  },
}))

export default ReservationModal
