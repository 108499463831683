import * as types from "../constants/actionTypes"
import initialState from "./initialState"
import _ from "lodash"



export default function userReducer(state = initialState.users, action) {
    switch (action.type) {
        case types.USERS.GET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USERS.GET.SUCCESS:
        console.log({action})    
        return {
                ...state,
                data: _.isNil(action.result.data.GetServiceManagers) ? action.result.data.GetServiceUsers : action.result.data.GetServiceManagers,
                isFetching: false,
            }
        case types.USERS.GET.FAILURE:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        case types.USERS.DELETE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USERS.DELETE.SUCCESS:
            return {
                ...state,
                data: state.data.filter(e => e._id !== action.result._id),
                isFetching: false,
            }
        case types.USERS.DELETE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.USERS.CLEAR:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        case types.USERS.CREATE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USERS.CREATE.SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.result.data.CreateUser),
                isFetching: false,
            }
        case types.USERS.CREATE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.USERS.SET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USERS.SET.SUCCESS:
            return {
                ...state,
                data: state.data.map(e => e._id === action.result.data.UpdateUser._id 
                    ? action.result.data.UpdateUser : e),
                isFetching: false,
            }
        case types.USERS.PROMOTE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USERS.PROMOTE.SUCCESS:
            return {
                ...state,
                data: state.data.filter(e => e._id !== action.result.data.PromoteUser._id),
                isFetching: true,
            }
        case types.USERS.SET.FAILURE:
            return {
                ...state,
                data: [],
                isFetching: false,
            }
        default:
            return state
    }
}
