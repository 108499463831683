/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"

import { Card, Grid, Button, makeStyles } from "@material-ui/core"

import { useSelector, useDispatch } from "react-redux"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"


import * as userActions from "../../actions/userActions"

const UserSettingsSummary = (props) => {
  const _ = require("lodash")
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const classes = useStyles()
  const userType = useSelector((state) => _.get(state, "userInfo.settings.user.userType", ""))
  const { userGroups } = props;
  const [selectedGroup, setSelectedGroup] = React.useState()
  const checked = React.useRef([])
  const [open, setOpen] = React.useState(false)

  function setSelected(rowData) {
    return props && props.users[props.selectedRow.tableData.id] && props.users[props.selectedRow.tableData.id].accountUserGroups
      .filter(e => e._id === rowData._id).length >= 1
  }

  function buttonsDisabled() {
    return userGroups && userGroups.length <= 0
  }

  const data =
    props.selectedRow && userGroups
      ? userGroups.map((rowData) => Object.assign(
        {
          ...rowData,
          tableData: {
            checked: setSelected(rowData),
          },
        },
        rowData
      ))
      : []

  function updateUserGroups() {
    const user = {
      ...props.selectedRow,
      accountUserGroups: checked.current
    }
    dispatch(userActions.setUser({ token: auth.token }, user));
  }

  function checkRow(rowData) {
    checked.current = rowData.map(e => e._id);
  }


  function handleClose() {
    setOpen(false)
  }

  return (
    <Fragment>
      <Grid item xs={7} lg={5}>
        <Card className={classes.summaryCardActive}>
          {props.selectedRow && userGroups && userGroups.length > 0 && (
            <MaterialTable
              icons={tableIcons}
              title="User Groups"
              columns={[
                { title: "Description", field: "name" },
                // { title: "active", field: "active" }
                // { title: "ID", field: "id", hidden: "true" }
                /* { title: 'Allowed', field: 'allowed' , type: 'boolean'}, */
              ]}
              options={{
                minBodyHeight: "calc(100vh - 224px)",
                maxBodyHeight: "calc(100vh - 224px)",
                pageSize: 300,
                paging: true,
                paginationType: "normal",
                selection: true,
              }}
              data={data ? data : []}
              onSelectionChange={(rowData) => {
                checkRow(rowData)
              }}
            />
          )}
        </Card>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: 10 }}>
          <Card style={{ width: "100%", height: "100%" }} className={classes.actionButtonsCard}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {props.selectedRow && (userType == "SERVICE_MANAGER" || userType == "ADMIN") && (
                  <Button color="primary" onClick={updateUserGroups} disabled={buttonsDisabled()}>
                    Update User Groups
                    <ArrowForwardIosIcon style={{ marginLeft: 5, fontSize: 20, marginBottom: 2 }}></ArrowForwardIosIcon>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden",
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px",
    },
  },
  actionButtonsCard: {
    paddingTop: 7,
    minHeight: 51,
    height: "100%",
    width: "100%",
    textAlign: "right",
  },
  summaryCard: {
    height: "calc(100vh - 106px)",
  },
  summaryCardActive: {
    height: "calc(100vh - 168px)",
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: "25%",
    minHeight: "25%",
  },
  customerDetails: {
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiTextField-root": {
      margin: "0px",
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& .MuiInputBase-root": {
      marginTop: "12px",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
}))

export default UserSettingsSummary
