/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect, Fragment } from "react"
import { Grid, makeStyles, Tooltip } from "@material-ui/core"
import moment from "moment"
// import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import HistoryIcon from "@material-ui/icons/History"
import RefreshIcon from '@mui/icons-material/Refresh';
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import IconButton from "@material-ui/core/IconButton";
import { capitaliseWord } from "../../utils/functions"
import _ from "lodash"

import { useSelector, useDispatch } from "react-redux"
import { push } from "connected-react-router"
import * as bookingsActions from "../../actions/bookingsActions"
import * as appActions from "../../actions/appActions"

import ReservationModal from "./ReservationModal"
import ReservationSummary from "./ReservationSummary"


import {
  DataGrid,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";


const Reservations = () => {
  const bookings = useSelector((state) => state.bookings)
  const reservations = useSelector((state) => state.bookings.reservations)
  const quotation = useSelector((state) => ({ ...state.quotation }))
  const auth = useSelector((state) => ({ ...state.auth }))
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const user = useSelector((state) => state.userInfo.settings.user)
  const locations = useSelector((state) => state.locations?.locations)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [modify, setModify] = React.useState(false)
  const [cancel, setCancel] = React.useState(false)
  const [history, setHistory] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const selectedIndex = React.useRef(null)
  const [, setDropOffDate] = React.useState(null)
  const [disabledRow, setDisabledRow] = React.useState(false)
  // const [openHistory, setOpenHistory] = React.useState(false)


  function disableRow(data) {
    if (_.intersection(userInfo.settings.itcodes.map(e => e.code), [data.PackageCode]).length > 0) {
      return false
    } else if (user.userType != "SERVICE_USER") {
      return false
    }

    return true
  }

  useEffect(() => {
    dispatch(bookingsActions.getReservations({ token: auth.token }))
  }, [])

  useEffect(() => {
    if (bookings.reservation) {
      const row = rowsData.filter((r) => r.ReservationNumber == bookings.reservation.ReservationNumber)
      setSelectedRow(row[0])
    }
  }, [bookings.reservation])

  function onReservationClick(selectedRow) {
    setDisabledRow(false)
    setDropOffDate(selectedRow.DropOff.DateTime)
    let reservationNumber = selectedRow.ReservationNumber
    let referenceId = selectedRow.ReferenceId
    if (selectedRow.status == "cancelled") {
      dispatch(bookingsActions.clearReservation({ token: auth.token }))
    } else if (disableRow(selectedRow)) {
      dispatch(bookingsActions.clearReservation({ token: auth.token }))
      setDisabledRow(true)
    } else {
      dispatch(bookingsActions.clearReservation({ token: auth.token }))
      dispatch(bookingsActions.getReservation({ token: auth.token, reservationNumber, referenceId }))
    }
  }

  const handleClose = () => {
    setOpen(false)
    setModify(false)
    setCancel(false)
    setHistory(false)
  }

  const handleOpenCancel = () => {
    handleClose()
    setOpen(true)
    setCancel(true)
    setModify(false)
    setHistory(false)
  }

  const handleOpenExtend = () => {
    setOpen(true)
    setCancel(false)
    setModify(true)
    setHistory(false)
  }

  const handleOpenHistory = (rowData) => {
    selectedIndex.current = rowData.id
    setSelectedRow(rowData)
    setOpen(true)
    setCancel(false)
    setModify(false)
    setHistory(true)
  }

  const handleKeyDown = (e) => {
    const inputBox = document.getElementById("search_res_input");
    const res_number = inputBox.value;
    // console.log(e)
    // console.log(inputBox.value)
    if (e.key == "Enter" && res_number.length == 6) {
      if (!res_number.match("^[A-Za-z0-9]+$")) {
        dispatch(appActions.showMessage("Warning", "RES# INVALID", "warning"));
        return;
      }
      dispatch(bookingsActions.getReservation({ token: auth.token, reservationNumber: res_number.toUpperCase() }))
      setSelectedRow(null)
    }
  };

  const handleChange = (RA) => {
    setSelectedRow(null)
  };

  const handleOnDownloadEmail = (data) => {
    dispatch(
      bookingsActions.downloadConfirmationEmail({
        type: data.ReservationStatus == "open" ? "modifyReservation" : "cancelReservation",
        mode: "reservation",
        token: auth.token,
        reservationNumber: data.ReservationNumber,
        rentalNumber: "",
        userInfo: { ...userInfo },
        locations: [...locations],
      })
    )
  }

  const handleEditReservation = () => {
    // - populate quotation from reservation
    const pickupDateTime = bookings.reservation.Pickup.DateTime.split("T")
    const dropoffDateTime = bookings.reservation.DropOff.DateTime.split("T")
    const optionsClean = _.cloneDeep(bookings.reservation.Options) || []

    dispatch(
      bookingsActions.setQuotation({
        pickupLocation: bookings.reservation.Pickup.Location.locationCode,
        pickupDate: moment(pickupDateTime[0], "YYYY-MM-DD").format("DD-MM-YYYY"),
        pickupTime: pickupDateTime[1],
        dropoffLocation: bookings.reservation.DropOff.Location.locationCode,
        dropoffDate: moment(dropoffDateTime[0], "YYYY-MM-DD").format("DD-MM-YYYY"),
        dropoffTime: dropoffDateTime[1],
        itcode: bookings.reservation.PackageCode,
        age: _.min([21, moment().diff(moment(bookings.reservation.DobOfYoungestDriver, "YYYY-MM-DD"), "years")]) + "",
        dobOfYoungestDriver: bookings.reservation.DobOfYoungestDriver,
        customerToReceive: bookings.reservation.RateClass,
        insurerChargedAs: bookings.reservation.VoucherRate.Class,
        vehicleClass: bookings.reservation.RateClass,
        options:
          optionsClean.map((o) => {
            o.Quantity = parseInt(o.Quantity)
            return o
          }) || [],
        claimnumber: bookings.reservation.Insurance.ClaimNumber,
        policynumber: bookings.reservation.Insurance.PolicyNumber,
        customer: {
          firstname: bookings.reservation.Renter.FirstName,
          lastname: bookings.reservation.Renter.LastName,
          email: bookings.reservation.Renter.Address.Email,
          phone: bookings.reservation.Renter.Address.HomeTelephoneNumber,
          mobile: bookings.reservation.Renter.Address.CellTelephoneNumber,
          address: bookings.reservation.Renter.Address.Street,
          postcode: bookings.reservation.Renter.Address.PostalCode,
          city: bookings.reservation.Renter.Address.City,
          country: bookings.reservation.Renter.Address.CountryCode,
          state: bookings.reservation.Renter.Address.StateOrProvCode,
          license: bookings.reservation.Renter.DrivingLicense.Number,
          issuer: bookings.reservation.Renter.DrivingLicense.Issuer,
          expiry: bookings.reservation.Renter.DrivingLicense.ExpiryDate,
          adfirstname: _.get(bookings, "reservation.AdditionalDriverNotes.FirstName", ""),
          adlastname: _.get(bookings, "reservation.AdditionalDriverNotes.LastName", ""),
          adphone: _.get(bookings, "reservation.AdditionalDriverNotes.Phone", ""),
          admobile: _.get(bookings, "reservation.AdditionalDriverNotes.Mobile", ""),
        },
      })
    )

    dispatch(
      bookingsActions.setBookings({
        reservationNumber: bookings.reservation.ReservationNumber,
        reservationStatus: bookings.reservation.ReservationStatus,
        referenceId: bookings.reservation.ReferenceId,
      })
    )

    let existingRes = {}
    if (bookings.referenceId != "" && bookings.reservationNumber != "" && bookings.reservationStatus == "open") {
      existingRes = {
        referenceId: bookings.reservation.ReferenceId,
      }
    }

    dispatch(
      bookingsActions.getRatesAvailability({
        ...existingRes,
        pickupLocation: quotation.pickupLocation,
        dropoffLocation: quotation.dropoffLocation,
        pickupDateTime: moment(quotation.pickupDate + " " + quotation.pickupTime, "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        ),
        dropoffDateTime: moment(quotation.dropoffDate + " " + quotation.dropoffTime, "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        ),
        rateClass: quotation.vehicleClass,
        packageCode: quotation.itcode,
        token: auth.token,
        dobOfYoungestDriver: quotation.age
          ? moment().subtract(quotation.age, "years").format("YYYY-MM-DD")
          : moment().subtract(21, "years").format("YYYY-MM-DD"),
      })
    )

    dispatch(push({ pathname: "editreservation" }))
  }

  const handleRowHovered = (event) => {

    const rowId = event.currentTarget?.dataset?.id;
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );

  };

  const handleRowLeaved = (event) => {

    const rowId = event.currentTarget?.dataset?.id;
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );

  };


  const rowsData = reservations ?
    reservations.map((x, index) =>
      Object.assign({
        ...x,
        id: index,
        PickUpDate: moment(x.PickUp.DateTime, "YYYY-MM-DDTHH:mm").format("YYYY/MM/DD"),
        PickUpTime: moment(x.PickUp.DateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        PickUpLoc: x.PickUp.Location,
        DropOffDate: moment(x.DropOff.DateTime, "YYYY-MM-DDTHH:mm").format("YYYY/MM/DD"),
        DropOffTime: moment(x.DropOff.DateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        DropOffLoc: x.DropOff.Location,
      }, x))
    : []

  const CustomToolbar = () => {
    return (
      <React.Fragment>
        <GridToolbarContainer>
          <h3>Reservations</h3>
          <div style={{ display: "flex", flexGrow: 1 }}></div>
          <div style={{ verticalAlign: "center" }}>
            <Tooltip title="Refresh Data">
              <span>
                <IconButton
                  disabled={bookings.isFetching}
                  onClick={() => dispatch(bookingsActions.getReservations({ token: auth.token }))
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <GridToolbarQuickFilter
              id="search_res_input"
              style={{ width: "150px" }}
              quickFilterParser={(searchInput) => {
                const inputs = searchInput
                  .split(",")
                  .map((value) => value?.trim().slice(0, 8))
                  .filter((value) => value?.slice(0, 8) !== "");
                  handleChange(inputs[0]);
                return inputs;
              }}
              debounceMs={500}
              quickFilterFormatter={(quickFilterValues) => {
                return quickFilterValues.join(", ");
              }}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
        </GridToolbarContainer>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ReservationModal
        open={open}
        setOpen={setOpen}
        modify={modify}
        cancel={cancel}
        history={history}
        selectedRow={selectedIndex ? rowsData[selectedIndex.current] : []}
      />
      <Grid container direction="row" justify="flex-start" spacing={2} className={classes.root}>
        <Grid item xs={5} lg={7} style={{
          height: "calc(100vh - 90px)"
        }}>
          <DataGrid
            loading={bookings.isFetching}
            ref={useGridApiRef()}
            className={classes.dataGrid + ' ' + classes.paper}
            slots={{ toolbar: CustomToolbar }}
            columns={[
              {
                headerName: "Claim No.",
                field: "ClaimNumber",
                minWidth: 120,
                headerClassName: classes.header
              },
              {
                headerName: "Status",
                field: "ReservationStatus",
                headerClassName: classes.header,
                maxWidth: 80,
                render: (r) => <Fragment>{capitaliseWord(r.ReservationStatus)}</Fragment>,
              },
              {
                headerName: "Pick Up Date",
                field: "PickUpDate",
                headerClassName: classes.header,
              },
              // {
              //   title: "Pick Up Time",
              //   field: "PickUpTime",
              //   headerClassName: classes.header,
              //   hideable: true,
              // },
              {
                headerName: "Pick Up Loc",
                field: "PickUpLoc",
                type: "string",
                maxWidth: 70,
                headerClassName: classes.header,
              },
              {
                headerName: "Drop Off Date",
                field: "DropOffDate",
                headerClassName: classes.header,
              },
              // {
              //   title: "Drop Off Time",
              //   field: "DropOff.DateTime",
              //   headerClassName: classes.header,
              //   hideable: true,
              // },
              {
                headerName: "Drop Off Loc",
                field: "DropOffLoc",
                maxWidth: 70,
                headerClassName: classes.header,
              },

              // {
              //   title: "Last Modified Time",
              //   field: "LastModifiedTime",
              //   headerClassName: classes.header,
              //   hideable: true,
              // },
              {
                headerName: "Res No.",
                field: "ReservationNumber",
                maxWidth: 100,
                headerClassName: classes.header,

              },
              // {
              //   title: "ReferenceId",
              //   field: "ReferenceId",
              //   hideable: true,
              // },

              {
                headerName: "Actions",
                field: "actions",
                headerClassName: classes.header,
                getActions: (params) => [
                  <GridActionsCellItem
                    icon={<HistoryIcon />}
                    label="History"
                    description="History"
                    className="textPrimary"
                    tooltip="History"
                    onClick={() => handleOpenHistory(params.row)}
                    color="inherit"
                    key={params.row.locationCode}
                  />,
                  <GridActionsCellItem
                    icon={<MailOutlineIcon />}
                    label="Download Email Template"
                    onClick={() => handleOnDownloadEmail(params.row)}
                    color="inherit"
                    key={params.row.locationCode}
                  />,
                ],
                flex: 1,
                minWidth: 100,
                type: "actions",
                align: "left",
                headerAlign: "left",
              },
            ]}
            rows={rowsData}
            slotProps={{
              row: {
                onMouseEnter: handleRowHovered,
                onMouseLeave: handleRowLeaved
              }
            }}
            icons={tableIcons}
            title="Reservations"
            onRowClick={(selectedRow, event) => {
              selectedIndex.current = selectedRow.id
              setSelectedRow(selectedRow.row)
              onReservationClick(selectedRow.row)
            }}

            pageSizeOptions={[10, 15, 20, 50, 100]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'PickUpDate', sort: 'desc' }],
              },
              pagination: {
                paginationModel: { pageSize: 10 }
              },
            }}

            components={{
              Toolbar: CustomToolbar
            }}
          />
        </Grid>
        <ReservationSummary
          selectedRow={selectedRow}
          disabledRow={disabledRow}
          handleOpenCancel={handleOpenCancel}
          handleOpenExtend={handleOpenExtend}
          handleEditReservation={handleEditReservation}
        />
      </Grid>
    </React.Fragment >
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px"
    },
  },
  header: {
    backgroundColor: "#fff2bf",
    fontSize: "bold"
  },
  dataGrid: {
    backgroundColor: "white",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3)
  },
}))

export default Reservations
