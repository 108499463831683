// Set up your root reducer here...
import { connectRouter } from "connected-react-router"
import { persistCombineReducers } from "redux-persist"
import storage from "redux-persist/lib/storage"

import analyticsReducer from "./analyticsReducer"
import authReducer from "./authReducer"
import locationsReducer from "./locationsReducer"
import quotationReducer from "./quotationReducer"
import bookingsReducer from "./bookingsReducer"
import appReducer from "./appReducer"
import userInfoReducer from "./userInfoReducer"
import serviceManagerReducer from "./serviceManagerReducer"
import locationGroupReducer from "./locationGroupReducer"
import vehicleGroupReducer from "./vehicleGroupReducer"
import userGroupReducer from "./userGroupReducer"
import userReducer from "./userReducer"
import changePasswordReducer from "./changePasswordReducer"
import forgotPasswordReducer from "./forgotPasswordReducer"
import resetPasswordReducer from "./resetPasswordReducer"

/* Redux-Persist */
const presistedRootReducer = (history) =>
  persistCombineReducers(
    {
      key: "root",
      whitelist: ["auth", "locations", "app", "bookings", "quotation", "userInfo", "serviceManagers"],
      storage,
    },
    {
      router: connectRouter(history),
      analytics: analyticsReducer,
      auth: authReducer,
      app: appReducer,
      locations: locationsReducer,
      locationGroups: locationGroupReducer,
      vehicleGroups: vehicleGroupReducer,
      quotation: quotationReducer,
      userGroups: userGroupReducer,
      users: userReducer,
      changePassword: changePasswordReducer,
      bookings: bookingsReducer,
      userInfo: userInfoReducer,
      serviceManagers: serviceManagerReducer,
      forgotPassword: forgotPasswordReducer,
      resetPassword: resetPasswordReducer,
    }
  )

export default presistedRootReducer
