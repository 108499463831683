import { createStore, compose, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";

import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga";

import { persistStore } from "redux-persist";

export const history = createBrowserHistory({ basename: "/" });

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [sagaMiddleware, reactRouterMiddleware];

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
}
