/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment } from "react"
import Moment from "react-moment"

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  withStyles,
  Box,
  Paper,
} from "@material-ui/core"

import { useSelector } from "react-redux"

import { HERTZ_YELLOW, BLUE } from "../../constants/style"
import EditIcon from "@material-ui/icons/Edit"
import CancelIcon from "@material-ui/icons/Cancel"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import moment from "moment"

const ColorCircularProgress = withStyles({
  root: {
    color: HERTZ_YELLOW,
  },
})(CircularProgress)

const getVehicleModelImage = (bookings) => {
  let vehicle = bookings.vehicles.filter((vehicles) => {
    return vehicles.code == bookings.reservation.RateClass
  })

  return "https://images.hertz.com/vehicles/220x128/" + vehicle[0].image
}

const ReservationSummary = (props) => {
  const bookings = useSelector((state) => state.bookings)
  const reservation = useSelector((state) => state.bookings.reservation)
  const classes = useStyles()
  const pathname = window.location.pathname.split("/")
  const settingsMeta = useSelector(state =>  state.userInfo.settings.meta )
  const allowTotalSubtotal = settingsMeta.find(e => e.key === "allowTotalSubtotal").value
  const lastSegment = pathname.pop() || pathname.pop()

  function disableEditCancel() {
    if (moment(props.selectedRow.DropOff.DateTime, "YYYY-MM-DD") < moment()) {
      return true
    } else if (props.bookingType === "rental" || moment(props.selectedRow.PickUp.DateTime, "YYYY-MM-DD") < moment()) {
      return true
    }

    return false
  }

  return (
    <Fragment>
      {reservation && reservation.Insurance && bookings.vehicles && props.selectedRow && props.selectedRow.ReservationStatus != "cancelled" && (
        <Grid item xs={7} lg={5} style={{ padding: 8 }}>
          <Grid item xs>
            <Card className={classes.summaryCardActive}>
              <Card style={{ margin: "15px" }} className={classes.quotationInfo}>
                <CardContent style={{ padding: "10px" }}>

                  {/* PICKUP / DROPOFF */}
                  <Grid container spacing={1} justify="center" >
                    <Grid item xs={4} className={classes.customerDetails} style={{ textAlign: "center" }}>
                      {reservation.RentalNumber ? <TextField
                        id="rentalnumber"
                        label="Rental #"
                        margin="dense"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={reservation.RentalNumber}
                      /> : <TextField
                        id="reservationnumber"
                        label="Reservation #"
                        margin="dense"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={reservation.ReservationNumber}
                      />}
                    </Grid>
                    <Grid item xs={4} className={classes.customerDetails} style={{ textAlign: "center" }}>
                      <TextField
                        id="claimnumber"
                        label="Claim #"
                        margin="dense"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={reservation.Insurance.ClaimNumber}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.customerDetails} style={{ textAlign: "center" }}>
                      <TextField
                        id="policynumber"
                        label="Policy #"
                        margin="dense"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={reservation.Insurance.ClaimNumber}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.customerDetails}>
                      <TextField
                        id="sfirstname"
                        label="Customer First Name"
                        value={reservation.Renter.FirstName}
                        margin="dense"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.customerDetails}>
                      <TextField
                        id="slastname"
                        label="Customer Last Name"
                        value={reservation.Renter.LastName}
                        margin="dense"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs style={{ padding: "5px 10px", textAlign: "center" }}>
                      <div style={{ fontSize: "10px" }}>PICKUP</div>
                      <div>{reservation.Pickup.Location.locationName}</div>
                      <div>
                        <Moment format="DD MMM, YYYY hh:mm A" parse="YYYY-MM-DD HH:mm">
                          {reservation.Pickup.DateTime}
                        </Moment>
                      </div>
                    </Grid>
                    <Grid item xs style={{ padding: "5px 10px", textAlign: "center" }}>
                      <div style={{ fontSize: "10px" }}>DROPOFF</div>
                      <div>{reservation.DropOff.Location.locationName}</div>
                      <div>
                        <Moment format="DD MMM, YYYY hh:mm A" parse="YYYY-MM-DD HH:mm">
                          {reservation.DropOff.DateTime}
                        </Moment>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* Customer Charges + image */}
              <Grid container alignItems="stretch">
                <Grid
                  item
                  xs
                  style={{
                    padding: "0px 5px 0px 15px",
                    alignText: "center",
                  }}
                >
                  <Paper className={classes.vehicleCard}>
                    <div
                      style={{
                        background: HERTZ_YELLOW,
                        width: "100%",
                        height: "20px",
                        position: "absolute",
                        top: "0px",
                        fontSize: "10px",
                        lineHeight: "20px",
                      }}
                    >
                      CUSTOMER CHARGES
                    </div>
                    <img style={{ marginTop: "5px", height: "60px" }} src={getVehicleModelImage(bookings)} />
                    { allowTotalSubtotal 
                      && <div style={{ fontSize: "20px" }}>${(Math.round(reservation.Billing.Customer.Total * 100) / 100).toFixed(2)}</div>}
                    <Divider variant="middle" style={{ margin: "0 0 5px 0" }} />
                    {/* Charges */}
                    <div style={{ padding: "0 10px" }}>
                      <Grid container spacing={1} justify="center" style={{ fontSize: "10px" }}>
                        <Grid item xs style={{ textAlign: "left" }}>
                          DESCRIPTION
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                          QTY
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                          { allowTotalSubtotal && "SUBTOTAL" }
                        </Grid>
                      </Grid>
                      {reservation.Billing.Customer.TimeCharges.map((tc, i) => (
                        <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            {tc.Description}
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "right" }}>
                            {tc.Quantity}
                          </Grid>
                          <Grid item xs={4} style={{ textAlign: "right" }}>
                            {allowTotalSubtotal && (Math.round(tc.Total * 100) / 100).toFixed(2)}
                          </Grid>
                        </Grid>
                      ))}
                      {reservation.Billing.Customer.Options.map((o, i) => (
                        <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                          {o.Quantity > 0 && (
                            <React.Fragment>
                              <Grid item xs={6} style={{ textAlign: "left" }}>
                                {o.Description}
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "right" }}>
                                {o.Quantity}
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {allowTotalSubtotal && (Math.round(o.Total * 100) / 100).toFixed(2)}
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      ))}
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    padding: "0px 15px 0px 5px",
                    alignText: "center",
                  }}
                >
                  <Paper className={classes.vehicleCard}>
                    <div
                      style={{
                        background: BLUE,
                        width: "100%",
                        height: "20px",
                        position: "absolute",
                        top: "0px",
                        fontSize: "10px",
                        lineHeight: "20px",
                      }}
                    >
                      INSURER CHARGES
                    </div>
                    <img style={{ marginTop: "5px", height: "60px" }} src={getVehicleModelImage(bookings)} />
                    {allowTotalSubtotal && <div style={{ fontSize: "20px" }}>${(Math.round(reservation.Billing.Voucher.Total * 100) / 100).toFixed(2)}</div>}
                    <Divider variant="middle" style={{ margin: "0 0 5px 0" }} />
                    {/* Charges */}
                    <div style={{ padding: "0 10px" }}>
                      <Grid container spacing={1} justify="center" style={{ fontSize: "10px" }}>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          DESCRIPTION
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          QTY
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          {allowTotalSubtotal && "SUBTOTAL"}
                        </Grid>
                      </Grid>
                      {reservation.Billing.Voucher.TimeCharges.map((tc, i) => (
                        <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            {tc.Description}
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "right" }}>
                            {tc.Quantity}
                          </Grid>
                          <Grid item xs={4} style={{ textAlign: "right" }}>
                            {allowTotalSubtotal && (Math.round(tc.Total * 100) / 100).toFixed(2)}
                          </Grid>
                        </Grid>
                      ))}
                      {reservation.Billing.Voucher.Options &&
                        reservation.Billing.Voucher.Options.map((o, i) => (
                          <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                            <Grid item xs={6} style={{ textAlign: "left" }}>
                              {o.Description}
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "right" }}>
                              {o.Quantity}
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                              {allowTotalSubtotal && (Math.round(o.Total * 100) / 100).toFixed(2)}
                            </Grid>
                          </Grid>
                        ))}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: 7 }}>
            <Card style={{ width: "100%", height: "100%" }}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                {
                  props.bookingType === "rental" ? <Grid item xs className={classes.actionButtons}>
                    <Button
                      color="primary"
                      fullWidth
                      onClick={props.handleOpenExtend}
                      disabled={moment(reservation.DropOff.DateTime, "YYYY-MM-DD") < moment() ? true : false}
                    >
                      <AddCircleOutlineIcon
                        style={{ fontSize: 15, marginRight: 5, marginTop: -2 }}
                      ></AddCircleOutlineIcon>{" "}
                      Modify Drop Off
                    </Button>
                  </Grid> : ""
                }
                
                <Grid item xs className={classes.actionButtons}>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={props.handleEditReservation}
                    disabled={disableEditCancel()}
                  >
                    <EditIcon style={{ fontSize: 15, marginRight: 5, marginTop: -2 }}></EditIcon> Edit{" "}
                    {props.bookingType === "rental" ? "Rental" : "Reservation"}
                  </Button>
                </Grid>
                <Grid item xs className={classes.actionButtons}>
                  <Button color="primary" onClick={props.handleOpenCancel} fullWidth disabled={disableEditCancel()}>
                    <CancelIcon style={{ fontSize: 15, marginRight: 5, marginTop: -2 }}></CancelIcon> Cancel{" "}
                    {props.bookingType === "rental" ? "Rental" : "Reservation"}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      {props.selectedRow && props.selectedRow.ReservationStatus == "cancelled" && !props.disabledRow && (
        <Grid item xs={7} lg={5}>
          <Card className={classes.summaryCard}>
            {props.selectedRow.reservationStatus}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Reservation
              </Typography>
              <Typography variant="body2" color="textSecondary" component="div">
                This reservation has been cancelled. {props.selectedRow.reservationStatus}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      {props.selectedRow && props.disabledRow && (
        <Grid item xs={7} lg={5}>
          <Card className={classes.summaryCard}>
            {props.selectedRow.reservationStatus}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Error
              </Typography>
              <Typography variant="body2" color="textSecondary" component="div">
                You do not have access to alter reservations with IT Code: {props.selectedRow.PackageCode}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      {reservation &&
        props.selectedRow &&
        !bookings.reservation &&
        !props.disabledRow &&
        props.selectedRow.ReservationStatus != "cancelled" && (
          <Grid item xs={7} lg={5}>
            <Card className={classes.summaryCard}>
              <CardContent>
                {bookings.isFetching ? (
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      Loading Your {lastSegment == "rentals" ? "Rental" : "Reservation"}
                      {props.selectedRow.reservationStatus}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                      <Box textAlign="center">
                        <ColorCircularProgress className={classes.progress} size={50} thickness={4} />
                      </Box>
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      Error fetching reservation.
                    </Typography>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      {reservation && !props.selectedRow && (
        <Grid item xs={7} lg={5}>
          <Card className={classes.summaryCard}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Please select a {lastSegment == "rentals" ? "Rental" : "Reservation"}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Your order&apos;s details will be displayed here
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Fragment>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden",
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px",
    },
  },
  actionButtons: {
    paddingTop: 10,
    paddingBottom: 10,
    height: "100%",
  },
  summaryCard: {
    paddingBottom: 2,
    height: "calc(100vh - 106px)",
  },
  summaryCardActive: {
    paddingBottom: 2,
    height: "calc(100vh - 168px)",
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  customerDetails: {
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiTextField-root": {
      margin: "0px",
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& .MuiInputBase-root": {
      marginTop: "12px",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
}))

export default ReservationSummary
