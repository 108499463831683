import { takeLatest, put, call } from "redux-saga/effects"
import callApi from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"


export function* requestPasswordReset(actions) {
    
    
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation RequestResetPassword {
                RequestResetPassword(
                    emailAddress: "${actions.payload.emailAddress}"
                    serviceName: "${actions.payload.serviceName}"
                ) {
                    message
                }
            }
            `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.FORGOT_PASSWORD.SET.SUCCESS, types.FORGOT_PASSWORD.SET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* watchForgotPasswordRequest() {
    yield takeLatest(types.FORGOT_PASSWORD.SET.REQUEST, requestPasswordReset);
}
