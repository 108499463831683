/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment } from "react"
import moment from "moment"
import _ from "lodash"


const ReservationHistory = (props) => {
  const reservation = props.selectedRow

  let differences = []

  reservation.Modifications?.map((m) => {



    function getDiff(object, base) {
      function changes(object, base) {
        return _.transform(object, function (result, value, key) {
          if (!_.isEqual(value, base[key]) && (!_.includes(["", null], value) && !_.includes(["", null], object[key])) && !_.includes(["DateModified", "DateDayRentalEnds", "RateSession"], key)) {
            result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
          }
        });
      }
      return changes(object, base);
    }

    const diff = getDiff(m.Update, m.Original)


    function findOriginal(object, base) {
      function matches(object, base) {
        return _.transform(base, function (result, value, key) {
          result[key] = (_.isObject(value) && _.isObject(object[key])) ? matches(object[key], value) : object[key];
        });
      }
      return matches(object, base);
    }

    if (!_.isEmpty(diff)) {
      let original = findOriginal(m.Original, diff)
      const result = {
        DateModified: m.Update.DateModified,
        Original: original,
        Modified: diff
      }
      differences.push(result)
    }
  })


  const ModifictionList = () => (
    <div>
      <ul style={{ paddingX: 30 }}>
        {
          differences.map((d, i) =>
            <li key={i}
              style={{
                marginBottom: "10px",
                borderBottom: "1px dashed",
                padding: "10px 10px 10px 0",
              }}>
              <div style={{ fontWeight: "bold", fontSize: "small", color: "gray" }}>{moment(d.DateModified).local().format('YYYY-MM-DD HH:mm')}</div>
              <div
                style={{ lineHeight: "24px", fontStyle: "italic", fontSize: "small" }}
              >{_.map(d.Original, (m, key) => {
                return _.isObject(m) ? _.map(m, (mValue, mKey) => {

                  return <div>
                    {`${key} - ${mKey}`} |
                    From: <strong>{`${d.Original[key][mKey]}`}</strong> To: <strong>{`${d.Modified[key][mKey]}`}</strong>
                  </div>

                }) : <div>
                  {`${key}`} | From:<strong> {`${d.Original[key]}`}</strong> To: <strong>{`${d.Modified[key]}`}</strong>
                </div>
              })}

              </div>
            </li>
          )
        }
      </ul>
    </div >

  )


  return (
    <Fragment>
      {reservation && reservation.Modifications ? (
        <div>
          <p style={{
            padding: "0px 40PX",
            fontWeight: "bolder",
            fontSize: "1.2rem",
            marginBottom: "0px"
          }}>MODIFICATION HISTORY OF {`${reservation.ReservationNumber}`}
          </p>
          <p
            style={{
              padding: "0px 40PX",
              fontWeight: "bolder",
              fontSize: "0.8rem",
              margin: "0px",
              color: "gray",
              fontStyle: "italic"
            }}
          >{'Timezone: ' + Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
          <div style={{ height: "50vh", width: "50vw", overflowY: "scroll" }}>
            <ModifictionList />
          </div>
        </div>
      ) : (
        <div>No modifications detected</div>
      )
      }
    </Fragment >
  )
}

export default ReservationHistory
