import _ from "lodash"

export const tenClosestLocations = (locations, allowedLocations, disallowedLocations, excludeAptLocations) => {
  // Returns the 10 closest locations to search result
  // handles disallowedLocations and when user has been assigned "allowedLocations" only

  console.log(locations, allowedLocations, disallowedLocations)

  locations = excludeAptLocations ? _.filter(locations, (l) => (!_.includes(l.locationName, "APT") || _.includes(l.locationName, "OFF APT"))) : locations

  if (locations && allowedLocations && allowedLocations.length > 0) {
    return disallowedLocations
      ? _.slice(
          locations.filter(
            (location) =>
              !disallowedLocations.includes(location.locationCode) && allowedLocations.includes(location.locationCode)
          ),
          0,
          10
        )
      : _.slice(
          locations.filter((location) => allowedLocations.includes(location.locationCode)),
          0,
          10
        )
  } else if (locations) {
    return disallowedLocations
      ? _.slice(
          locations.filter((location) => !disallowedLocations.includes(location.locationCode)),
          0,
          10
        )
      : _.slice(locations, 0, 10)
  }

  return []
}

export const favouriteLocations = (locations, favouriteLocations, allowedLocations, disallowedLocations) => {
  // Returns favourite locations
  // handles disallowedLocations and when user has been assigned "allowedLocations" only

  if (locations && favouriteLocations && allowedLocations && allowedLocations.length > 0) {
    return disallowedLocations
      ? _.slice(
          locations.filter(
            (location) =>
              !disallowedLocations.includes(location.locationCode) &&
              favouriteLocations.includes(location.locationCode) &&
              allowedLocations.includes(location.locationCode)
          ),
          0,
          10
        )
      : _.slice(
          locations.filter(
            (location) =>
              favouriteLocations.includes(location.locationCode) && allowedLocations.includes(location.locationCode)
          ),
          0,
          10
        )
  } else if (locations && favouriteLocations) {
    return disallowedLocations
      ? _.slice(
          locations.filter(
            (location) =>
              !disallowedLocations.includes(location.locationCode) && favouriteLocations.includes(location.locationCode)
          ),
          0,
          10
        )
      : _.slice(
          locations.filter((location) => favouriteLocations.includes(location.locationCode)),
          0,
          10
        )
  }

  return []
}
